import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import IPSView from "../../../models/ipsView";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import SupportViewFinally from "../../../models/supportViewFinally";
import DateFilterButtons from "../../component/DateFilterButtons";

export const ViewSendSupport = () => {
  const [arraySupport, setArraySupport] = useState<SupportViewFinally[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [selectedSupport, setSelectedSupport] = useState<any>(null);
  const navigate = useNavigate();
  const [limit, setlimit] = useState(0);
  const [Total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [expandedRow] = useState(null);
  const [selectedIpsName, setselectedIpsName] = useState<string>("");
  const [arrayIPS, setArrayIPS] = useState<IPSView[]>([]);
  const [startDateEntry, setStartDateEntry] = useState<string>("");
  const [endDateEntry, setEndDateEntry] = useState<string>("");
  const [selectedType, setselectedType] = useState<string>("");
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);

  const handleIpsNameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setselectedIpsName(event.target.value);
  };

  const getAllIPS = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ACTIVE);
      setArrayIPS(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info IPS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleTokenError = () => {
    navigate("/"); // Go to login session
  };
  // Get my supports using id to token
  const getMySupports = async (page: number) => {
    setLoading(true);
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;

      const url = `${ApiBack.SUPPORT_SEND_VIEW}?${[
        selectedType ? `id_type_request_id=${selectedType}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        `user=${userId}`,
        page ? `page=${page}` : "",
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        // eslint-disable-next-line eqeqeq
        (startDateEntry != "" && endDateEntry == "") ||
        // eslint-disable-next-line eqeqeq
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupport(response.results);
          setlimit(response.total_pages);
          setTotal(response.total_count);
          setLoading(false);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify("info", "No se encuentran resultados.", 2000);
        setArraySupport([]);
        setlimit(0);
        setTotal(0);
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = (support: any) => {
    setSelectedSupport(support);
    setShow(true);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1)); // Asegura que la página no sea menor que 1
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, limit)); // Asegura que la página no exceda el límite
    window.scrollTo(0, 0);
  };

  const truncateText = (text: string, maxLength: any) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const getRowClass = (howItConclude: string) => {
    switch (howItConclude) {
      case "EXISTOSO":
        return "table-success";
      case "EN_PROGRESO":
        return "table-secondary";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isSearchTriggered && startDateEntry && endDateEntry) {
      getMySupports(page);
      setIsSearchTriggered(false); // Resetear para futuras búsquedas
    }
  }, [startDateEntry, endDateEntry, page, isSearchTriggered]);

  useEffect(() => {
    // getMySupports(page);
    MessageToastify(
      "info",
      "Recuerde ingresar el filtro para ver los datos",
      2000
    );
    getAllIPS();
    // const today = new Date();
    // const firstDayLastMonth = new Date(
    //   today.getFullYear(),
    //   today.getMonth() - 1,
    //   1
    // );
    // const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    // // Formatear la fecha en YYYY-MM-DD
    // const formatDate = (date: Date) => date.toISOString().split("T")[0];

    // setStartDateEntry(formatDate(firstDayLastMonth));
    // setEndDateEntry(formatDate(lastDayLastMonth));
  }, [page]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
            <h3 style={{ fontWeight: "bold" }}>Soportes asignados por mí</h3>
          </div>

          <div className="col-lg-12 d-flex justify-content-start">
            {/* Filtro Tipo de Solicitud */}

            <div className="row">
              <div className="d-flex align-items-center col-lg-8 col-md-12">
                <Form.Group className="" controlId="formIps">
                  <Form.Label>Seleccionar IPS</Form.Label>
                  <Form.Select
                    value={selectedIpsName}
                    onChange={handleIpsNameChange}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  >
                    <option value="">Todas las IPS</option>
                    {arrayIPS.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.municipio_id.nombre_municipio}&nbsp;-&nbsp;
                        {type.nombre_ips}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formIps">
                <Form.Label>Seleccionar solicitud</Form.Label>
                <Form.Select value={selectedType} onChange={handleTyoeChange}>
                  <option value="">Todas las solicitudes</option>
                  {arrayTypeRequest.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.typerequest}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group> */}
                <Form.Group className="" controlId="startDateEntry">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    // id="startDateEntry"
                    value={startDateEntry}
                    onChange={(e) => setStartDateEntry(e.target.value)}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  />
                </Form.Group>
                <Form.Group className="" controlId="startDateEntry">
                  <Form.Label>Fecha de fin</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    // id="endDateEntry"
                    value={endDateEntry}
                    onChange={(e) => setEndDateEntry(e.target.value)}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex align-items-center col-lg-4 col-md-12 mt-lg-4 mb-3">
                <DateFilterButtons
                  setStartDateEntry={setStartDateEntry}
                  setEndDateEntry={setEndDateEntry}
                  setIsSearchTriggered={setIsSearchTriggered}
                  setPage={setPage}
                  getMySupports={getMySupports}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-2 col-md-4 col-sm-4 align-content-center">
                    <p>
                      Mis asignados:&nbsp;
                      <b className="fw-bold">{arraySupport.length}</b>
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-5">
                    <p>
                      Mis asignados totales:&nbsp;
                      <b className="fw-bold">{Total}</b>
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-5">
                    <p>
                      Páginas totales:&nbsp;
                      <b className="fw-bold">{limit}</b>
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-3">
                    <p>
                      Exitoso:&nbsp;
                      <span
                        style={{
                          display: "inline-block",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#c7dbd2",
                          borderRadius: "50%",
                        }}
                      ></span>
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-3">
                    <p>
                      En progreso:&nbsp;
                      <span
                        style={{
                          display: "inline-block",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#e2e3e5",
                          borderRadius: "50%",
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table  table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th style={{ width: "10%" }}>Fecha</th>
                        <th style={{ width: "10%" }}>IPS</th>
                        <th style={{ width: "15%" }}>Enviado a</th>
                        <th style={{ width: "15%" }}>Solicitante</th>
                        <th style={{ width: "5%" }}>Tiempo</th>
                        <th style={{ width: "10%" }}>Tipo de solicitud</th>
                        <th style={{ width: "17%" }}>Requerimiento</th>
                        <th style={{ width: "5%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <tr>
                            <td colSpan={12} className="text-center">
                              <div className="d-flex flex-column align-items-center">
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    color: "#000",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Cargando...
                                  </span>
                                </div>
                                <p
                                  className="mt-3 fs-5"
                                  style={{ color: "#000" }}
                                >
                                  Cargando datos, por favor espera...
                                </p>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {arraySupport.length === 0 ? (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <div className="d-flex flex-column align-items-center">
                                  <p
                                    className="mt-3 fs-5"
                                    style={{ color: "#000" }}
                                  >
                                    No tiene soportes en el momento de buscar.
                                    Por favor, ingrese o verifique el filtro
                                    para la búsqueda.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            arraySupport.map((support, count) => (
                              <tr
                                key={support.id}
                                className={getRowClass(support.how_it_conclude)}
                              >
                                <td>
                                  {new Date(
                                    support.support_date
                                  ).toLocaleString("es-CO", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </td>
                                <td>
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={support.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                                  >
                                    {
                                      support.id_ips__municipio_id__nombre_municipio
                                    }
                                  </span>
                                </td>
                                <td>{support.id_CustomerUser2__name}</td>
                                <td>{support.name_solicited}</td>
                                <td>{support.time_support || 0}&nbsp;Min.</td>
                                <td>{support.typerequest_id__typerequest}</td>
                                <td>
                                  {expandedRow === count
                                    ? support.requirement
                                    : truncateText(support.requirement, 50)}
                                </td>
                                <td>
                                  <button
                                    className="badge fs-6 py-1 pe-2 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                                    type="button"
                                    onClick={() => handleShow(support)}
                                  >
                                    <i className="fas fa-eye"></i>
                                    &nbsp;Ver
                                  </button>
                                </td>
                              </tr>
                            ))
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center justify-content-center">
                    <nav className="inline-flex space-x-2.5 justify-content-center">
                      <button
                        className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                        &nbsp;Anterior
                      </button>
                      &nbsp;
                      <span className="fs-6">
                        Página {page} de {limit}
                      </span>
                      &nbsp;
                      <button
                        className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                        onClick={handleNextPage}
                        disabled={page === limit}
                      >
                        Siguiente&nbsp;
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal para ver detalles del soporte */}
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Detalles del soporte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedSupport && (
              <div>
                <p>
                  <strong>Solicitante:</strong>&nbsp;
                  {selectedSupport.name_solicited}&nbsp;-&nbsp;
                  {selectedSupport.role2}
                </p>
                <p>
                  <strong>Teléfono:</strong>&nbsp;
                  {selectedSupport.number || "No disponible"}
                </p>
                <p>
                  <strong>Requerimiento:</strong>&nbsp;
                  {selectedSupport.requirement}
                </p>
                <p>
                  <strong>Tiempo de soporte:</strong>&nbsp;
                  {selectedSupport.time_support} minutos.
                </p>
                <p>
                  <strong>Conclusión:</strong>&nbsp;
                  {selectedSupport.how_it_conclude || "Sin información"}
                </p>
                <p>
                  <strong>Respuesta:</strong>
                  <Form.Control
                    required
                    as="textarea"
                    name="requirement"
                    className="form-control"
                    // id="requirement"
                    placeholder="Escriba o seleccione la descripción del soporte."
                    value={selectedSupport.answer}
                    aria-describedby="requirement"
                    autoComplete="off"
                    rows={8}
                  />
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>

        <ToastContainer />
      </div>
    </>
  );
};
