import { useState } from "react";
import { InternalRouting } from "../utils/router/AppRouter";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";

export const MainBoard = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="page-body-wrapper">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="main-panel" 
        style={{
          marginLeft: isSidebarOpen ? "240px" : "0px",
          transition: "margin-left 0.3s ease-in-out",
        }}>
          <div className="content-wrapper ">
            <InternalRouting />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
