import React from "react";
import { Button } from "react-bootstrap";

interface DateFilterButtonsProps {
  setStartDateEntry: (date: string) => void;
  setEndDateEntry: (date: string) => void;
  setIsSearchTriggered: (value: boolean) => void;
  setPage: (page: number) => void;
  getMySupports: (page: number) => void;
  page: number;
}

const DateFilterButtons: React.FC<DateFilterButtonsProps> = ({
  setStartDateEntry,
  setEndDateEntry,
  setIsSearchTriggered,
  setPage,
  getMySupports,
  page,
}) => {
  const formatDate = (date: Date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const handleSearchNow = () => {
    const today = new Date();
    setStartDateEntry(formatDate(today));
    setEndDateEntry(formatDate(today));
    setIsSearchTriggered(true);
    setPage(1);
  };

  const handleSearchYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setStartDateEntry(formatDate(yesterday));
    setEndDateEntry(formatDate(yesterday));
    setIsSearchTriggered(true);
    setPage(1);
  };

  const handleSearchWeet = () => {
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    setStartDateEntry(formatDate(lastWeek));
    setEndDateEntry(formatDate(today));
    setIsSearchTriggered(true);
    setPage(1);
  };

  const handleSearchMonth = () => {
    const today = new Date();
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    setStartDateEntry(formatDate(firstDayLastMonth));
    setEndDateEntry(formatDate(lastDayLastMonth));
    setIsSearchTriggered(true);
    setPage(1);
  };

  return (
    <div>
      <Button className="ms-2 mt-3 badge fs-6 p-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
        variant="primary" onClick={() => getMySupports(page)}>
        <i className="fa-solid fa-magnifying-glass"></i>&nbsp;Buscar
      </Button>
      <Button className="ms-2 mt-3 badge fs-6 p-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
        variant="primary" onClick={handleSearchNow}>
        <i className="fa-solid fa-magnifying-glass"></i>&nbsp;Hoy
      </Button>
      <Button className="ms-2 mt-3 badge fs-6 p-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
        variant="primary" onClick={handleSearchYesterday}>
        <i className="fa-solid fa-magnifying-glass"></i>&nbsp;Ayer
      </Button>
      <Button className="ms-2 mt-3 badge fs-6 p-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
        variant="primary" onClick={handleSearchWeet}>
        <i className="fa-solid fa-magnifying-glass"></i>&nbsp;Semana
      </Button>
      <Button className="ms-2 mt-3 badge fs-6 p-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
        variant="primary" onClick={handleSearchMonth}>
        <i className="fa-solid fa-magnifying-glass"></i>&nbsp;Mes
      </Button>
    </div>
  );
};

export default DateFilterButtons;
