/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { useNavigate } from "react-router-dom";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { ToastContainer } from "react-toastify";
import IPS from "../../../models/Ips";
import User from "../../../models/user";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import Typerequest from "../../../models/type_of_request";
import logo from "../../../assets/images/Rocky/logoRockyPre.png";
import signature from "../../../assets/images/Rocky/firmaDoctor.jpeg";
import SupportViewFinally from "../../../models/supportViewFinally";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import DateFilterButtons from "../../component/DateFilterButtons";

export const ViewSupportFinal = () => {
  const [arraySupport, setArraySupport] = useState<SupportViewFinally[]>([]);
  const [arraySupportIPSExport, setArraySupportIPSExport] = useState<
    SupportViewFinally[]
  >([]);
  const [arraySupportIPSOnlyExport, setArraySupportIPSOnlyExport] = useState<
    SupportViewFinally[]
  >([]);
  const [arraySupportAllToPDF, setArraySupportAllToPDF] = useState<
    SupportViewFinally[]
  >([]);
  const [arraySupportConIpsFun, setArraySupportConIpsFun] = useState<
    SupportViewFinally[]
  >([]);
  const [arraySupportOnlyPDf, setArraySupportOnlypdf] = useState<
    SupportViewFinally[]
  >([]);
  const [arraySupportOnePDF, setArraySupportOnePDF] = useState<
    SupportViewFinally[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arrayCustomerUser, setArrayCustomerUser] = useState<User[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [loadingConcated, setLoadingConcated] = useState(false);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDateEntry, setStartDateEntry] = useState<string>("");
  const [endDateEntry, setEndDateEntry] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arrayTypeRequest, setArrayTypeRequest] = useState<Typerequest[]>([]);
  const [arrayIPS, setArrayIPS] = useState<IPS[]>([]);
  const [selectedType, setselectedType] = useState<string>("");
  const [selectedIpsName, setselectedIpsName] = useState<string>("");
  const [page, setPage] = useState(1);
  const [pageConcate, setPageConcate] = useState(1);
  const [limit, setlimit] = useState(0);
  const [limitConcate, setlimitConcate] = useState(0);
  const [Total, setTotal] = useState(0);
  const [typeCondensate, setTypeCondensate] = useState("");
  const [pageNext, setPagenext] = useState(0);
  const [pageprevious, setPageprevious] = useState(0);
  const [expandedRow] = useState(null);
  const [show, setShow] = useState<boolean>(false);
  const [showCondensates, setShowCondensates] = useState<boolean>(false);
  const [showCondensatesIPS, setShowCondensatesIPS] = useState<boolean>(false);
  const [showCondensatesFuncionary, setShowCondensatesFuncionary] =
    useState<boolean>(false);
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);

  const [selectedSupport, setSelectedSupport] = useState<SupportViewFinally>(
    new SupportViewFinally(
      0,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      0,
      "",
      "",
      0,
      new Date(),
      new Date(),
      new Date(),
      "",
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    )
  );

  const handleClose = () => setShow(false);
  const handleCloseCondensates = () => {
    setShowCondensates(false);
    setArraySupportConIpsFun([]);
    setlimitConcate(0);
    setPageConcate(1);
  };
  const handleCloseCondensatesIPS = () => {
    setShowCondensatesIPS(false);
    setArraySupportConIpsFun([]);
    setlimitConcate(0);
    setPageConcate(1);
  };
  const handleCloseCondensatesFuncinary = () => {
    setShowCondensatesFuncionary(false);
    setArraySupportConIpsFun([]);
    setlimitConcate(0);
    setPageConcate(1);
  };

  const Excel = async () => {
    try {
      if (!arraySupportAllToPDF || arraySupportAllToPDF.length === 0) {
        MessageToastify("info", "Vuelva a intentar exportar en Excel.", 2000);
        return;
      }
      if (!startDateEntry || !endDateEntry) {
        MessageToastify(
          "error",
          "Debe seleccionar una rango de fechas para poder exportat a Excel.",
          2000
        );
        return;
      }

      // Crear un arreglo con los datos que quieres exportar
      const tableData = arraySupportAllToPDF.map((support) => ({
        "Nombre de la ips: ": support.id_ips__nombre_ips,
        "Fecha de soporte":
          new Date(support.support_date).toLocaleDateString("es-ES") ||
          "No disponible",
        Solicitud: support.subtype_request__subtype_request || "No disponible",
        "Funcionario que atiende":
          support.id_CustomerUser2__name +
            " " +
            support.id_CustomerUser2__last_name || "No disponible",
        Solicitante: support.name_solicited || "No disponible",
        Cargo: support.role2 || "No disponible",
        "Cómo concluye": support.how_it_conclude || "No disponible",
        Requerimiento: support.requirement,
        Respuesta: support.answer || "No disponible",
        Tiempo: support.time_support || "No disponible",
      }));

      // Crear un libro de trabajo (workbook)
      const ws = XLSX.utils.json_to_sheet(tableData);

      // Crear el libro con la hoja de trabajo
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Soportes Filtrados");

      // Generar un archivo Excel y descargarlo
      const name_ips = selectedIpsName.trim()
        ? selectedIpsName
        : "Todas las IPS";

      // Descargar el archivo Excel
      XLSX.writeFile(wb, `${name_ips}.xlsx`);
      setArraySupportAllToPDF([]);
    } catch (error) {
      console.error("Error al exportar a Excel:", error);
      alert("No se pudo exportar el archivo. Intenta de nuevo.");
    }
  };

  // Create pdf but no use ips case
  const PDFExport = async () => {
    try {
      const formatDate = (date: Date | string | null | undefined): string => {
        if (!date) return "No disponible"; // Manejar valores nulos o indefinidos

        const parsedDate = typeof date === "string" ? new Date(date) : date;

        return parsedDate.toLocaleDateString("es-CO", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
      };

      // Filtrar por fecha (ejemplo de filtro de fecha)
      const startDate = new Date("2024-01-01");
      const endDate = new Date();
      const filteredData = arraySupportOnePDF.filter((myProfessional) => {
        const createDate = new Date(myProfessional.create_date);
        return createDate >= startDate && createDate <= endDate;
      });
      // Crear una nueva instancia de jsPDF con orientación personalizada
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      // Título
      doc.setFontSize(11);
      const pageWidth = doc.internal.pageSize.getWidth();

      // Dibujo del rectángulo principal
      doc.rect(10, 10, pageWidth - 20, 30); // (x, y, ancho, alto)

      // Logo de Rocky
      const logoPath = logo; // Cambia esto a la ruta o base64 de tu imagen
      doc.addImage(logoPath, "PNG", 12, 19, 50, 10); // (imagen, formato, x, y, ancho, alto)

      // Texto del logo
      doc.setFontSize(10);
      doc.setTextColor(192, 192, 192); // Gris claro

      let nombreIPS = "Nombre de IPS no disponible"; // Valor por defecto

      if (selectedIpsName && filteredData.length > 0) {
        const ipsEncontrada = filteredData.find(
          (m) => m.id_ips__nombre_ips
        )?.id_ips__nombre_ips;
        if (ipsEncontrada) {
          nombreIPS = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }

      // Texto del título
      else doc.setFontSize(10);
      console.log(nombreIPS);

      doc.setTextColor(0, 0, 0); // Negro
      doc.text(
        `Sistema de Información en Atención Primaria S.A.S\nNIT 900.798.870-0\nSoporte técnico remoto del software Rocky\nPara:\n${nombreIPS}\nDesde: ${startDateEntry} hasta ${endDateEntry}`,
        pageWidth / 2,
        18,
        { align: "center" }
      );
      // doc.text("No 091-2024", pageWidth / 2 - 20, 27); // Centrado debajo

      // // Celdas de la tabla derecha
      // doc.rect(pageWidth - 50, 10, 40, 10); // Celda: Código
      // doc.rect(pageWidth - 50, 20, 40, 10); // Celda: Año
      // doc.line(pageWidth - 30, 10, pageWidth - 30, 30); // Línea divisoria entre columnas

      // // Textos en las celdas
      // doc.setFontSize(10);
      // doc.text("Código", pageWidth - 48, 17); // Primera celda, columna 1
      // doc.text("Sop - 01", pageWidth - 28, 17); // Primera celda, columna 2
      // doc.text("Año", pageWidth - 48, 27); // Segunda celda, columna 1
      // doc.text("2025", pageWidth - 28, 27); // Segunda celda, columna 2
      const tableData = filteredData.map((myProfessional) => [
        formatDate(myProfessional.support_date), // Formatear fecha
        myProfessional.name_solicited,
        myProfessional.role2,
        myProfessional.id_CustomerUser2__name +
          " " +
          myProfessional.id_CustomerUser2__last_name, // ID del cliente
        myProfessional.requirement,
        myProfessional.answer,
      ]);

      // Config table
      autoTable(doc, {
        startY: 45,
        head: [
          [
            "Fecha",
            "Solicitante",
            "Cargo",
            "Funcionario de Rocky",
            "Solicitud",
            "Respuesta",
          ],
        ], // Cabecera de la tabla
        body: tableData, // Cuerpo de la tabla con los datos
        margin: { top: 10, left: 10, right: 10 }, // Márgenes personalizados
        theme: "grid", // Tema de la tabla
        styles: {
          fontSize: 8,
          cellPadding: 1,
        },
        headStyles: {
          fillColor: [0, 0, 0], // Color de fondo de las cabeceras
          textColor: [255, 255, 255], // Color del texto
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 33 }, // Fecha
          1: { cellWidth: 45 }, // Solicitante
          2: { cellWidth: 30 }, // Cargo
          3: { cellWidth: 35 }, // Funcionario de Rocky
          4: { cellWidth: 35 }, // Solicitud
          5: { cellWidth: 100 }, // Respuesta
        },
      });

      const finalY = doc.lastAutoTable.finalY;
      const signatureDoc = signature;
      doc.addImage(signatureDoc, "PNG", 10, finalY + 10, 30, 8);
      doc.setTextColor(0, 0, 0); // Negro
      doc.text(`FRANCISCO CARDOZO VARGAS\nRL ROCKY SAS`, 10, finalY + 20);

      // Verifica en la consola
      doc.save(`${nombreIPS}.pdf`);

      setArraySupportOnePDF([]);
    } catch (error) {
      console.error("Error al exportar a PDF:", error);
      alert("No se pudo exportar el archivo PDF. Intenta de nuevo.");
    }
  };
  // Create pdf in IPS and funcionary in condensate
  const PDFExportIPSFuncionary = async () => {
    try {
      // Crear una nueva instancia de jsPDF con orientación personalizada
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      // Título
      doc.setFontSize(11);
      const pageWidth = doc.internal.pageSize.getWidth();

      // Dibujo del rectángulo principal
      doc.rect(10, 10, pageWidth - 20, 30); // (x, y, ancho, alto)

      // Logo de Rocky
      const logoPath = logo; // Cambia esto a la ruta o base64 de tu imagen
      doc.addImage(logoPath, "PNG", 12, 19, 50, 10); // (imagen, formato, x, y, ancho, alto)

      // Texto del logo
      doc.setFontSize(10);
      doc.setTextColor(192, 192, 192); // Gris claro

      let nombreIPS = "Nombre de IPS no disponible"; // Valor por defecto

      if (selectedIpsName && arraySupportIPSOnlyExport.length > 0) {
        const ipsEncontrada = arraySupportIPSOnlyExport.map(
          (m) => m.id_ips__nombre_ips
        );
        if (ipsEncontrada) {
          nombreIPS = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }

      // Texto del título
      else doc.setFontSize(10);

      doc.setTextColor(0, 0, 0); // Negro
      doc.text(
        `Sistema de Información en Atención Primaria S.A.S\nNIT 900.798.870-0\nSoporte técnico remoto del software Rocky\nPara:\n${nombreIPS}\nDesde: ${startDateEntry} hasta ${endDateEntry}`,
        pageWidth / 2,
        18,
        { align: "center" }
      );
      const tableData = arraySupportIPSOnlyExport.map((myProfessional) => [
        myProfessional.id_ips__municipio_id__nombre_municipio,
        myProfessional.id_ips__nombre_ips,
        myProfessional.id_CustomerUser2__name +
          " " +
          myProfessional.id_CustomerUser2__last_name,
        myProfessional.total_supports,
        myProfessional.total_time_minutes,
      ]);
      console.log("Los datos: " + arraySupportIPSOnlyExport);

      // Config table
      autoTable(doc, {
        startY: 45,
        head: [
          [
            "Municipio",
            "IPS",
            "Funcionario Rocky",
            "N soportes",
            "Total minutos",
          ],
        ], // Cabecera de la tabla
        body: tableData, // Cuerpo de la tabla con los datos
        margin: { top: 10, left: 10, right: 10 }, // Márgenes personalizados
        theme: "grid", // Tema de la tabla
        styles: {
          fontSize: 8,
          cellPadding: 1,
        },
        headStyles: {
          fillColor: [0, 0, 0], // Color de fondo de las cabeceras
          textColor: [255, 255, 255], // Color del texto
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 120 },
          2: { cellWidth: 70 },
          3: { cellWidth: 20, halign: "center" },
          4: { cellWidth: 25, halign: "center" },
        },
      });

      const finalY = doc.lastAutoTable.finalY;
      const signatureDoc = signature;
      doc.addImage(signatureDoc, "PNG", 10, finalY + 10, 30, 15);
      doc.setTextColor(0, 0, 0); // Negro
      doc.text(`FRANCISCO CARDOZO VARGAS\nRL ROCKY SAS`, 10, finalY + 30);

      let nombreArchivo = "Archivo_con_todas_las_IPS"; // Valor por defecto

      if (selectedIpsName && arraySupportIPSOnlyExport.length > 0) {
        const ipsEncontrada = arraySupportIPSOnlyExport.map(
          (m) => m.id_ips__nombre_ips
        );
        if (ipsEncontrada) {
          nombreArchivo = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }
      // Verifica en la consola
      doc.save(`${nombreArchivo}.pdf`);

      setArraySupportOnePDF([]);
    } catch (error) {
      console.error("Error al exportar a PDF:", error);
      alert("No se pudo exportar el archivo PDF. Intenta de nuevo.");
    }
  };
  // Create pdf in IPS and funcionary in condensate
  const PDFExportIPS = async () => {
    try {
      // Crear una nueva instancia de jsPDF con orientación personalizada
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      // Título
      doc.setFontSize(11);
      const pageWidth = doc.internal.pageSize.getWidth();

      // Dibujo del rectángulo principal
      doc.rect(10, 10, pageWidth - 20, 30); // (x, y, ancho, alto)

      // Logo de Rocky
      const logoPath = logo; // Cambia esto a la ruta o base64 de tu imagen
      doc.addImage(logoPath, "PNG", 12, 19, 50, 10); // (imagen, formato, x, y, ancho, alto)

      // Texto del logo
      doc.setFontSize(10);
      doc.setTextColor(192, 192, 192); // Gris claro

      let nombreIPS = "Nombre de IPS no disponible"; // Valor por defecto

      if (selectedIpsName && arraySupportIPSExport.length > 0) {
        const ipsEncontrada = arraySupportIPSExport.map(
          (m) => m.id_ips__nombre_ips
        );
        if (ipsEncontrada) {
          nombreIPS = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }

      // Texto del título
      else doc.setFontSize(10);

      doc.setTextColor(0, 0, 0); // Negro
      doc.text(
        `Sistema de Información en Atención Primaria S.A.S\nNIT 900.798.870-0\nSoporte técnico remoto del software Rocky\nPara:\n${nombreIPS}\nDesde: ${startDateEntry} hasta ${endDateEntry}`,
        pageWidth / 2,
        18,
        { align: "center" }
      );
      const tableData = arraySupportIPSExport.map((myProfessional) => [
        myProfessional.id_ips__municipio_id__nombre_municipio,
        myProfessional.id_ips__nombre_ips,
        myProfessional.total_supports,
        myProfessional.total_time_minutes,
      ]);
      console.log("Los datos: " + arraySupportIPSExport);

      // Config table
      autoTable(doc, {
        startY: 45,
        head: [["Municipio", "IPS", "N soportes", "Total minutos"]], // Cabecera de la tabla
        body: tableData, // Cuerpo de la tabla con los datos
        margin: { top: 10, left: 10, right: 10 }, // Márgenes personalizados
        theme: "grid", // Tema de la tabla
        styles: {
          fontSize: 8,
          cellPadding: 1,
        },
        headStyles: {
          fillColor: [0, 0, 0], // Color de fondo de las cabeceras
          textColor: [255, 255, 255], // Color del texto
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 120 },
          2: { cellWidth: 70 },
          3: { cellWidth: 20, halign: "center" },
          4: { cellWidth: 25, halign: "center" },
        },
      });

      const finalY = doc.lastAutoTable.finalY;
      const signatureDoc = signature;
      doc.addImage(signatureDoc, "PNG", 10, finalY + 10, 30, 15);
      doc.setTextColor(0, 0, 0); // Negro
      doc.text(`FRANCISCO CARDOZO VARGAS\nRL ROCKY SAS`, 10, finalY + 30);

      let nombreArchivo = "Archivo_con_todas_las_IPS"; // Valor por defecto

      if (selectedIpsName && arraySupportIPSExport.length > 0) {
        const ipsEncontrada = arraySupportIPSExport.map(
          (m) => m.id_ips__nombre_ips
        );
        if (ipsEncontrada) {
          nombreArchivo = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }
      // Verifica en la consola
      doc.save(`${nombreArchivo}.pdf`);

      setArraySupportOnePDF([]);
    } catch (error) {
      console.error("Error al exportar a PDF:", error);
      alert("No se pudo exportar el archivo PDF. Intenta de nuevo.");
    }
  };

  const PDFExportFuncionary = async () => {
    try {
      // Crear una nueva instancia de jsPDF con orientación personalizada
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      // Título
      doc.setFontSize(11);
      const pageWidth = doc.internal.pageSize.getWidth();

      // Dibujo del rectángulo principal
      doc.rect(10, 10, pageWidth - 20, 30); // (x, y, ancho, alto)

      // Logo de Rocky
      const logoPath = logo; // Cambia esto a la ruta o base64 de tu imagen
      doc.addImage(logoPath, "PNG", 12, 19, 50, 10); // (imagen, formato, x, y, ancho, alto)

      // Texto del logo
      doc.setFontSize(10);
      doc.setTextColor(192, 192, 192); // Gris claro

      let nombreIPS = "Nombre de IPS no disponible"; // Valor por defecto

      if (selectedIpsName && arraySupportConIpsFun.length > 0) {
        const ipsEncontrada = arraySupportConIpsFun.map(
          (m) => m.id_ips__nombre_ips
        );
        if (ipsEncontrada) {
          nombreIPS = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }

      // Texto del título
      else doc.setFontSize(10);

      doc.setTextColor(0, 0, 0); // Negro
      doc.text(
        `Sistema de Información en Atención Primaria S.A.S\nNIT 900.798.870-0\nSoporte técnico remoto del software Rocky\nPara:\n${nombreIPS}\nDesde: ${startDateEntry} hasta ${endDateEntry}`,
        pageWidth / 2,
        18,
        { align: "center" }
      );
      const tableData = arraySupportConIpsFun.map((myProfessional) => [
        myProfessional.id_CustomerUser2__name +
          " " +
          myProfessional.id_CustomerUser2__last_name,
        myProfessional.total_supports,
        myProfessional.total_time_minutes,
      ]);
      console.log("Los datos: " + arraySupportConIpsFun);

      // Config table
      autoTable(doc, {
        startY: 45,
        head: [["Funcionario Rocky", "Número de soportes", "Total de minutos"]], // Cabecera de la tabla
        body: tableData, // Cuerpo de la tabla con los datos
        margin: { top: 10, left: 10, right: 10 }, // Márgenes personalizados
        theme: "grid", // Tema de la tabla
        styles: {
          fontSize: 8,
          cellPadding: 1,
        },
        headStyles: {
          fillColor: [0, 0, 0], // Color de fondo de las cabeceras
          textColor: [255, 255, 255], // Color del texto
          fontSize: 9,
        },
        columnStyles: {
          1: { cellWidth: 120 },
          3: { cellWidth: 20, halign: "center" },
          4: { cellWidth: 25, halign: "center" },
        },
      });

      const finalY = doc.lastAutoTable.finalY;
      const signatureDoc = signature;
      doc.addImage(signatureDoc, "PNG", 10, finalY + 10, 30, 15);
      doc.setTextColor(0, 0, 0); // Negro
      doc.text(`FRANCISCO CARDOZO VARGAS\nRL ROCKY SAS`, 10, finalY + 30);

      let nombreArchivo = "Archivo_con_todas_las_IPS"; // Valor por defecto

      if (selectedIpsName && arraySupportConIpsFun.length > 0) {
        const ipsEncontrada = arraySupportConIpsFun.map(
          (m) => m.id_ips__nombre_ips
        );
        if (ipsEncontrada) {
          nombreArchivo = String(ipsEncontrada);
        } else {
          console.warn("⚠️ No se encontró la IPS seleccionada en los datos.");
        }
      }
      // Verifica en la consola
      doc.save(`${nombreArchivo}.pdf`);

      setArraySupportOnePDF([]);
    } catch (error) {
      console.error("Error al exportar a PDF:", error);
      alert("No se pudo exportar el archivo PDF. Intenta de nuevo.");
    }
  };

  // Create to pdf as all ips
  const PDFExportByIPS = async () => {
    try {
      MessageToastify(
        "info",
        "Se estan creado los pdf, por favor espere mientras se descargan.",
        4000
      );
      const formatDate = (date: Date | string | null | undefined): string => {
        if (!date) return "No disponible";

        const parsedDate = typeof date === "string" ? new Date(date) : date;

        return parsedDate.toLocaleDateString("es-CO", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
      };

      const startDate = new Date("2024-01-01");
      const endDate = new Date();

      const zip = new JSZip();

      // Agrupar los datos por nombre de la IPS
      const groupedData: Record<string, typeof arraySupportOnlyPDf> =
        arraySupportOnlyPDf.reduce((acc, current) => {
          const nombreIPS =
            current.id_ips__municipio_id__nombre_municipio +
              "_" +
              current.id_ips__nombre_ips || "Nombre de IPS no disponible";
          if (!acc[nombreIPS]) {
            acc[nombreIPS] = [];
          }
          acc[nombreIPS].push(current);
          return acc;
        }, {} as Record<string, typeof arraySupportOnlyPDf>);

      // Iterar sobre cada grupo de IPS
      for (const [nombreIPS, data] of Object.entries(groupedData)) {
        const filteredData = data.filter((myProfessional) => {
          const createDate = new Date(myProfessional.create_date);
          return createDate >= startDate && createDate <= endDate;
        });

        // Crear una nueva instancia de jsPDF para cada IPS
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: "a4",
        });

        doc.setFontSize(11);
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.rect(10, 10, pageWidth - 20, 30);

        const logoPath = logo;
        doc.addImage(logoPath, "PNG", 12, 19, 50, 10);

        doc.setTextColor(0, 0, 0); // Negro
        doc.text(
          `Sistema de Información en Atención Primaria S.A.S\nNIT 900.798.870-0\nSoporte técnico remoto del software Rocky\nPara:\n${nombreIPS}\nDesde: ${startDateEntry} hasta ${endDateEntry}`,
          pageWidth / 2,
          16,
          { align: "center" }
        );

        // Tabla de datos
        const tableData = filteredData.map((myProfessional) => [
          formatDate(myProfessional.support_date),
          myProfessional.name_solicited,
          myProfessional.role2,
          `${myProfessional.id_CustomerUser2__name} ${myProfessional.id_CustomerUser2__last_name}`,
          myProfessional.requirement || "No especificado",
          myProfessional.answer || "Sin respuesta",
        ]);

        autoTable(doc, {
          startY: 45,
          head: [
            [
              "Fecha",
              "Solicitante",
              "Cargo",
              "Funcionario de Rocky",
              "Solicitud",
              "Respuesta",
            ],
          ],
          body: tableData,
          margin: { top: 10, left: 10, right: 10 },
          theme: "grid",
          styles: {
            fontSize: 8,
            cellPadding: 1,
          },
          headStyles: {
            fillColor: [0, 0, 0],
            textColor: [255, 255, 255],
            fontSize: 9,
          },
          columnStyles: {
            0: { cellWidth: 33 },
            1: { cellWidth: 35 },
            2: { cellWidth: 30 },
            3: { cellWidth: 35 },
            4: { cellWidth: 50 },
            5: { cellWidth: 100 },
          },
        });
        const finalY = doc.lastAutoTable.finalY;
        const signatureDoc = signature;
        doc.addImage(signatureDoc, "PNG", 10, finalY + 10, 30, 15);
        doc.setTextColor(0, 0, 0); // Negro
        doc.text(`FRANCISCO CARDOZO VARGAS\nRL ROCKY SAS`, 10, finalY + 30);

        // Guardar el PDF para esta IPS
        // const fileName = `${nombreIPS.replace(/ /g, "_")}.pdf`;
        // doc.save(fileName);
        const pdfBlob = doc.output("blob");
        zip.file(`${nombreIPS.replace(/ /g, "_")}.pdf`, pdfBlob);
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "informes_soporte.zip");
      MessageToastify("success", "Se han descargado todos los PDFs.", 4000);
      setArraySupportOnlypdf([]);
    } catch (error) {
      console.error("Error al exportar PDFs:", error);
      MessageToastify(
        "error",
        "Error en el momento de crear los PDFs, por favor comuníquese con TI.",
        4000
      );
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      getSupport(page - 1); // Llama a la API con la página anterior
    }
  };
  const handlePreviousPageConcated = () => {
    if (pageConcate > 1) {
      setPageConcate(pageConcate - 1);
      getSupportCondensatesIPSFun(pageConcate - 1); // Llama a la API con la página anterior
    }
  };
  const handlePreviousPageConcatedIPS = () => {
    if (pageConcate > 1) {
      setPageConcate(pageConcate - 1);
      getSupportCondensatesIPS(pageConcate - 1); // Llama a la API con la página anterior
    }
  };
  const handlePreviousPageConcatedFun = () => {
    if (pageConcate > 1) {
      setPageConcate(pageConcate - 1);
      getSupportCondensatesFuncionary(pageConcate - 1); // Llama a la API con la página anterior
    }
  };

  const handleNextPage = () => {
    if (page < limit) {
      setPage(page + 1);
      getSupport(page + 1); // Llama a la API con la página siguiente
    }
  };
  const handleNextPageConcated = () => {
    if (pageConcate < limitConcate) {
      setPageConcate(pageConcate + 1);
      getSupportCondensatesIPSFun(pageConcate + 1); // Llama a la API con la página siguiente
    }
  };
  const handleNextPageConcatedIPS = () => {
    if (pageConcate < limitConcate) {
      setPageConcate(pageConcate + 1);
      getSupportCondensatesIPS(pageConcate + 1); // Llama a la API con la página siguiente
    }
  };
  const handleNextPageConcatedFun = () => {
    if (pageConcate < limitConcate) {
      setPageConcate(pageConcate + 1);
      getSupportCondensatesFuncionary(pageConcate + 1); // Llama a la API con la página siguiente
    }
  };

  //Get to all support, but filter with values to selected for the users
  const getSupport = async (page: number) => {
    setLoading(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_FINAL}?${[
        selectedType ? `id_type_request_id=${selectedType}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        page ? `page=${page}` : "",
      ]
        .filter(Boolean)
        .join("&")}`;

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);
        if (response.length == 0) {
          MessageToastify(
            "info",
            "No se encontraron resultados para los filtros seleccionados.",
            4000
          );
          setArraySupport([]);
          return;
        }
        if (response.results && Array.isArray(response.results)) {
          setArraySupport(response.results);
          setlimit(response.total_pages);
          setTotal(response.total_count);
          setPagenext(response.next);
          setPageprevious(response.previous);
          setLoading(false);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify("info", response.error, 4000);
          setArraySupport([]);
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify("info", "No se encuentran resultados.", 2000);
        setArraySupport([]);
        setlimit(0);
        setTotal(0);
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };
  //Get support condensate to export IPS
  const getSupportexpIPS = async () => {
    setLoading(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_FINAL_EXPO_ONLY_PDF}?${[
        selectedType ? `id_type_request_id=${selectedType}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
      ]
        .filter(Boolean)
        .join("&")}`;

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);
        console.log(response);

        if (response.length == 0) {
          MessageToastify(
            "info",
            "No se encontraron resultados para los filtros seleccionados.",
            4000
          );
          setArraySupportIPSExport([]);
          return;
        }
        if (response) {
          setArraySupportIPSExport(response);
          setLoading(false);
          MessageToastify(
            "info",
            "Se esta creando el archivo por favor espere.",
            2000
          );
          if (arraySupportIPSExport.length !== 0) {
            PDFExportIPS();
          }
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify("info", response.error, 4000);
          setArraySupport([]);
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify("info", "No se encuentran resultados.", 2000);
        setArraySupportIPSExport([]);
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };
  //get export only data for IPS
  const getSupportexpIPSOnly = async () => {
    setLoading(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_FINAL_EXPO_IPS}?${[
        selectedType ? `id_type_request_id=${selectedType}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
      ]
        .filter(Boolean)
        .join("&")}`;

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);
        if (response.length == 0) {
          MessageToastify(
            "info",
            "No se encontraron resultados para los filtros seleccionados.",
            4000
          );
          setArraySupportIPSOnlyExport([]);
          return;
        }
        if (response) {
          setArraySupportIPSOnlyExport(response);
          setLoading(false);
          MessageToastify(
            "info",
            "Se esta creando el archivo por favor espere.",
            2000
          );
          if (arraySupportIPSOnlyExport.length !== 0) {
            console.log("CAmbios de datos");

            PDFExportIPSFuncionary();
          }
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify("info", response.error, 4000);
          setArraySupportIPSOnlyExport([]);
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify("info", "No se encuentran resultados.", 2000);
        setArraySupportIPSOnlyExport([]);
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  //Get data from backend, use only pdf for support but not use the filter
  const getSupportOnlyPDF = async () => {
    MessageToastify(
      "info",
      "Se están generado los informes, este proceso puede tardar un poco, sea paciente.",
      2000
    );
    try {
      const url = `${ApiBack.SUPPORT_VIEW_ONLY_PDF}?${[
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : `ipsName=0`,
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response && Array.isArray(response)) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupportOnlypdf(response);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra los datos en la base de datos.",
          4000
        );
        setArraySupport([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };
  const getSupportOnePDF = async () => {
    MessageToastify("info", "Se está exportando los datos a PDF.", 2000);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_ONLY_PDF}?${[
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : `ipsName=0`,
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);
        if (response.status === 200 && response.length == 0) {
          MessageToastify(
            "info",
            "No se encontraron resultados para los filtros seleccionados.",
            4000
          );
          setArraySupportOnePDF([]);
          return;
        }
        if (response && Array.isArray(response)) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupportOnePDF(response);
        } else {
          console.log(response);

          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra los datos en la base de datos.",
          4000
        );
        setArraySupport([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };
  const getSupportOnlyExcel = async () => {
    // setLoading(true);
    MessageToastify("info", "Se está exportando los datos a excel.", 2000);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_ONLY_PDF}?${[
        selectedIpsName ? `ipsName=${selectedIpsName}` : `ipsName=0`,
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupportAllToPDF(response);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra los datos en la base de datos.",
          4000
        );
        setArraySupport([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getSupportCondensatesIPSFun = async (page: number) => {
    setLoadingConcated(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_CON_IPS_FUN}?${[
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        page ? `page=${page}` : "",
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoadingConcated(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupportConIpsFun(response.results);
          setlimitConcate(response.total_pages);
          setLoadingConcated(false);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra los datos en la base de datos.",
          4000
        );
        setArraySupport([]);
        setLoadingConcated(false);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };
  const getSupportCondensatesFuncionary = async (page: number) => {
    setLoadingConcated(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_BY_FUNCIONARY}?${[
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        page ? `page=${page}` : "",
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoadingConcated(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupportConIpsFun(response.results);
          setlimitConcate(response.total_pages);
          setLoadingConcated(false);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra los datos en la base de datos.",
          4000
        );
        setArraySupport([]);
        setLoadingConcated(false);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };
  const getSupportCondensatesIPS = async (page: number) => {
    setLoadingConcated(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_CON_IPS}?${[
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        page ? `page=${page}` : "",
      ]
        .filter(Boolean) // Filtra valores vacíos
        .join("&")}`; // Une con '&'

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoadingConcated(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results) {
          // Filtrar resultados si objSupport.id_type_request_id está definido
          setArraySupportConIpsFun(response.results);
          setlimitConcate(response.total_pages);
          setLoadingConcated(false);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra los datos en la base de datos.",
          4000
        );
        setArraySupport([]);
        setLoadingConcated(false);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getAllIPS = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ACTIVE);
      setArrayIPS(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info IPS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleShow = (support: any) => {
    setSelectedSupport(support);
    setShow(true);
  };

  const getAllTypesrequest = async () => {
    try {
      const results = await ServicePrivate.requestGET(
        ApiBack.TYPE_OF_REQUEST_lIST
      );
      setArrayTypeRequest(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleTokenError = () => {
    navigate("/"); // Go to login session
  };

  const handleIpsNameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setselectedIpsName(event.target.value); // Actualiza el ID de IPS seleccionada
  };

  const truncateText = (text: string, maxLength: any) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  useEffect(() => {
    //getSupport(page);
    MessageToastify(
      "info",
      "Recuerde ingresar el filtro para ver los datos",
      2000
    );
    getAllTypesrequest();
    getAllIPS();
    setLoading(true);
    const today = new Date();
    const firstDayLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    // Formatear la fecha en YYYY-MM-DD
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    setStartDateEntry(formatDate(firstDayLastMonth));
    setEndDateEntry(formatDate(lastDayLastMonth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (arraySupportAllToPDF.length > 0) {
      Excel();
    }
    if (arraySupportOnlyPDf.length > 0) {
      PDFExportByIPS();
    }
    if (arraySupportOnePDF.length > 0) {
      PDFExport();
    }
  }, [arraySupportAllToPDF, arraySupportOnlyPDf, arraySupportOnePDF]);
  useEffect(() => {
    if (isSearchTriggered && startDateEntry && endDateEntry) {
      getSupport(page);
      setIsSearchTriggered(false); // Resetear para futuras búsquedas
    }
  }, [startDateEntry, endDateEntry, page, isSearchTriggered]);

  const handleSelectCondensateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTypeCondensate(event.target.value);
  };

  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
            <h3 style={{ fontWeight: "bold" }}>Soportes finalizados</h3>
          </div>

          <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
            <div className="row">
              <div className="d-flex align-items-center col-lg-8 col-md-12">
                <Form.Group className="" controlId="formIps">
                  <Form.Label>Seleccionar IPS</Form.Label>
                  <Form.Select
                    value={selectedIpsName}
                    onChange={handleIpsNameChange}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  >
                    <option value="">Todas las IPS</option>
                    {arrayIPS.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.municipio_id.nombre_municipio}&nbsp;-&nbsp;
                        {type.nombre_ips}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formIps">
                <Form.Label>Seleccionar solicitud</Form.Label>
                <Form.Select value={selectedType} onChange={handleTyoeChange}>
                  <option value="">Todas las solicitudes</option>
                  {arrayTypeRequest.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.typerequest}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group> */}
                <Form.Group className="" controlId="startDateEntry">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    // id="startDateEntry"
                    value={startDateEntry}
                    onChange={(e) => setStartDateEntry(e.target.value)}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  />
                </Form.Group>
                <Form.Group className="" controlId="startDateEntry">
                  <Form.Label>Fecha de fin</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    // id="endDateEntry"
                    value={endDateEntry}
                    onChange={(e) => setEndDateEntry(e.target.value)}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex align-items-center mt-lg-4 col-lg-4 col-md-12 mb-3">
                <DateFilterButtons
                  setStartDateEntry={setStartDateEntry}
                  setEndDateEntry={setEndDateEntry}
                  setIsSearchTriggered={setIsSearchTriggered}
                  setPage={setPage}
                  getMySupports={getSupport}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="fs-6 fw-light">
                  <div className="row d-flex">
                    <div className="col-lg-2 col-md-4 col-sm-4 align-content-center">
                      <p>
                        Soportes encontrados:&nbsp;
                        <b className="fw-bold">{arraySupport?.length}</b>
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-3 align-content-center">
                      <p>
                        Soportes totales:&nbsp;
                        <b className="fw-bold">{Total}</b>
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-3 align-content-center">
                      <p>
                        Páginas totales:&nbsp;
                        <b className="fw-bold">{limit}</b>
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-12 pb-2">
                      <div className="d-flex justify-content-end align-items-center">
                        {arraySupport.length !== 0 && (
                          <>
                            <Form.Group className="" controlId="conID">
                              <Form.Select
                                value={typeCondensate}
                                onChange={handleSelectCondensateChange}
                              >
                                <option value="">Condensados</option>
                                <option value="1">IPS</option>
                                <option value="2">IPS - funcionario</option>
                                <option value="3">Funcionario</option>
                              </Form.Select>
                            </Form.Group>
                            <button
                              className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill mx-2"
                              onClick={() => {
                                switch (typeCondensate) {
                                  case "1":
                                    getSupportCondensatesIPS(pageConcate);
                                    setShowCondensatesIPS(true);
                                    break;
                                  case "2":
                                    getSupportCondensatesIPSFun(pageConcate);
                                    setShowCondensates(true);
                                    break;
                                  case "3":
                                    getSupportCondensatesFuncionary(
                                      pageConcate
                                    );
                                    setShowCondensatesFuncionary(true);
                                    break;
                                  case "":
                                    MessageToastify(
                                      "info",
                                      "Seleccione un tipo de condensado.",
                                      2000
                                    );
                                    break;
                                }
                              }}
                            >
                              <i className="fa-solid fa-square-poll-horizontal"></i>
                              &nbsp;Condensados
                            </button>
                            <button
                              className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                              onClick={() => {
                                getSupportOnlyExcel();
                              }}
                            >
                              <i className="fa-solid fa-file-excel"></i>
                              &nbsp;Exportar
                            </button>

                            <button
                              className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill mx-2"
                              onClick={() => getSupportOnePDF()}
                            >
                              <i className="fa-solid fa-file-pdf"></i>
                              &nbsp;Exportar
                            </button>
                            {selectedIpsName === "" && (
                              <button
                                className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                                onClick={() => getSupportOnlyPDF()}
                              >
                                <i className="fa-solid fa-file-pdf"></i>
                                &nbsp;Todos
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col" style={{ width: "10%" }}>
                          Fecha
                        </th>
                        <th scope="col" style={{ width: "5%" }}>
                          IPS
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Funcionario
                        </th>
                        <th scope="col" style={{ width: "4%" }}>
                          T.
                        </th>
                        <th
                          scope="col"
                          className="d-none d-md-table-cell"
                          style={{ width: "20%" }}
                        >
                          Requerimiento
                        </th>
                        <th
                          scope="col"
                          className="d-none d-md-table-cell"
                          style={{ width: "20%" }}
                        >
                          Respuesta
                        </th>
                        <th style={{ width: "5%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <tr>
                            <td colSpan={12} className="text-center">
                              <div className="d-flex flex-column align-items-center">
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    color: "#000",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Cargando...
                                  </span>
                                </div>
                                <p
                                  className="mt-3 fs-5"
                                  style={{ color: "#000" }}
                                >
                                  Cargando datos, por favor espere...
                                </p>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {arraySupport?.length === 0 && (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <div className="d-flex flex-column align-items-center">
                                  <p
                                    className="mt-3 fs-5"
                                    style={{ color: "#000" }}
                                  >
                                    No tiene soportes en el momento de buscar.
                                    Por favor, ingrese o verifique el filtro
                                    para la búsqueda.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                          {arraySupport.map((myProfessional, count) => (
                            <tr key={count + 1}>
                              <td>
                                {new Date(
                                  myProfessional.support_date
                                ).toLocaleString("es-CO", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </td>
                              <td>
                                <span
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={myProfessional?.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                                >
                                  {
                                    myProfessional.id_ips__municipio_id__nombre_municipio
                                  }
                                </span>
                              </td>
                              <td>
                                {myProfessional?.id_CustomerUser2__name}&nbsp;
                                {myProfessional?.id_CustomerUser2__last_name}
                              </td>
                              <td>
                                {myProfessional?.time_support || 0}&nbsp;min.
                              </td>
                              {/* <td>
                                {expandedRow === count
                                  ? myProfessional.subtype_request
                                      ?.subtype_request
                                  : truncateText(
                                      myProfessional.subtype_request
                                        ?.subtype_request,
                                      10
                                    )}
                              </td> */}
                              <td className="d-none d-md-table-cell">
                                {expandedRow === count
                                  ? myProfessional?.requirement
                                  : truncateText(
                                      myProfessional?.requirement,
                                      50
                                    )}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {expandedRow === count
                                  ? myProfessional?.answer
                                  : truncateText(myProfessional?.answer, 50)}
                              </td>
                              <td>
                                <button
                                  className="badge fs-6 py-1 pe-2 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                                  type="button"
                                  onClick={() => handleShow(myProfessional)}
                                >
                                  <i className="fas fa-eye"></i>
                                  &nbsp;Ver
                                </button>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <nav className="inline-flex space-x-2.5 justify-content-center">
                    <button
                      className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                      onClick={handlePreviousPage}
                      disabled={page === 1}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                      &nbsp;Anterior
                    </button>
                    &nbsp;
                    <span className="fs-6">
                      Página {page} de {limit}
                    </span>
                    &nbsp;
                    <button
                      className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                      onClick={handleNextPage}
                      disabled={page === limit}
                    >
                      Siguiente&nbsp;
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
            {/* Modal para eliminar */}
            {/* *********************************************************************************/}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del soporte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSupport && (
            <div>
              <p>
                <strong>Ips:</strong>&nbsp;
                {selectedSupport.id_ips__nombre_ips}
              </p>
              <p>
                <strong>Estado:</strong>&nbsp;
                {selectedSupport.how_it_conclude || "Sin información"}
              </p>
              <p>
                <strong>Solicitante:</strong>&nbsp;
                {selectedSupport.name_solicited}
              </p>
              <p>
                <strong>Finalizado por:</strong>&nbsp;
                {selectedSupport.id_CustomerUser2__name}&nbsp;
                {selectedSupport.id_CustomerUser2__last_name}
              </p>
              <p>
                <strong>Teléfono:</strong>&nbsp;
                {selectedSupport.number || "No tiene teléfono disponible"}
              </p>
              <p>
                <strong>Tiempo de soporte:</strong>&nbsp;
                {selectedSupport.time_support || 0}&nbsp;minutos.
              </p>
              <p>
                <strong>Requerimiento:</strong>&nbsp;
                {selectedSupport.requirement}
              </p>
              <strong>Respuesta:</strong>
              <br />
              <Form.Control
                required
                as="textarea"
                name="requirement"
                className="form-control"
                id="requirement"
                placeholder="Escriba o seleccione la descripción del soporte."
                value={selectedSupport.answer}
                aria-describedby="requirement"
                autoComplete="off"
                rows={5}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCondensates}
        onHide={handleCloseCondensates}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Condensados de IPS y funcionarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-3 mb-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
            onClick={() => getSupportexpIPSOnly()}
          >
            <i className="fa-solid fa-file-pdf"></i>&nbsp;Exportar
          </button>
          <table className="table table-striped table-sm rounded-table">
            <thead className="table-dark">
              <tr>
                <th scope="col" style={{ width: "10%" }}>
                  Municipio
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  IPS
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  Funcionario
                </th>
                <th scope="col" style={{ width: "10%" }}>
                  Cuenta
                </th>
                <th style={{ width: "10%" }}>Minutos</th>
              </tr>
            </thead>
            <tbody>
              {loadingConcated ? (
                <>
                  <tr>
                    <td colSpan={12} className="text-center">
                      <div className="d-flex flex-column align-items-center">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#000",
                          }}
                        >
                          <span className="visually-hidden">Cargando...</span>
                        </div>
                        <p className="mt-3 fs-5" style={{ color: "#000" }}>
                          Cargando datos, por favor espere...
                        </p>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {arraySupportConIpsFun?.length === 0 && (
                    <tr>
                      <td colSpan={12} className="text-center">
                        <div className="d-flex flex-column align-items-center">
                          <div
                            className="spinner-border"
                            role="status"
                            style={{
                              width: "3rem",
                              height: "3rem",
                              color: "#000",
                            }}
                          >
                            <span className="visually-hidden">Cargando...</span>
                          </div>
                          <p className="mt-3 fs-5" style={{ color: "#000" }}>
                            Cargando datos, por favor espere...
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {arraySupportConIpsFun.map((supportCondensate, count) => (
                    <tr key={count + 1}>
                      <td>
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={supportCondensate?.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                        >
                          {
                            supportCondensate.id_ips__municipio_id__nombre_municipio
                          }
                        </span>
                      </td>
                      <td>
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={supportCondensate?.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                        >
                          {expandedRow === count
                            ? supportCondensate?.id_ips__nombre_ips
                            : truncateText(
                                supportCondensate?.id_ips__nombre_ips,
                                30
                              )}
                        </span>
                      </td>
                      <td>
                        {supportCondensate?.id_CustomerUser2__name}&nbsp;
                        {supportCondensate?.id_CustomerUser2__last_name}
                      </td>
                      <td className="text-center">
                        {supportCondensate.total_supports}
                      </td>
                      <td className="text-center">
                        {supportCondensate.total_time_minutes}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-center">
            <nav className="inline-flex space-x-2.5 justify-content-center">
              <button
                className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                onClick={handlePreviousPageConcated}
                disabled={pageConcate === 1}
              >
                <i className="fa-solid fa-arrow-left"></i>
                &nbsp;Anterior
              </button>
              &nbsp;
              <span className="fs-6">
                Página {pageConcate} de {limitConcate}
              </span>
              &nbsp;
              <button
                className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                onClick={handleNextPageConcated}
                disabled={pageConcate === limitConcate}
              >
                Siguiente&nbsp;
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </nav>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseCondensates}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCondensatesIPS}
        onHide={handleCloseCondensatesIPS}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Condensados de IPS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-3 mb-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
            onClick={() => getSupportexpIPS()}
          >
            <i className="fa-solid fa-file-pdf"></i>&nbsp;Exportar
          </button>
          <table className="table table-striped table-sm rounded-table">
            <thead className="table-dark">
              <tr>
                <th scope="col" style={{ width: "10%" }}>
                  Municipio
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  IPS
                </th>
                <th scope="col" style={{ width: "10%" }}>
                  Cuenta
                </th>
                <th style={{ width: "10%" }}>Minutos</th>
              </tr>
            </thead>
            <tbody>
              {loadingConcated ? (
                <>
                  <tr>
                    <td colSpan={12} className="text-center">
                      <div className="d-flex flex-column align-items-center">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#000",
                          }}
                        >
                          <span className="visually-hidden">Cargando...</span>
                        </div>
                        <p className="mt-3 fs-5" style={{ color: "#000" }}>
                          Cargando datos, por favor espere...
                        </p>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {arraySupportConIpsFun?.length === 0 && (
                    <tr>
                      <td colSpan={12} className="text-center">
                        <div className="d-flex flex-column align-items-center">
                          <div
                            className="spinner-border"
                            role="status"
                            style={{
                              width: "3rem",
                              height: "3rem",
                              color: "#000",
                            }}
                          >
                            <span className="visually-hidden">Cargando...</span>
                          </div>
                          <p className="mt-3 fs-5" style={{ color: "#000" }}>
                            Cargando datos, por favor espere...
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {arraySupportConIpsFun.map((supportCondensate, count) => (
                    <tr key={count + 1}>
                      <td>
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={supportCondensate?.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                        >
                          {
                            supportCondensate.id_ips__municipio_id__nombre_municipio
                          }
                        </span>
                      </td>
                      <td>
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={supportCondensate?.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                        >
                          {expandedRow === count
                            ? supportCondensate?.id_ips__nombre_ips
                            : truncateText(
                                supportCondensate?.id_ips__nombre_ips,
                                50
                              )}
                        </span>
                      </td>
                      <td className="text-center">
                        {supportCondensate.total_supports}
                      </td>
                      <td className="text-center">
                        {supportCondensate.total_time_minutes}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-center">
            <nav className="inline-flex space-x-2.5 justify-content-center">
              <button
                className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                onClick={handlePreviousPageConcatedIPS}
                disabled={pageConcate === 1}
              >
                <i className="fa-solid fa-arrow-left"></i>
                &nbsp;Anterior
              </button>
              &nbsp;
              <span className="fs-6">
                Página {pageConcate} de {limitConcate}
              </span>
              &nbsp;
              <button
                className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                onClick={handleNextPageConcatedIPS}
                disabled={pageConcate === limitConcate}
              >
                Siguiente&nbsp;
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </nav>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseCondensatesIPS}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCondensatesFuncionary}
        onHide={handleCloseCondensatesFuncinary}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Condensados por funcionario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-3 mb-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
            onClick={() => PDFExportFuncionary()}
          >
            <i className="fa-solid fa-file-pdf"></i>&nbsp;Exportar
          </button>
          <table className="table table-striped table-sm rounded-table">
            <thead className="table-dark">
              <tr>
                <th scope="col" style={{ width: "50%" }}>
                  Nombre
                </th>
                <th scope="col" style={{ width: "25%" }}>
                  Cuenta
                </th>
                <th style={{ width: "25%" }}>Minutos</th>
              </tr>
            </thead>
            <tbody>
              {loadingConcated ? (
                <>
                  <tr>
                    <td colSpan={12} className="text-center">
                      <div className="d-flex flex-column align-items-center">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#000",
                          }}
                        >
                          <span className="visually-hidden">Cargando...</span>
                        </div>
                        <p className="mt-3 fs-5" style={{ color: "#000" }}>
                          Cargando datos, por favor espere...
                        </p>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {arraySupportConIpsFun?.length === 0 && (
                    <tr>
                      <td colSpan={12} className="text-center">
                        <div className="d-flex flex-column align-items-center">
                          <div
                            className="spinner-border"
                            role="status"
                            style={{
                              width: "3rem",
                              height: "3rem",
                              color: "#000",
                            }}
                          >
                            <span className="visually-hidden">Cargando...</span>
                          </div>
                          <p className="mt-3 fs-5" style={{ color: "#000" }}>
                            Cargando datos, por favor espere...
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {arraySupportConIpsFun.map((supportCondensate, count) => (
                    <tr key={count + 1}>
                      <td>
                        {supportCondensate.id_CustomerUser2__name}&nbsp;
                        {supportCondensate.id_CustomerUser2__last_name}
                      </td>
                      <td>{supportCondensate.total_supports}</td>
                      <td>{supportCondensate.total_time_minutes}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-center">
            <nav className="inline-flex space-x-2.5 justify-content-center">
              <button
                className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                onClick={handlePreviousPageConcatedFun}
                disabled={pageConcate === 1}
              >
                <i className="fa-solid fa-arrow-left"></i>
                &nbsp;Anterior
              </button>
              &nbsp;
              <span className="fs-6">
                Página {pageConcate} de {limitConcate}
              </span>
              &nbsp;
              <button
                className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                onClick={handleNextPageConcatedFun}
                disabled={pageConcate === limitConcate}
              >
                Siguiente&nbsp;
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </nav>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseCondensatesFuncinary}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};
