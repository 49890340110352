import Form from "react-bootstrap/Form";
// import imgLogo from "../../../assets/images/Rocky/LOGOROCKY.png";
import { useState } from "react";
import logo from "../../../assets/images/Rocky/LOGOROCKY.png";

import { useNavigate } from "react-router-dom";
import { useForm } from "../../../utils/hooks/useForm";
import AccessUser from "../../../models/AccessUser";
import LoginService from "../../../services/loginService";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { ToastContainer } from "react-toastify";

export const LoginPrivate = () => {
  //Modal const
  //   const [show, setShow] = useState(false);
  //   const handleClose = () => setShow(false);

  //Variables for login, using useNavigate, formHtml, process and Access login
  const myNavigate = useNavigate();
  type formHtml = React.FocusEvent<HTMLFormElement>;
  const [inProcess, setProcess] = useState<boolean>(false);
  let { password, doubleLink, object } = useForm<AccessUser>(
    new AccessUser("", "")
  );
  const [showPassword, setShowPassword] = useState(false);
  //function login access
  //clear box
  // const cleanBoxs = (myForm: HTMLFormElement) => {
  //   myForm.reset();

  //   object.email = "";
  //   object.password = "";
  //   object.num_document = "";

  //   myForm.email.value = "";
  //   myForm.password.value = "";
  //   myForm.num_document.value = "";
  //   myForm.classList.remove("was-validate");
  // };
  const handleShowPasswordToggle = (e: any) => {
    setShowPassword(!showPassword);
  };

  //function to error the Token on the time, using when token is invalid or expired
  const handleTokenError = (e: any) => {
    MessageToastify("error", "El usuario no está activo.", 7000);
  };

  const generateToken = (key: string): string => {
    return btoa(`${key}-${new Date().getTime()}`);
  };
  const cleanBox = (form: HTMLFormElement) => {
    form.reset();

    object.password = "";

    (form.querySelector('[name="password"]') as HTMLInputElement).value = "";

    form.classList.remove("was-validated");
  };

  //function procces form create all log to use apiback
  const proccesForm = async (fh: formHtml) => {
    try {
      fh.preventDefault();
      setProcess(true);
      const formCurrent = fh.currentTarget;
      formCurrent.classList.add("was-validate");

      if (formCurrent.checkValidity() === false) {
        fh.preventDefault();
        fh.stopPropagation();
      } else {
        const key = process.env.REACT_APP_SEGURITY_KEY;
        if (!key) {
          console.error("⚠️ No se encontró REACT_APP_SECURITY_KEY en el .env");
          MessageToastify(
            "info",
            "No localizó la frase de seguridad. Comuníquese con Ti.",
            2000
          );
          return;
        }
        if (key === password) {
          const token = generateToken(key);
          localStorage.setItem("auth_token", token);
          myNavigate("/home/view-dashbord");
        } else {
          const form = fh.currentTarget;
          cleanBox(form);
          MessageToastify(
            "error",
            "La frase de seguridad es incorrecta.",
            2000
          );
        }
      }
    } catch (e) {
      handleTokenError(e);
    }
  };

  return (
    <>
      <div
        className="container-fluid light-gray-background d-flex justify-content-center align-content-center vh-80"
        style={{
          background:
            "linear-gradient($color-background-gradient-start, $color-background-gradient-end)",
        }}
      >
        <div
          className="login-wrapper p-4 rounded"
          style={{
            backgroundColor: "rgba(255, 255, 255, 1)",
            backdropFilter: "blur(10px)",
            width: "450px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.9)",
          }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={logo}
              alt="Logo Rocky"
              style={{
                width: "150px",
                height: "auto",
                marginLeft: "10px",
              }}
            />
          </div>

          <Form
            noValidate
            validated={inProcess}
            onSubmit={proccesForm}
            autoComplete="off"
          >
            <Form.Group controlId="password" className="mt-3">
              <Form.Label className="text-black fs-5 fw-bolder ">
                Frase de seguridad:
              </Form.Label>
              <Form.Control
                required
                name="password"
                className="form-control"
                type={showPassword ? "text" : "password"}
                placeholder="Escriba la frase de seguridad"
                value={password}
                onChange={doubleLink}
                autoComplete="off"
                style={{
                  color: "$color-primary-text",
                  borderRadius: "20px", // Ajusta el radio del borde aquí
                  boxShadow: "0px 0px 2px 0px",
                  textAlign: "center", // Centrar el texto
                  outline: "none", // Quitar la sombra azul
                }}
              />

              <Form.Control.Feedback type="invalid">
                Ingrese la frase para validar el ingreso.
              </Form.Control.Feedback>
            </Form.Group>
            <button
              className="btn btn-block login-btn mt-4 w-100"
              type="submit"
              style={{
                backgroundColor: "#0f0f6f",
                color: "$color-primary-text",
                borderRadius: "20px", // También puedes redondear el botón
                boxShadow: "0px 4px 6px #d9931175!important", // Sombra para el botón
              }}
            >
              Validadar
            </button>
          </Form>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
