class Deparment {
    public id: number;
    public name_deparment: string;

    constructor(id: number, name_deparment: string) {
      this.id = id;
      this.name_deparment = name_deparment;
    }
  }
  
  export default Deparment;
  