/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { useNavigate } from "react-router-dom";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { ToastContainer } from "react-toastify";
import IPS from "../../../models/Ips";
import User from "../../../models/user";
import "jspdf-autotable";
import Typerequest from "../../../models/type_of_request";
import SupportViewFinally from "../../../models/supportViewFinally";
import DateFilterButtons from "../../component/DateFilterButtons";

export const AdminSupport = () => {
  const [arraySupport, setArraySupport] = useState<SupportViewFinally[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arrayCustomerUser, setArrayCustomerUser] = useState<User[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [loadingConcated, setLoadingConcated] = useState(false);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDateEntry, setStartDateEntry] = useState<string>("");
  const [endDateEntry, setEndDateEntry] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arrayTypeRequest, setArrayTypeRequest] = useState<Typerequest[]>([]);
  const [arrayIPS, setArrayIPS] = useState<IPS[]>([]);
  const [selectedType, setselectedType] = useState<string>("");
  const [selectedIpsName, setselectedIpsName] = useState<string>("");
  const [page, setPage] = useState(1);
  const [pageConcate, setPageConcate] = useState(1);
  const [limit, setlimit] = useState(0);
  const [limitConcate, setlimitConcate] = useState(0);
  const [Total, setTotal] = useState(0);
  const [typeCondensate, setTypeCondensate] = useState("");
  const [pageNext, setPagenext] = useState(0);
  const [pageprevious, setPageprevious] = useState(0);
  const [expandedRow] = useState(null);
  const [show, setShow] = useState<boolean>(false);
  const [showCondensates, setShowCondensates] = useState<boolean>(false);
  const [showCondensatesIPS, setShowCondensatesIPS] = useState<boolean>(false);
  const [showCondensatesFuncionary, setShowCondensatesFuncionary] =
    useState<boolean>(false);
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);

  const [selectedSupport, setSelectedSupport] = useState<SupportViewFinally>(
    new SupportViewFinally(
      0,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      0,
      "",
      "",
      0,
      new Date(),
      new Date(),
      new Date(),
      "",
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    )
  );

  const handleClose = () => setShow(false);


  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      getSupport(page - 1); // Llama a la API con la página anterior
    }
  };

  const handleNextPage = () => {
    if (page < limit) {
      setPage(page + 1);
      getSupport(page + 1); // Llama a la API con la página siguiente
    }
  };

  //Get to all support, but filter with values to selected for the users
  const getSupport = async (page: number) => {
    setLoading(true);
    try {
      const url = `${ApiBack.SUPPORT_VIEW_FINAL}?${[
        selectedType ? `id_type_request_id=${selectedType}` : "",
        selectedIpsName ? `ipsName=${selectedIpsName}` : "",
        startDateEntry ? `startDateEntry=${startDateEntry}` : "",
        endDateEntry ? `endDateEntry=${endDateEntry}` : "",
        page ? `page=${page}` : "",
      ]
        .filter(Boolean)
        .join("&")}`;

      if (
        (startDateEntry != "" && endDateEntry == "") ||
        (startDateEntry == "" && endDateEntry != "")
      ) {
        MessageToastify(
          "error",
          "Debes seleccionar las fechas de inicio y de fin para filtrar por rango de fechas.",
          4000
        );
        setLoading(false);
        return;
      }

      try {
        const response = await ServicePrivate.requestGET(url);
        if (response.length == 0) {
          MessageToastify(
            "info",
            "No se encontraron resultados para los filtros seleccionados.",
            4000
          );
          setArraySupport([]);
          return;
        }
        if (response.results && Array.isArray(response.results)) {
          setArraySupport(response.results);
          setlimit(response.total_pages);
          setTotal(response.total_count);
          setPagenext(response.next);
          setPageprevious(response.previous);
          setLoading(false);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify("info", response.error, 4000);
          setArraySupport([]);
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify("info", "No se encuentran resultados.", 2000);
        setArraySupport([]);
        setlimit(0);
        setTotal(0);
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };


  const getAllIPS = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ACTIVE);
      setArrayIPS(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info IPS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleShow = (support: any) => {
    setSelectedSupport(support);
    setShow(true);
  };

  const getAllTypesrequest = async () => {
    try {
      const results = await ServicePrivate.requestGET(
        ApiBack.TYPE_OF_REQUEST_lIST
      );
      setArrayTypeRequest(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleTokenError = () => {
    navigate("/"); // Go to login session
  };

  const handleIpsNameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setselectedIpsName(event.target.value); // Actualiza el ID de IPS seleccionada
  };

  const truncateText = (text: string, maxLength: any) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  useEffect(() => {
    //getSupport(page);
    MessageToastify(
      "info",
      "Recuerde ingresar el filtro para ver los datos",
      2000
    );
    getAllTypesrequest();
    getAllIPS();
    setLoading(true);
    const today = new Date();
    const firstDayLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    // Formatear la fecha en YYYY-MM-DD
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    setStartDateEntry(formatDate(firstDayLastMonth));
    setEndDateEntry(formatDate(lastDayLastMonth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchTriggered && startDateEntry && endDateEntry) {
      getSupport(page);
      setIsSearchTriggered(false); // Resetear para futuras búsquedas
    }
  }, [startDateEntry, endDateEntry, page, isSearchTriggered]);

  const handleSelectCondensateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTypeCondensate(event.target.value);
  };

  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
            <h3 style={{ fontWeight: "bold" }}>Administración de soportes</h3>
          </div>

          <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
            <div className="row">
              <div className="d-flex align-items-center col-lg-8 col-md-12">
                <Form.Group className="" controlId="formIps">
                  <Form.Label>Seleccionar IPS</Form.Label>
                  <Form.Select
                    value={selectedIpsName}
                    onChange={handleIpsNameChange}
                    onClick={() => {
                      setArraySupport([]);
                      setPage(0);
                      setlimit(0);
                      setTotal(0);
                    }}
                  >
                    <option value="">Todas las IPS</option>
                    {arrayIPS.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.municipio_id.nombre_municipio}&nbsp;-&nbsp;
                        {type.nombre_ips}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formIps">
                <Form.Label>Seleccionar solicitud</Form.Label>
                <Form.Select value={selectedType} onChange={handleTyoeChange}>
                  <option value="">Todas las solicitudes</option>
                  {arrayTypeRequest.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.typerequest}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group> */}
                <Form.Group className="" controlId="startDateEntry">
                  <Form.Label>Fecha de inicio</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    // id="startDateEntry"
                    value={startDateEntry}
                    onChange={(e) => setStartDateEntry(e.target.value)}
                    onClick={() => {
                      setArraySupport([]);
                    }}
                  />
                </Form.Group>
                <Form.Group className="" controlId="startDateEntry">
                  <Form.Label>Fecha de fin</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    // id="endDateEntry"
                    value={endDateEntry}
                    onChange={(e) => setEndDateEntry(e.target.value)}
                    onClick={() => {
                      setArraySupport([]);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="d-flex align-items-center mt-lg-4 col-lg-4 col-md-12 mb-3">
                <DateFilterButtons
                  setStartDateEntry={setStartDateEntry}
                  setEndDateEntry={setEndDateEntry}
                  setIsSearchTriggered={setIsSearchTriggered}
                  setPage={setPage}
                  getMySupports={getSupport}
                  page={page}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="fs-6 fw-light">
                  <div className="row d-flex">
                    <div className="col-lg-2 col-md-4 col-sm-4 align-content-center">
                      <p>
                        Soportes encontrados:&nbsp;
                        <b className="fw-bold">{arraySupport?.length}</b>
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-3 align-content-center">
                      <p>
                        Soportes totales:&nbsp;
                        <b className="fw-bold">{Total}</b>
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-3 align-content-center">
                      <p>
                        Páginas totales:&nbsp;
                        <b className="fw-bold">{limit}</b>
                      </p>
                    </div>

                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col" style={{ width: "10%" }}>
                          Fecha
                        </th>
                        <th scope="col" style={{ width: "5%" }}>
                          IPS
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Funcionario
                        </th>
                        <th scope="col" style={{ width: "4%" }}>
                          T.
                        </th>
                        <th
                          scope="col"
                          className="d-none d-md-table-cell"
                          style={{ width: "20%" }}
                        >
                          Requerimiento
                        </th>
                        <th
                          scope="col"
                          className="d-none d-md-table-cell"
                          style={{ width: "20%" }}
                        >
                          Respuesta
                        </th>
                        <th style={{ width: "5%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <>
                          <tr>
                            <td colSpan={12} className="text-center">
                              <div className="d-flex flex-column align-items-center">
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    color: "#000",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Cargando...
                                  </span>
                                </div>
                                <p
                                  className="mt-3 fs-5"
                                  style={{ color: "#000" }}
                                >
                                  Cargando datos, por favor espere...
                                </p>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {arraySupport?.length === 0 && (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <div className="d-flex flex-column align-items-center">
                                  <p
                                    className="mt-3 fs-5"
                                    style={{ color: "#000" }}
                                  >
                                    No tiene soportes en el momento de buscar.
                                    Por favor, ingrese o verifique el filtro
                                    para la búsqueda.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                          {arraySupport.map((myProfessional, count) => (
                            <tr key={count + 1}>
                              <td>
                                {new Date(
                                  myProfessional.support_date
                                ).toLocaleString("es-CO", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </td>
                              <td>
                                <span
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={myProfessional?.id_ips__nombre_ips} // Este es el texto que se mostrará en el tooltip
                                >
                                  {
                                    myProfessional.id_ips__municipio_id__nombre_municipio
                                  }
                                </span>
                              </td>
                              <td>
                                {myProfessional?.id_CustomerUser2__name}&nbsp;
                                {myProfessional?.id_CustomerUser2__last_name}
                              </td>
                              <td>
                                {myProfessional?.time_support || 0}&nbsp;min.
                              </td>
                              {/* <td>
                                {expandedRow === count
                                  ? myProfessional.subtype_request
                                      ?.subtype_request
                                  : truncateText(
                                      myProfessional.subtype_request
                                        ?.subtype_request,
                                      10
                                    )}
                              </td> */}
                              <td className="d-none d-md-table-cell">
                                {expandedRow === count
                                  ? myProfessional?.requirement
                                  : truncateText(
                                      myProfessional?.requirement,
                                      50
                                    )}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {expandedRow === count
                                  ? myProfessional?.answer
                                  : truncateText(myProfessional?.answer, 50)}
                              </td>
                              <td>
                                <button
                                  className="badge fs-6 py-1 pe-2 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                                  type="button"
                                  onClick={() => handleShow(myProfessional)}
                                >
                                  <i className="fas fa-eye"></i>
                                  &nbsp;Ver
                                </button>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <nav className="inline-flex space-x-2.5 justify-content-center">
                    <button
                      className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                      onClick={handlePreviousPage}
                      disabled={page === 1}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                      &nbsp;Anterior
                    </button>
                    &nbsp;
                    <span className="fs-6">
                      Página {page} de {limit}
                    </span>
                    &nbsp;
                    <button
                      className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                      onClick={handleNextPage}
                      disabled={page === limit}
                    >
                      Siguiente&nbsp;
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
            {/* Modal para eliminar */}
            {/* *********************************************************************************/}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del soporte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSupport && (
            <div>
              <p>
                <strong>Ips:</strong>&nbsp;
                {selectedSupport.id_ips__nombre_ips}
              </p>
              <p>
                <strong>Estado:</strong>&nbsp;
                {selectedSupport.how_it_conclude || "Sin información"}
              </p>
              <p>
                <strong>Solicitante:</strong>&nbsp;
                {selectedSupport.name_solicited}
              </p>
              <p>
                <strong>Finalizado por:</strong>&nbsp;
                {selectedSupport.id_CustomerUser2__name}&nbsp;
                {selectedSupport.id_CustomerUser2__last_name}
              </p>
              <p>
                <strong>Teléfono:</strong>&nbsp;
                {selectedSupport.number || "No tiene teléfono disponible"}
              </p>
              <p>
                <strong>Tiempo de soporte:</strong>&nbsp;
                {selectedSupport.time_support || 0}&nbsp;minutos.
              </p>
              <p>
                <strong>Requerimiento:</strong>&nbsp;
                {selectedSupport.requirement}
              </p>
              <strong>Respuesta:</strong>
              <br />
              <Form.Control
                required
                as="textarea"
                name="requirement"
                className="form-control"
                id="requirement"
                placeholder="Escriba o seleccione la descripción del soporte."
                value={selectedSupport.answer}
                aria-describedby="requirement"
                autoComplete="off"
                rows={5}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};
