import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import IPS from "../../../models/Ips";
import { default as Subtype_request } from "../../../models/sub_type_of_request";
import Support from "../../../models/support";
import Typerequest from "../../../models/type_of_request";
import User from "../../../models/user";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { useForm } from "../../../utils/hooks/useForm";
import { Button } from "react-bootstrap";
import Municipio from "../../../models/municipio";
import Template from "../../../models/Template";
import Deparment from "../../../models/Deparment";
import SupportViewFinally from "../../../models/supportViewFinally";

export const CreateSupport = () => {
  const [arrayTemplate, setArrayTemplate] = useState<Template[]>([]);
  const [process, setProcess] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showRes, setShowRes] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const [seleccion, setSeleccion] = useState<"si" | "no" | null>("si");

  const [arrayIps, setArrayips] = useState<IPS[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arraytyperequest, setArraytype_request] = useState<Typerequest[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arrayUsers, setArrayUsers] = useState<User>();
  const [arrayCustomerUser, setArrayCustomerUser] = useState<User[]>([]);

  type formaHtml = React.FormEvent<HTMLFormElement>;

  const [subtypes, setSubtypes] = useState<Subtype_request[]>([]);
  const [supportDate, setSupportDate] = useState("");
  const [supportTime, setSupportTime] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [srequirement, setRequirement] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [aanswer, setAnswer] = useState("");
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleCloseRes = () => setShowRes(false);
  const handleCloseHistory = () => setShowHistory(false);
  const handleCloseInfo = () => setShowInfo(false);

  const [id_subtype_request_id, setIdSubtypeRequestId] = useState("");
  const [typeRequestId, setTypeRequestId] = useState("");
  const [search, setSearch] = useState("");

  const [nameSolicited, setNameSolicited] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [arraySupport, setArraySupport] = useState<SupportViewFinally[]>([]);
  const [expandedRow] = useState(null);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(0);
  const [Total, setTotal] = useState(0);

  const [selectedSupport, setSelectedSupport] = useState<SupportViewFinally>(
    new SupportViewFinally(
      0,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      0,
      "",
      "",
      0,
      new Date(),
      new Date(),
      new Date(),
      "",
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    )
  );

  let {
    id_ips_id,
    role2,
    medio,
    number,
    requirement,
    answer,
    is_active,
    is_close,
    time_support,
    doubleLink,
    object,
  } = useForm<Support>(
    new Support(
      0,
      new IPS(
        0,
        "",
        "",
        true,
        new Municipio(0, "", "", new Deparment(0, "")),
        0,
        new Date(),
        false
      ),
      0,
      new User(0, "", "", "", "", false, "", "", ""),
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      true,
      false,
      new User(0, "", "", "", "", false, "", "", ""),
      0,
      "",
      new Typerequest(0, ""),
      0,
      new Subtype_request(0, "", new Typerequest(0, "")),
      0,
      new Date(),
      new Date(),
      new Date(),
      "",
      5,
      "",
      ""
    )
  );

  function submitHandler(e: any) {
    e.preventDefault();
  }

  const handleSelectChange = (e: any) => {
    const selectedId = e.target.value;
    setIdSubtypeRequestId(selectedId);

    // Encuentra el subtype correspondiente para obtener el type_request_id
    const selectedSubtype = subtypes.find(
      (subtype) => subtype.type_request_id.id === selectedId
    );
    if (selectedSubtype) {
      const id_type = selectedSubtype.type_request_id;

      setTypeRequestId(String(id_type)); // Asumiendo que type_request_id está en el objeto subtype
    }
  };

  const today = new Date();
  const eightDaysAgo = new Date(today);
  eightDaysAgo.setDate(today.getDate() - 8);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const myNavigate = useNavigate();

  const handleTokenError = () => {
    myNavigate("/");
  };

  const getProfessionals = async () => {
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      const url = ApiBack.USER_LIST_ONE + "/" + userId;
      const results = await ServicePrivate.requestGET(url);
      localStorage.setItem("user", results.num_document);
      if (results) {
        setArrayUsers(results);
        setLoading(false);
      }
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    } catch (error) {
      console.error("Error fetching professionals:", error);
      setLoading(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    }
  };

  const [isActive] = useState<boolean>(is_active);
  const [isClose] = useState<boolean>(is_close);

  const getAllUser = async () => {
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      console.log(userId);

      const url = ApiBack.USER_LIST_ONE + "/" + userId;
      const results = await ServicePrivate.requestGET(url);
      console.log(results);

      const customerUserId = results.id_CustomerUser?.id;

      if (customerUserId !== null) {
        localStorage.setItem("user", customerUserId.toString());
      }

      setLoading(false); // Finaliza la carga

      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    } catch (error) {
      console.error("Error fetching professionals:", error);
      setLoading(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const cleanBox = (form: HTMLFormElement) => {
    form.reset();

    object.name_solicited = "";
    object.role2 = "";
    object.id_CustomerUser = new User(0, "", "", "", "", false, "", "", "");
    object.number = "";
    object.email = "";
    object.requirement = "";
    object.answer = "";
    object.is_active = true;
    object.is_close = false;
    object.id_CustomerUser2 = new User(0, "", "", "", "", false, "", "", "");
    object.how_it_conclude = "";
    object.typerequest_id = new Typerequest(0, "");
    object.id_Subtype_request_id = 0;
    setIdSubtypeRequestId("");
    setNameSolicited("");
    object.support_date = new Date();
    object.create_date = new Date();
    object.update_date = new Date();
    object.id_ips_id = 0;
    object.time_support = 5;

    (form.querySelector('[name="name_solicited"]') as HTMLInputElement).value =
      "";
    (form.querySelector('[name="role2"]') as HTMLInputElement).value = "";
    (form.querySelector('[name="number"]') as HTMLInputElement).value = "";
    (form.querySelector('[name="requirement"]') as HTMLInputElement).value = "";

    setSeleccion("si");

    form.classList.remove("was-validated");
  };

  const sendForm = async (fh: formaHtml) => {
    try {
      fh.preventDefault();
      setProcess(true);
      const form = fh.currentTarget;

      form.classList.add("was-validated");

      if (form.checkValidity() === false) {
        fh.preventDefault();
        fh.stopPropagation();
        setProcess(false);
        MessageToastify(
          "error",
          "Por favor, completa todos los campos requeridos.",
          2000
        );
        return;
      }
      const today = new Date();
      const eightDaysAgo = new Date(today);
      eightDaysAgo.setDate(today.getDate() - 8);
      const currentTime = today.toTimeString().split(" ")[0];

      const combinedDateTime = `${supportDate}T${supportTime}`;
      // Validación de rango de fechas

      const selectedDate = new Date(supportDate);
      if (selectedDate < eightDaysAgo) {
        MessageToastify(
          "error",
          "La fecha no puede ser menor a 8 días desde hoy.",
          2000
        );
        return;
      } else if (selectedDate > today) {
        MessageToastify(
          "error",
          "La fecha no puede superar la fecha actual.",
          2000
        );
        return;
      } else if (
        supportDate === today.toISOString().split("T")[0] &&
        supportTime > currentTime
      ) {
        MessageToastify(
          "error",
          "La hora no puede ser mayor a la hora actual.",
          2000
        );
        setProcess(false);
        return;
      } else if (time_support === 0) {
        MessageToastify(
          "error",
          "El tiempo del soporte debe ser mayor a 0, verifiqué el tiempo del soporte.",
          2000
        );
        setProcess(false);
        return;
      }

      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;

      const formData = {
        id_ips_id: object.id_ips_id,
        id_CustomerUser_id: userId,
        role2: object.role2,
        medio: object.medio,
        name_solicited: nameSolicited,
        number: object.number,
        email: object.email.toLowerCase(),
        requirement: object.requirement,
        answer: object.answer || "",
        is_active: seleccion === "si" ? false : isActive,
        is_close: object.how_it_conclude || object.answer ? true : isClose,
        id_CustomerUser2_id: object.id_CustomerUser2.id || userId,
        time_support: object.time_support,
        how_it_conclude: object.how_it_conclude || "",
        id_type_request_id: typeRequestId,
        id_Subtype_request_id: id_subtype_request_id,
        support_date: combinedDateTime,
      };

      if (seleccion === "no") {
        formData.how_it_conclude = object.how_it_conclude || "EN_PROGRESO";
      }

      const response = await ServicePrivate.requestPOST(
        ApiBack.SUPPORT_CREATE,
        formData
      );

      if (response.OK) {
        setProcess(false);
        MessageToastify(
          "error",
          "Error al momento de crear el soporte, verifique los datos ingresados.",
          2000
        );
      } else {
        setProcess(false);
        if (seleccion === "no") {
          MessageToastify(
            "success",
            "Soporte creado exitosamente pero no finalizado. Por favor, finalícelo lo antes posible.",
            2000
          );
        }
        if (seleccion === "si") {
          MessageToastify(
            "success",
            "Soporte creado y finalizado exitosamente.",
            2000
          );
        } else {
          MessageToastify(
            "success",
            "Soporte creado exitosamente pero no finalizado. Por favor, finalícelo lo antes posible.",
            2000
          );
        }
        cleanBox(form);
      }
    } catch (error) {
      setProcess(false);
      MessageToastify(
        "error",
        "Error en el momento de crear el soporte, comuniquese con el TI.",
        7000
      );
    }
  };

  const handleSiClick = () => setSeleccion("si");
  const handleNoClick = () => setSeleccion("no");

  const getAllUsers = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.USER_VIEW);
      setArrayCustomerUser(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  //Get to all support, but filter with values to selected for the users
  const getSupportByID = async (page: number) => {
    setLoading(true);
    try {
      if (object.id_ips_id === 0) {
        MessageToastify(
          "info",
          "Debes seleccionar una IPS para poder buscar el historial.",
          4000
        );
        return;
      }
      const url = `${ApiBack.SUPPORT_VIEW_FINAL_HISTORY}?${[
        typeRequestId ? `id_type_request_id=${typeRequestId}` : "",
        object.id_ips_id ? `ipsName=${object.id_ips_id}` : "",
        page ? `page=${page}` : "",
      ]
        .filter(Boolean)
        .join("&")}`;

      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          setArraySupport(response.results);
          setlimit(response.total_pages);
          setLoading(false);
          setTotal(response.total_count);
          setShowHistory(true);
        } else {
          console.error("La respuesta no es un array:", response);
          MessageToastify("info", response.error, 4000);
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify("info", "No se encuentran resultados.", 2000);
        setArraySupport([]);
        setlimit(0);
        setTotal(0);
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getAllIps = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ACTIVE);
      setArrayips(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info ips:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getAllTypesrequest = async () => {
    try {
      const results = await ServicePrivate.requestGET(
        ApiBack.TYPE_OF_REQUEST_lIST
      );
      setArraytype_request(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info type request:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getTemplateRes = async () => {
    setLoading(true);
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;

      const id_request = id_subtype_request_id;
      const url = `${ApiBack.TEMPALTE_LIST_BY_USER_RES}/${userId}/${id_request}`;
      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          const filteredResults = response.results;
          setArrayTemplate(filteredResults);
        } else {
          console.error("La respuesta no es un arreglo:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos, por favor comuníquese con TI.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentran datos, por favor seleccione un tipo de requerimiento.",
          4000
        );
        setArrayTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getTemplateResAll = async () => {
    setLoading(true);
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      const url = `${ApiBack.TEMPALTE_LIST_BY_RES_ALL}/${userId}`;
      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          const filteredResults = response.results;
          setArrayTemplate(filteredResults);
        } else {
          console.error("La respuesta no es un arreglo:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos, por favor comuníquese con TI.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentran datos, por favor seleccione un tipo de requerimiento.",
          4000
        );
        setArrayTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getTemplateDes = async () => {
    setLoading(true);
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;

      const id_request = id_subtype_request_id;
      const url = `${ApiBack.TEMPALTE_LIST_BY_USER}/${userId}/${id_request}`;
      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          const filteredResults = response.results;
          setArrayTemplate(filteredResults);
        } else {
          console.error("La respuesta no es un arreglo:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos, por favor comuníquese con TI.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentran datos, por favor seleccione un tipo de requerimiento.",
          4000
        );
        setArrayTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getTemplateDesAll = async () => {
    setLoading(true);
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      const url = `${ApiBack.TEMPALTE_LIST_BY_DESCRIP_ALL}/${userId}`;
      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          const filteredResults = response.results;
          setArrayTemplate(filteredResults);
        } else {
          console.error("La respuesta no es un arreglo:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos, por favor comuníquese con TI.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra el datos en la base de datos.",
          4000
        );
        setArrayTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const handleShow = () => {
    getTemplateDes();
    setShow(true);
  };
  const handleShowRes = () => {
    getTemplateRes();
    setShowRes(true);
  };

  const handleNextPage = () => {
    if (page < limit) {
      setPage(page + 1);
      getSupportByID(page + 1); // Llama a la API con la página siguiente
    }
  };
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      getSupportByID(page - 1); // Llama a la API con la página anterior
    }
  };

  const addTemplateToRequirement = (content: string) => {
    const updatedRequirement = object.requirement
      ? `${object.requirement} ${content}`
      : content;

    object.requirement = updatedRequirement;
    setRequirement(updatedRequirement);
    MessageToastify("info", "Se transladó el texto.", 1000);
  };

  const addTemplateToResponse = (contentREs: string) => {
    const updatedAnswer = object.answer
      ? `${object.answer} ${contentREs}`
      : contentREs;

    object.answer = updatedAnswer;
    setAnswer(updatedAnswer);
    MessageToastify("info", "Se transladó el texto.", 1000);
  };

  const truncateText = (text: string, maxLength: any) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  useEffect(() => {
    getAllIps();
    getAllUsers();
    getAllTypesrequest();
    getAllUser();
    getProfessionals();

    const today = new Date();
    const formattedDate = today
      .toLocaleDateString("es-CO", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-"); // Convierte de DD/MM/YYYY a YYYY-MM-DD
    setSupportDate(formattedDate);

    // Establecer la hora actual en formato HH:MM
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    setSupportTime(`${hours}:${minutes}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();

    if (!/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]*$/.test(value)) return;

    if (value.length < 3) {
      setErrorMessage("Debe tener al menos 3 caracteres.");
    } else if (value.length > 60) {
      setErrorMessage("No puede superar los 60 caracteres.");
    } else if (value.length === 0) {
      setErrorMessage("Ingrese el nombre del solicitante.");
    } else {
      setErrorMessage("");
    }

    setNameSolicited(value);
  };

  const handleShowInfoSupport = (support: any) => {
    setSelectedSupport(support);
    setShowInfo(true);
  };

  useEffect(() => {
    if (search.trim() === "") {
      setArrayTemplate(arrayTemplate);
    } else {
      const filtered = arrayTemplate.filter((template) =>
        `${template.id}${template.template_content}`
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      setArrayTemplate(filtered);
    }
  }, [search, arrayTemplate]);
  return (
    <>
      <div>
        <div className="row d-flex">
          <div className="col-12 grid-margin stretch-card justify-content-center">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title" style={{ textAlign: "center" }}>
                  <i className="fa-solid fa-folder-plus"></i>&nbsp;Formulario
                  de creación
                </h4>
                <Form
                  className="forms-sample"
                  validated={process}
                  onSubmit={sendForm}
                  noValidate
                  ref={formRef}
                >
                  <div className="row ms-lg-5 me-lg-5">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-7 col-md-10 col-sm-6 d-flex">
                          <Form.Group controlId="id_ips_id">
                            <Form.Label className="fw-bold">IPS:</Form.Label>
                            <Form.Select
                              required
                              name="id_ips_id"
                              value={id_ips_id}
                              onChange={doubleLink}
                              className="form-select custom-select"
                              onClick={() => {
                                const hours = String(today.getHours()).padStart(
                                  2,
                                  "0"
                                );
                                const minutes = String(
                                  today.getMinutes()
                                ).padStart(2, "0");
                                setSupportTime(`${hours}:${minutes}`);
                                const todays = new Date();
                                const formattedDate = todays
                                  .toLocaleDateString("es-CO", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .split("/")
                                  .reverse()
                                  .join("-"); // Convierte de DD/MM/YYYY a YYYY-MM-DD
                                setSupportDate(formattedDate);
                              }}
                              
                            >
                              <option value="">Seleccione la IPS.</option>
                              {arrayIps.map((myRol) => (
                                <option value={myRol.id}>
                                  {myRol.municipio_id.nombre_municipio}
                                  &nbsp;-&nbsp;
                                  {myRol.date_contract
                                    ? new Date(
                                        myRol.date_contract
                                      ).toLocaleString("es-CO", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })
                                    : "No Tiene contrato"}
                                  &nbsp;-&nbsp;
                                  {myRol.nombre_ips}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Por favor seleccione la IPS la cual es
                              obligatoria.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-6 align-content-center">
                          <br />
                          <button
                            type="button"
                            onClick={() => {
                              getSupportByID(1);
                            }}
                            className="badge d-flex align-items-center fs-6 pe-2 text-primary-emphasis bg-body-secondary border border-dark-subtle rounded-pill"
                          >
                            <i className="fa-solid fa-magnifying-glass"></i>
                            &nbsp;Histórico
                          </button>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <Form.Group controlId="id_subtype_request_id">
                            <Form.Label className="fw-bold ">
                              Tipo de requerimiento:
                            </Form.Label>
                            <Form.Select
                              required
                              name="id_subtype_request_id"
                              value={id_subtype_request_id}
                              onChange={handleSelectChange}
                              className="form-select"
                            >
                              <option value="">
                                Seleccione el tipo de requerimiento
                              </option>
                              {arraytyperequest.map((typeReques, count) => (
                                <option
                                  value={typeReques.id}
                                  key={typeReques.id}
                                >
                                  {count + 1}&nbsp;-&nbsp;
                                  {typeReques.typerequest}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione el tipo de requerimiento.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                          <Form.Group controlId="name_solicited">
                            <Form.Label className="fw-bold mb-2">
                              Nombre solicitante:
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="name_solicited"
                              className="form-control"
                              placeholder="Escriba nombre."
                              value={nameSolicited}
                              onChange={handleChange}
                              autoComplete="off"
                              maxLength={60}
                              minLength={3}
                              isInvalid={!!errorMessage}
                              onClick={() => {
                                const hours = String(today.getHours()).padStart(
                                  2,
                                  "0"
                                );
                                const minutes = String(
                                  today.getMinutes()
                                ).padStart(2, "0");
                                setSupportTime(`${hours}:${minutes}`);
                                const todays = new Date();
                                const formattedDate = todays
                                  .toLocaleDateString("es-CO", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .split("/")
                                  .reverse()
                                  .join("-"); // Convierte de DD/MM/YYYY a YYYY-MM-DD
                                setSupportDate(formattedDate);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errorMessage}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-lg-2 col-md-4 col-sm-6">
                          <Form.Group controlId="role2">
                            <Form.Label className="fw-bold mb-2 ">
                              Cargo:
                            </Form.Label>
                            <Form.Select
                              required
                              name="role2"
                              value={role2}
                              onChange={doubleLink}
                              className="form-select"
                            >
                              <option value="">Seleccione el cargo</option>
                              <option value="ADMINISTRATIVO">
                                ADMINISTRATIVO
                              </option>
                              <option value="ALMACÉN">ALMACÉN</option>
                              <option value="AUDITOR">AUDITOR</option>
                              <option value="AUXILIARES">AUXILIARES</option>
                              <option value="AUX_ADMINISTRATIVO">
                                AUX ADMINISTRATIVO
                              </option>
                              <option value="AUX_ENFERMERÍA">
                                AUX ENFERMERÍA
                              </option>
                              <option value="AUX_ODONTOLOGIA">
                                AUX ODONTOLOGÍA
                              </option>
                              <option value="AUX_RADICACIÓN">
                                AUX RADICACIÓN
                              </option>
                              <option value="ASESOR_CONTABLE">
                                ASESOR CONTABLE
                              </option>
                              <option value="BACTERIOLOGO">BACTERIOLOGO</option>
                              <option value="CARTERA">CARTERA</option>
                              <option value="CAJERO">CAJERO</option>
                              <option value="COORD_FACTURACIÓN">
                                COORD FACTURACIÓN
                              </option>
                              <option value="CONTROL_INTERNO">
                                CONTROL INTERNO
                              </option>
                              <option value="COMUNICACIONES">
                                COMUNICACIONES
                              </option>
                              <option value="FACTURACIÓN">FACTURACIÓN</option>
                              <option value="FINANZAS">FINANZAS</option>
                              <option value="FISIOTERAPÉUTA">
                                FISIOTERAPÉUTA
                              </option>
                              <option value="GERENCIA">GERENCIA</option>
                              <option value="GESTION_SOCIAL">
                                GESTIÓN SOCIAL
                              </option>
                              <option value="INFORMES">INFORMES</option>
                              <option value="JEFE">JEFE</option>
                              <option value="LOGISTICA">LOGÍSTICA</option>
                              <option value="MÉDICOS">MÉDICOS</option>
                              <option value="ODONTÓLOGO">ODONTÓLOGO</option>
                              <option value="PLANIFICACION">
                                PLANIFICACIÓN
                              </option>
                              <option value="PSICOLÓGA">PSICOLÓGA</option>
                              <option value="RECURSOS_HUMANOS">
                                RECURSOS HUMANOS
                              </option>
                              <option value="REGENTE">REGENTE</option>
                              <option value="SECRETARIA_GENERAL">
                                SECRETARÍA GENERAL
                              </option>
                              <option value="SISTEMAS">SISTEMAS</option>
                              <option value="SIAU">SIAU</option>
                              <option value="TRANSPORTE">TRANSPORTE</option>
                              <option value="TESORERA">TESORERA</option>
                              <option value="VACUNACIÓN">VACUNACIÓN</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione el cargo.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-lg-2 col-md-4 col-sm-6">
                          <Form.Group controlId="medio">
                            <Form.Label className="fw-bold mb-2">
                              Plataforma:
                            </Form.Label>
                            <Form.Select
                              required
                              name="medio"
                              value={medio}
                              onChange={doubleLink}
                              className="form-select"
                            >
                              <option value="WHATSAPP">WHATSAPP</option>
                              <option value="CORREO"> CORREO</option>
                              <option value="TELEFONO"> TELEFONO</option>
                            </Form.Select>
                          </Form.Group>
                        </div>

                        <div className="col-lg-2 col-md-4 col-sm-6">
                          <Form.Group controlId="number">
                            <Form.Label className="fw-bold mb-2 ">
                              Número telefónico:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="number"
                              className="form-control"
                              placeholder="0000000000"
                              value={number}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value.length <= 13) {
                                  doubleLink(e);
                                }
                              }}
                              autoComplete="off"
                              maxLength={13}
                            />
                          </Form.Group>
                        </div>
                        {/* <div className="col-lg-3 col-sm-12">
                          <Form.Group controlId="email">
                            <Form.Label
                              className="fw-bold mb-2 "
                              htmlFor="email"
                            >
                              Correo electrónico:
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              className="form-control"
                              id="email"
                              placeholder="Escriba su correo electrónico."
                              value={email}
                              onChange={(e) => doubleLink(e)}
                              autoComplete="off"
                              isInvalid={
                                !email.includes("@") && email.length > 0
                              } 
                            />
                            <Form.Control.Feedback type="invalid">
                              Correo electrónico debe contener "@".
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div> */}
                        <div className="col-lg-2 col-md-4 col-sm-6">
                          <Form.Group controlId="support_time">
                            <Form.Label className="fw-bold mb-2 ">
                              Hora de soporte:
                            </Form.Label>
                            <Form.Control
                              type="time"
                              name="support_time"
                              className="form-control"
                              value={supportTime}
                              onChange={(e) => setSupportTime(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                          <Form.Group controlId="support_date">
                            <Form.Label className="fw-bold mb-2 ">
                              Fecha de soporte:
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="support_date"
                              className="form-control"
                              value={supportDate}
                              onChange={(e) => setSupportDate(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-lg-10 col-md-12 col-sm-12">
                        <Form.Group controlId="requirement">
                          <Form.Label className="fw-bold">
                            Descripción de soporte:
                          </Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            name="requirement"
                            className="form-control"
                            placeholder="Escriba o seleccione la descripción del soporte."
                            value={requirement}
                            onChange={doubleLink}
                            aria-describedby="requirement"
                            autoComplete="off"
                            rows={5}
                          />
                          <Form.Control.Feedback type="invalid">
                            La descripción del soportes es obligatoria, escriba
                            o seleccione de las plantillas ya inscritas.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2 d-flex pt-5 align-content-center justify-content-center">
                        <div>
                          <a
                            href="#/"
                            className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 text-primary-emphasis bg-primary-subtle border border-primary-subtle"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#FFF7E0",
                              borderRadius: "50px",
                              border: "2px solid transparent",
                              color: "#835C0E",
                              fontWeight: "bold",
                              cursor: "pointer",
                              transition: "background-color 0.3s ease",
                              height: "auto",
                            }}
                            onClick={() => handleShow()}
                          >
                            <i className="fa-solid fa-plus"></i>&nbsp;Plantillas
                          </a>
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <i>
                          <strong>
                            El soporte ha finalizado y tiene respuesta:
                          </strong>
                        </i>
                        <Button
                          onClick={handleSiClick}
                          variant={seleccion === "si" ? "primary" : "secondary"}
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            color: seleccion === "si" ? "white" : "gray",
                            backgroundColor:
                              seleccion === "si" ? "#3a09eb" : "#f0f0f0",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            border: "2px solid #ccc",
                            cursor: "pointer",
                          }}
                        >
                          Sí
                        </Button>
                        <Button
                          onClick={handleNoClick}
                          variant={seleccion === "no" ? "primary" : "secondary"}
                          style={{
                            fontSize: "14px",
                            color: seleccion === "no" ? "white" : "gray",
                            backgroundColor:
                              seleccion === "no" ? "#3a09eb" : "#f0f0f0",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            border: "2px solid #ccc",
                            cursor: "pointer",
                          }}
                        >
                          No
                        </Button>

                        {seleccion === "si" && (
                          <div>
                            <div className="row">
                              <div className="col-lg-2">
                                <Form.Group controlId="time_support">
                                  <Form.Label className="fw-bold mb-2 ">
                                    Tiempo (minutos):
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    name="time_support"
                                    className="form-control"
                                    value={time_support}
                                    placeholder="Tiempo en minutos."
                                    onChange={doubleLink}
                                    autoComplete="off"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    El tiempo es obligatorio para finalizar el
                                    soporte
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="col-lg-8">
                                <Form.Group controlId="answer">
                                  <Form.Label className="fw-bold mb-2 ">
                                    Respuesta:
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    as="textarea"
                                    name="answer"
                                    className="form-control border-1 border-yellow-500"
                                    value={answer}
                                    placeholder="Escriba o seleccione la respuesta del soporte."
                                    onChange={doubleLink}
                                    autoComplete="off"
                                    rows={5}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    La respuesta para finalizar el soportes es
                                    obligatoria.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="col-lg-2 d-flex pt-5 align-content-center justify-content-center">
                                <div>
                                  <a
                                    href="#/"
                                    className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 text-info-emphasis bg-info-subtle border border-info-subtles"
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "#FFF7E0",
                                      border: "2px solid transparent",
                                      borderRadius: "50px",
                                      color: "#835C0E",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s ease",
                                      height: "auto",
                                    }}
                                    onClick={() => handleShowRes()}
                                  >
                                    <i className="fa-solid fa-plus"></i>
                                    &nbsp;Plantillas
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {seleccion === "no" && (
                          <div className="row">
                            <div className="col-lg-2">
                              {/* <Form.Group controlId="id_CustomerUser2_id">
                                <Form.Label
                                  className="fw-bold mb-2 "
                                  htmlFor="id_CustomerUser2_id"
                                >
                                  Encargado:
                                </Form.Label>
                                <Form.Select
                                  required
                                  id="id_CustomerUser2_id"
                                  name="id_CustomerUser2_id"
                                  value={id_CustomerUser2_id}
                                  onChange={doubleLink}
                                  className="form-select"
                                >
                                  <option value="">
                                    Seleccione al encargado
                                  </option>
                                  {arrayCustomerUser.map((myRol) => (
                                    <option value={myRol.id} key={myRol.name}>
                                      {`${myRol.role} - ${myRol.name}`}
                                    </option>
                                  ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Seleccione al encargado.
                                </Form.Control.Feedback>
                              </Form.Group> */}
                              <Form.Group controlId="time_support">
                                <Form.Label className="fw-bold mb-2 ">
                                  Tiempo:
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="number"
                                  name="time_support"
                                  className="form-control"
                                  value={time_support}
                                  placeholder="Tiempo en minutos."
                                  onChange={doubleLink}
                                  autoComplete="off"
                                  defaultValue={5}
                                  min={1}
                                />
                                <Form.Control.Feedback type="invalid">
                                  El tiempo es obligatorio para finalizar el
                                  soporte
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                            <div className="col-lg-8">
                              <Form.Group controlId="answer">
                                <Form.Label className="fw-bold mb-2 ">
                                  Observación:
                                </Form.Label>
                                <Form.Control
                                  required
                                  as="textarea"
                                  name="answer"
                                  className="form-control border-1 border-yellow-500"
                                  value={answer}
                                  placeholder="Escriba o seleccione la respuesta del soporte."
                                  onChange={doubleLink}
                                  autoComplete="off"
                                  rows={5}
                                />
                                <Form.Control.Feedback type="invalid">
                                  La observación es obligatoria, por favor
                                  diligenciela.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </div>
                            <div className="col-lg-2 d-flex pt-lg-5 pt-sm-0 align-content-center justify-content-center">
                              <div>
                                <a
                                  href="#/"
                                  className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 text-info-emphasis bg-info-subtle border border-info-subtles"
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#FFF7E0",
                                    border: "2px solid transparent",
                                    borderRadius: "50px",
                                    color: "#835C0E",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s ease",
                                    height: "auto",
                                  }}
                                  onClick={() => handleShowRes()}
                                >
                                  <i className="fa-solid fa-plus"></i>
                                  &nbsp;Plantillas
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="col-lg-12 col-sm-12 mt-7  d-flex justify-content-center"
                      style={{
                        padding: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-outline-info button-create badge fs-6 p-2 pe-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                        disabled={process}
                      >
                        {process ? (
                          <>
                            <i className="fa-solid fa-spinner fa-spin"></i>
                            &nbsp;Guardando...
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-plus"></i>&nbsp;Guardar
                          </>
                        )}
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="badge d-flex align-items-center fs-6 p-2 pe-3 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                      >
                        <i className="fa-solid fa-arrow-left-long"></i>
                        &nbsp;Regresar
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          if (formRef.current) {
                            cleanBox(formRef.current);
                          }
                        }}
                        className="badge d-flex align-items-center fs-6 p-2 pe-3 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-pill"
                      >
                        <i className="fa-solid fa-broom"></i>&nbsp;Limpiar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {/* this modal to use my templates for description  */}
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <div className="row">
              <div className="col-lg-4">
                <Modal.Title>Mis plantillas</Modal.Title>
              </div>
              <div className="col-lg-2">
                <button
                  type="button"
                  onClick={() => {
                    getTemplateDesAll();
                  }}
                  className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                >
                  <i className="fa-solid fa-globe"></i>&nbsp;Todas
                </button>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Form
                  role="search"
                  id="form-professional"
                  onSubmit={submitHandler}
                >
                  <InputGroup className="">
                    <Form.Control
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Buscar descripción"
                    ></Form.Control>
                  </InputGroup>
                </Form>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped table-sm rounded-table">
              <thead className="table-dark">
                <tr>
                  <th scope="col" style={{ width: "5%" }}>
                    Id
                  </th>
                  <th scope="col" style={{ width: "95%" }}>
                    Contenido
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {arrayTemplate.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <p>No hay resultados</p>
                    </td>
                  </tr>
                )}

                {arrayTemplate.map((myProfessional, count) => (
                  <tr key={count + 1}>
                    <td>
                      <p>{myProfessional.id}</p>
                    </td>
                    <td>
                      <p>{myProfessional.template_content}</p>
                    </td>
                    <td>
                      <Button
                        className="btn btn-outline-info button-create badge d-flex align-items-center fs-5 p-1 pe-1 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                        onClick={() => {
                          addTemplateToRequirement(
                            myProfessional.template_content
                          );
                        }}
                      >
                        <i className="fa-solid fa-file-import"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
        {/* this modal to use my templates for anwers  */}
        <Modal show={showRes} onHide={handleCloseRes} size="lg" centered>
          <Modal.Header closeButton>
            <div className="row">
              <div className="col-lg-4">
                <Modal.Title>Mis plantillas</Modal.Title>
              </div>
              <div className="col-lg-2">
                <button
                  type="button"
                  onClick={() => {
                    getTemplateResAll();
                  }}
                  className="btn btn-outline-info button-create badge d-flex align-items-center fs-6 p-2 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                >
                  <i className="fa-solid fa-globe"></i>&nbsp;Todas
                </button>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Form
                  role="search"
                  id="form-professional"
                  onSubmit={submitHandler}
                >
                  <InputGroup className="">
                    <Form.Control
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Buscar descripción"
                    ></Form.Control>
                  </InputGroup>
                </Form>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped table-sm rounded-table">
              <thead className="table-dark">
                <tr>
                  <th scope="col" style={{ width: "5%" }}>
                    Id
                  </th>
                  <th scope="col" style={{ width: "95%" }}>
                    Contenido
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {arrayTemplate.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <p>No hay resultados</p>
                    </td>
                  </tr>
                )}

                {arrayTemplate.map((myProfessional, count) => (
                  <tr key={count + 1}>
                    <td>
                      <p>{myProfessional.id}</p>
                    </td>
                    <td>
                      <p>{myProfessional.template_content}</p>
                    </td>
                    <td>
                      <Button
                        className="btn btn-outline-info button-create badge d-flex align-items-center fs-5 p-1 pe-1 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                        onClick={() => {
                          addTemplateToResponse(
                            myProfessional.template_content
                          );
                        }}
                      >
                        <i className="fa-solid fa-file-import"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
        {/* this modal to use my templates for anwers  */}
        <Modal
          show={showHistory}
          onHide={handleCloseHistory}
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <div className="row">
              <div className="col-lg-12">
                <Modal.Title>Historial de IPS</Modal.Title>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 align-content-center">
                <p>
                  Soportes encontrados:&nbsp;
                  <b className="fw-bold">{arraySupport?.length}</b>
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-3 align-content-center">
                <p>
                  Soportes totales:&nbsp;
                  <b className="fw-bold">{Total}</b>
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-3 align-content-center">
                <p>
                  Páginas totales:&nbsp;
                  <b className="fw-bold">{limit}</b>
                </p>
              </div>
            </div>
            <table className="table table-striped table-sm rounded-table">
              <thead className="table-dark">
                <tr>
                  <th scope="col" style={{ width: "10%" }}>
                    Fecha
                  </th>
                  <th scope="col" style={{ width: "15%" }}>
                    Cargo
                  </th>
                  <th scope="col" style={{ width: "35%" }}>
                    Requeriminto
                  </th>
                  <th scope="col" style={{ width: "35%" }}>
                    Respuesta
                  </th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {arraySupport.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <p>No hay resultados</p>
                    </td>
                  </tr>
                )}

                {arraySupport.map((mySupport, count) => (
                  <tr key={count + 1}>
                    <td>
                      {new Date(mySupport.support_date).toLocaleString(
                        "es-CO",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </td>
                    <td>{mySupport.role2}</td>
                    <td>
                      {" "}
                      {expandedRow === count
                        ? mySupport.requirement
                        : truncateText(mySupport.requirement, 50)}
                    </td>
                    <td>
                      {expandedRow === count
                        ? mySupport.answer
                        : truncateText(mySupport.answer, 50)}
                    </td>
                    <td>
                      <button
                        className="badge fs-6 py-1 pe-2 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                        type="button"
                        onClick={() => handleShowInfoSupport(mySupport)}
                      >
                        <i className="fas fa-eye"></i>
                        &nbsp;Ver
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-center">
              <nav className="inline-flex space-x-2.5 justify-content-center">
                <button
                  className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                  &nbsp;Anterior
                </button>
                &nbsp;
                <span className="fs-6">
                  Página {page} de {limit}
                </span>
                &nbsp;
                <button
                  className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                  onClick={handleNextPage}
                  disabled={page === limit}
                >
                  Siguiente&nbsp;
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
              </nav>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showInfo} onHide={handleCloseInfo} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Detalles del soporte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedSupport && (
              <div>
                <p>
                  <strong>Estado:</strong>&nbsp;
                  {selectedSupport.how_it_conclude || "Sin información"}
                </p>
                <p>
                  <strong>Solicitante:</strong>&nbsp;
                  {selectedSupport.name_solicited} - {selectedSupport.role2}
                </p>
                <p>
                  <strong>Finalizado por:</strong>&nbsp;
                  {selectedSupport.id_CustomerUser2__name}&nbsp;
                  {selectedSupport.id_CustomerUser2__last_name}
                </p>
                <p>
                  <strong>Teléfono:</strong>&nbsp;
                  {selectedSupport.number || "No tiene teléfono disponible"}
                </p>
                <p>
                  <strong>Tiempo de soporte:</strong>&nbsp;
                  {selectedSupport.time_support || 0}&nbsp;minutos.
                </p>
                <p>
                  <strong>Requerimiento:</strong>&nbsp;
                  {selectedSupport.requirement}
                </p>
                <strong>Respuesta:</strong>
                <br />
                <Form.Control
                  required
                  as="textarea"
                  name="requirement"
                  className="form-control"
                  id="requirement"
                  placeholder="Escriba o seleccione la descripción del soporte."
                  value={selectedSupport.answer}
                  aria-describedby="requirement"
                  autoComplete="off"
                  rows={5}
                />
              </div>
            )}
          </Modal.Body>
        </Modal>

        <ToastContainer />
      </div>
    </>
  );
};
