import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import IPS from "../../../models/Ips";
import {
  default as Subtype_of_request,
  default as Subtype_request,
} from "../../../models/sub_type_of_request";
import Typerequest from "../../../models/type_of_request";
import User from "../../../models/user";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { useForm } from "../../../utils/hooks/useForm";
import Template from "../../../models/Template";

export const CreateTemplate = () => {
  const navigate = useNavigate();
  const [process, setProcess] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [arrayUsers, setArrayUsers] = useState<User>();
  const [seleccion, setSeleccion] = useState<"si" | "no" | null>(null);

  const [arrayIps, setArrayips] = useState<IPS[]>([]);
  const [arraytyperequest, setArraytype_request] = useState<Typerequest[]>([]);
  const [arrayCustomerUser, setArrayCustomerUser] = useState<User[]>([]);

  type formaHtml = React.FormEvent<HTMLFormElement>;

  const [subtypes, setSubtypes] = useState<Subtype_request[]>([]);

  const [isDescription, setIsDescription] = useState(false);

  const today = new Date();
  const eightDaysAgo = new Date(today);
  eightDaysAgo.setDate(today.getDate() - 8);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const myNavigate = useNavigate();

  const handleTokenError = () => {
    myNavigate("/"); // Redirigir a la página de inicio de sesión
  };

  const handleSubmit = () => {
    // Lógica para manejar el submit
    console.log("Botón guardado!");
  };
  const MyComponent = () => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        // Simula el clic del botón
        handleSubmit();
      }
    };
  };

  const getProfessionals = async () => {
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      const url = ApiBack.USER_LIST_ONE + "/" + userId;
      const results = await ServicePrivate.requestGET(url);
      localStorage.setItem("user", results.num_document);
      if (results) {
        setArrayUsers(results);
        setLoading(false);
      }
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    } catch (error) {
      console.error("Error fetching professionals:", error);
      setLoading(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  // constants for create user

  let { id_type_support_id, template_content, doubleLink, object } =
    useForm<Template>(
      new Template(
        0,
        new Subtype_of_request(0, "", new Typerequest(0, "")),
        0,
        new User(0, "", "", "", "", false, "", "", ""),
        0,
        "",
        "",
        new Date(),
        new Date(),
        false,
        false
      )
    );

  const getAllUser = async () => {
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      console.log(userId);

      const url = ApiBack.USER_LIST_ONE + "/" + userId;
      const results = await ServicePrivate.requestGET(url);
      console.log(results);

      const customerUserId = results.id_CustomerUser?.id;

      if (customerUserId !== null) {
        localStorage.setItem("user", customerUserId.toString());
      }

      setLoading(false);

      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    } catch (error) {
      console.error("Error fetching professionals:", error);
      setLoading(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    }
  };

  const cleanBox = (form: HTMLFormElement) => {
    form.reset();

    object.id_type_support_id = 0;
    object.id_user_id = 0;
    object.template_name = "";
    object.template_content = "";

    // Limpiar cada campo de entrada directamente

    (
      form.querySelector('[name="template_content"]') as HTMLInputElement
    ).value = "";

    // Restablecer el estado de selección para la opción "Sí" y "No"
    setSeleccion(null); // o el valor inicial que desees

    form.classList.remove("was-validated");
  };

  const sendForm = async (fh: formaHtml) => {
    try {
      fh.preventDefault();
      setProcess(true);
      const form = fh.currentTarget;

      form.classList.add("was-validated");

      if (form.checkValidity() === false) {
        fh.preventDefault();
        fh.stopPropagation();
        setProcess(false);
        MessageToastify(
          "error",
          "Por favor, completa todos los campos requeridos.",
          7000
        );
        return;
      }
      const today = new Date();
      const eightDaysAgo = new Date(today);
      eightDaysAgo.setDate(today.getDate() - 8);

      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      console.log(userId);

      const formData = {
        id_type_support_id: object.id_type_support_id,
        id_user_id: userId,
        template_content: object.template_content,
        is_description: isDescription,
      };

      const response = await ServicePrivate.requestPOST(
        ApiBack.TEMPLATE_CREATE,
        formData
      );

      if (response.OK) {
        setProcess(false);
        MessageToastify(
          "error",
          "Error al momento de crear la plantilla, por favor verifique los datos.",
          7000
        );
      } else {
        setProcess(false);
        MessageToastify("success", "Se creo con éxito la plantilla.", 7000);
      }
    } catch (error) {
      setProcess(false);
      MessageToastify(
        "error",
        "Error en el momento de crear el soporte. verifique los datos.",
        7000
      );
    }
  };

  const getAllUsers = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.USER_VIEW);
      setArrayCustomerUser(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getAllIps = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ACTIVE);
      setArrayips(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info ips:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getAllTypesrequest = async () => {
    try {
      const results = await ServicePrivate.requestGET(
        ApiBack.TYPE_OF_REQUEST_lIST
      );
      setArraytype_request(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info type request:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getSubtypesByType = async (typerequestId: string) => {
    try {
      const results = await ServicePrivate.requestGET(
        `${ApiBack.SUBTYPE_OF_REQUEST_LIST_TYPE}/${typerequestId}`
      );
      setSubtypes(results);
      console.log(results);
    } catch (error) {
      console.error("Error fetching subtypes:", error);
    }
  };

  useEffect(() => {
    getAllIps();
    getAllUsers();
    getAllTypesrequest();
    getAllUser();
    MyComponent();
    getProfessionals();
  }, []);
  return (
    <>
      <div>
        <div className="page-header">
          <div className="row">
            <div className="col-ls-6 col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-chevron"></ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col-12 grid-margin stretch-card justify-content-center">
            <div className="card">
              <div className="card-body,padding-5">
                <h4
                  className="card-title"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  <i className="fa-solid fa-folder-plus"></i> &nbsp;Formulario
                  de creación para mis plantillas
                </h4>
                <Form
                  className="forms-sample"
                  validated={process}
                  onSubmit={sendForm}
                  noValidate
                  ref={formRef}
                >
                  <div className="row ms-lg-5 me-lg-5">
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-4 col-sm-8">
                          <Form.Group controlId="is_description">
                            <Form.Label
                              className="fw-bold mb-2 mt-2"
                              htmlFor="is_description"
                            >
                              Tipo de plantilla:
                            </Form.Label>
                            <Form.Select
                              required
                              id="is_description"
                              name="is_description"
                              value={isDescription.toString()} // Convertimos el booleano a string para que coincida con las opciones
                              onChange={(e) => {
                                setIsDescription(e.target.value === "true"); // Convertimos el valor de vuelta a booleano
                              }}
                              className="form-select"
                            >
                              <option value="true">Solicitud</option>
                              <option value="false">Respuesta</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Este campo es obligatorio.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          <Form.Group controlId="role_id">
                            <Form.Label
                              className="fw-bold mb-2 mt-2"
                              htmlFor="role_id"
                              style={{ color: "black" }} //
                            >
                              Tipo de requerimiento:
                            </Form.Label>
                            <Form.Select
                              required
                              id="id_type_request_id"
                              name="id_type_request_id"
                              onChange={async (e) => {
                                const selectedId = e.target.value;
                                doubleLink(e);
                                await getSubtypesByType(selectedId);
                              }}
                              className="form-select"
                            >
                              <option value="">
                                Seleccione el tipo de requerimiento.
                              </option>
                              {arraytyperequest.map((myRol) => (
                                <option
                                  value={myRol.id}
                                  key={myRol.typerequest}
                                >
                                  {myRol.typerequest}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione el requerimiento
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          <Form.Group controlId="id_type_support_id">
                            <Form.Label
                              className="fw-bold mb-2 mt-2"
                              htmlFor="id_type_support_id"
                            >
                              Sub-tipo de requerimiento:
                            </Form.Label>
                            <Form.Select
                              required
                              name="id_type_support_id"
                              value={id_type_support_id}
                              onChange={doubleLink}
                              className="form-select"
                            >
                              <option value="">Seleccione el sub-tipo</option>
                              {subtypes.map((myRol) => (
                                <option value={myRol.id} key={myRol.id}>
                                  {myRol.subtype_request}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione el sub-tipo
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-lg-8 col-sm-6">
                          <Form.Group controlId="first_name">
                            <Form.Label
                              className="fw-bold mb-2 mt-2"
                              htmlFor="template_content"
                            >
                              Contenido de la plantilla:
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="template_content"
                              className="form-control"
                              placeholder="Contenido de la plantilla."
                              value={template_content}
                              onChange={doubleLink}
                              autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">
                              El contenido de la plantilla es obligatorio.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-5 col-sm-5 mt-7  d-flex justify-content-center"
                      style={{
                        marginTop: "20px",
                        marginLeft: "30%",
                        padding: "10px",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-outline-info button-create badge fs-6 p-2 pe-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                        disabled={process}
                      >
                        {process ? (
                          <>
                            <i className="fa-solid fa-spinner fa-spin"></i>
                            &nbsp;Guardando...
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-plus"></i>&nbsp;Guardar
                          </>
                        )}
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="badge d-flex align-items-center fs-6 p-2 pe-2 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                      >
                        <i className="fa-solid fa-arrow-left-long"></i>
                        &nbsp;Regresar
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          if (formRef.current) {
                            cleanBox(formRef.current);
                          }
                        }}
                        className="badge d-flex align-items-center fs-6 p-2 pe-2 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-pill"
                      >
                        <i className="fa-solid fa-broom"></i>&nbsp;Limpiar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};
