import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ViewIps } from "../../view/private/ips/viewIps";
import { CreateUser } from "../../view/private/user/CreateUser";
import { ViewUser } from "../../view/private/user/ViewUser";
import { UpdateUser } from "../../view/private/user/UpdateUser";
import { ViewSupport } from "../../view/private/support/ViewSupport";
import { CreateSupport } from "../../view/private/support/CreateSupport";
import { ViewSupportFinal } from "../../view/private/support/ViewSupport_final";
import { RockyHeader } from "../../view/private/main/RockyHeader";
import { ViewSupportMY } from "../../view/private/support/VierwMySupports";
import { ViewCreateMuni } from "../../view/private/municipality/listCreate";
import { ViewTemplateAll } from "../../view/private/template/ViewTemplate";
import { CreateTemplate } from "../../view/private/template/CreateTemplate";
import { ViewMyTemplate } from "../../view/private/template/MyTemplate";
import { ViewSendSupport } from "../../view/private/support/ViewSendSupport";
import { LoginPrivate } from "../../view/private/auth/authprivate";
import { InternarGuard } from "../../segurity/InternalGuard";
import { AdminSupport } from "../../view/private/support/AdminSupport";

// const LazyLogin = lazy(() =>
//   import("../../view/auth/shared/Login").then(() => ({ default: Login }))
// );

// import { CREATE_USER } from "../../view/private/user/Create"

// const LazyProfessionalCreate =lazy(()=>import("../../view/private/user/Create").then(()=>({default:CreateProfessional})));
const LazyIPSView = lazy(() =>
  import("../../view/private/ips/viewIps").then(() => ({ default: ViewIps }))
);
const LazyUserCreate = lazy(() =>
  import("../../view/private/user/CreateUser").then(() => ({
    default: CreateUser,
  }))
);

const Lazymain = lazy(() =>
  import("../../view/private/main/RockyHeader").then(() => ({
    default: RockyHeader,
  }))
);

const LazyUserView = lazy(() =>
  import("../../view/private/user/ViewUser").then(() => ({
    default: ViewUser,
  }))
);
const LazyUserUpdate = lazy(() =>
  import("../../view/private/user/UpdateUser").then(() => ({
    default: UpdateUser,
  }))
);

const LazySupportView = lazy(() =>
  import("../../view/private/support/ViewSupport").then(() => ({
    default: ViewSupport,
  }))
);

const LazySupportViewFinal = lazy(() =>
  import("../../view/private/support/ViewSupport_final").then(() => ({
    default: ViewSupportFinal,
  }))
);

const LazySupportCreate = lazy(() =>
  import("../../view/private/support/CreateSupport").then(() => ({
    default: CreateSupport,
  }))
);

const LazyupportMySupport = lazy(() =>
  import("../../view/private/support/VierwMySupports").then(() => ({
    default: ViewSupportMY,
  }))
);
const LazyupportMyMunicipality = lazy(() =>
  import("../../view/private/municipality/listCreate").then(() => ({
    default: ViewCreateMuni,
  }))
);
const LazyViewTemplate = lazy(() =>
  import("../../view/private/template/ViewTemplate").then(() => ({
    default: ViewTemplateAll,
  }))
);
const LazyCreateTemplate = lazy(() =>
  import("../../view/private/template/CreateTemplate").then(() => ({
    default: CreateTemplate,
  }))
);
const LazyViewMyTemplate = lazy(() =>
  import("../../view/private/template/MyTemplate").then(() => ({
    default: ViewMyTemplate,
  }))
);
const LazyViewSendSupport = lazy(() =>
  import("../../view/private/support/ViewSendSupport").then(() => ({
    default: ViewSendSupport,
  }))
);
const LazyViewLoginPrivate = lazy(() =>
  import("../../view/private/auth/authprivate").then(() => ({
    default: LoginPrivate,
  }))
);
const LazyViewAdminSupport = lazy(() =>
  import("../../view/private/support/AdminSupport").then(() => ({
    default: AdminSupport,
  }))
);
export const InternalRouting = () => {
  return (
    <Routes>
      {/* <Route path="/professional-view" element={<LazyProfessionalView/>}/> */}
      <Route path="/ips-view" element={<LazyIPSView />} />
      <Route path="/main" element={<Lazymain />} />
      <Route path="/create-user" element={<LazyUserCreate />} />
      <Route path="/view-user" element={<LazyUserView />} />
      <Route path="/update-user/:id" element={<LazyUserUpdate />} />
      <Route path="/support-view" element={<LazySupportView />} />
      <Route path="/support-view-final" element={<LazySupportViewFinal />} />
      <Route path="/view-send-support" element={<LazyViewSendSupport />} />
      <Route path="/create-support" element={<LazySupportCreate />} />
      <Route path="/view-mysupport" element={<LazyupportMySupport />} />
      <Route
        path="/view-create-municipality"
        element={<LazyupportMyMunicipality />}
      />
      <Route path="/view-template" element={<LazyViewTemplate />} />
      <Route path="/create-template" element={<LazyCreateTemplate />} />
      <Route path="/view-my-template" element={<LazyViewMyTemplate />} />
      <Route path="/view-login-private" element={<LazyViewLoginPrivate />} />
      <Route element={<InternarGuard />}>
        <Route path="/view-dashbord" element={<LazyViewAdminSupport />} />
      </Route>

      <Route />
    </Routes>
  );
};
