import { useEffect, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { ToastContainer } from "react-toastify";
import Support from "../../../models/support";
import IPS from "../../../models/Ips";
import User from "../../../models/user";
import Municipio from "../../../models/municipio";

import Typerequest from "../../../models/type_of_request";
import Subtype_of_request from "../../../models/sub_type_of_request";
import { useForm } from "../../../utils/hooks/useForm";
import { jwtDecode } from "jwt-decode";
import Deparment from "../../../models/Deparment";

export const ViewSupport = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [process, setProcess] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [arraySupport, setArraySupport] = useState<Support[]>([]);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedIps, setSelectedIps] = useState<string>("");
  const [arrayIps, setArrayIps] = useState<IPS[]>([]);
  const [arrayTypeRequest, setArrayTypeRequest] = useState<Typerequest[]>([]);

  const [selectedType, setselectedType] = useState<string>("");

  const [arrayCustomerUser, setArrayCustomerUser] = useState<User[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [show, setShow] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showFinal, setShowFinal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = () => setShow(false);
  const handleCloseView = () => setShowView(false);
  const handleCloseFinal = () => setShowFinal(false);
  let userId: any;

  const ips: IPS = {
    is_active: true,
    nombre_ips: "",
    cod_ips: "",
    id: 0,
    municipio_id: new Municipio(0, "", "", new Deparment(0, "")),
    id_municipio_id: 0,
    date_contract: new Date(),
    have_contract: false,
  };
  const type_of_request: Typerequest = { typerequest: "", id: 0 };
  type formaHtml = React.FormEvent<HTMLFormElement>;
  const subtype_of_request: Subtype_of_request = {
    subtype_request: "",
    type_request_id: type_of_request,
    id: 0,
  };
  const user: User = {
    id: 0,
    name: "",
    email: "",
    password: "",
    last_name: "",
    num_document: "",
    is_active: true,
    document_type: "",
    role: "",
  };
  let {
    id_CustomerUser2_id,
    how_it_conclude,
    answer,
    time_support,
    doubleLink,
    object,
  } = useForm<Support>(
    new Support(
      0,
      new IPS(
        0,
        "",
        "",
        true,
        new Municipio(0, "", "", new Deparment(0, "")),
        0,
        new Date(),
        false
      ),
      0,
      new User(0, "", "", "", "", false, "", "", ""),
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      new User(0, "", "", "", "", false, "", "", ""),
      0,
      "",
      new Typerequest(0, ""),
      0,
      new Subtype_of_request(0, "", new Typerequest(0, "")),
      0,
      new Date(),
      new Date(),
      new Date(),
      "",
      0,
      "",
      ""
    )
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [objSupport, setObjProfessional] = useState<Support>(
    new Support(
      0,
      ips,
      0,
      user,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      true,
      false,
      user,
      0,
      "",
      type_of_request,
      0,
      subtype_of_request,
      0,
      new Date(),
      new Date(),
      new Date(),
      "",
      0,
      "",
      ""
    )
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [proccess, setProccess] = useState<boolean>();
  const [expandedRow] = useState(null);
  const [selectedSupport, setSelectedSupport] = useState<any>(null);

  const getOneSupport = async (id: number) => {
    try {
      const urlGetUser = ApiBack.SUPPORT_LIST_ONE + "/" + id;
      const result = await ServicePrivate.requestGET(urlGetUser);
      setObjProfessional(result);
      setShow(true);
    } catch (error) {
      console.error("Error fetching profesional:", error);
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const getOneSupportFinal = async (id: number) => {
    try {
      const urlGetUser = ApiBack.SUPPORT_LIST_ONE + "/" + id;
      const result = await ServicePrivate.requestGET(urlGetUser);
      setObjProfessional(result);
      setShowFinal(true);
    } catch (error) {
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const getAllUsers = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.USER_VIEW);
      setArrayCustomerUser(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getAllIps = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW);
      setArrayIps(results);
    } catch (error) {
      console.error("Error al obtener las IPS:", error);
      MessageToastify("error", "No se pudieron cargar las IPS.", 7000);
    }
  };
  const getAllTypesrequest = async () => {
    try {
      const results = await ServicePrivate.requestGET(
        ApiBack.TYPE_OF_REQUEST_lIST
      );
      setArrayTypeRequest(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleTokenError = () => {
    navigate("/"); // Go to login session
  };

  const sendUpdatedId = async (supportId: number, newId: number) => {
    setProccess(true);
    try {
      // Encuentra el soporte en el array
      const updatedProfessional = arraySupport.find(
        (prof) => prof.id === supportId
      );

      if (!updatedProfessional) {
        MessageToastify(
          "error",
          "Profesional no encontrado en la base de datos.",
          7000
        );
        return;
      }

      // Crear el objeto con el id que deseas actualizar
      const updatedObject = {
        how_it_conclude: object.how_it_conclude,
        id_CustomerUser2_id: newId,
        answer: object.answer,
        time_support: object.time_support,
      };

      const urlUpdate = ApiBack.SUPPORT_UPDATE_USER + "/" + supportId;
      console.log("URL de actualización:", urlUpdate);

      const response = await ServicePrivate.requestPUT(
        urlUpdate,
        updatedObject
      );

      if (response?.status === 200) {
        console.error("Error al actualizar el ID:", response?.data);
        MessageToastify(
          "success",
          "Error al momento de enviar el soporte al nuevo usuario encargado.",
          7000
        );
      } else {
        const selectedUser = arrayCustomerUser.find(
          (user) => user.id === Number(newId)
        );

        const name = selectedUser
          ? selectedUser.name + " " + selectedUser.last_name
          : null;

        const message = `El soporte se ha asignado a: ${name}`;
        MessageToastify("success", message, 7000);
        getAllUsers();
        setShow(false);
        getSupport();
      }
    } catch (error) {
      console.error("Error actualizando el ID del usuario:", error);
      MessageToastify("error", "Error al escalar el soporte.", 7000);
    } finally {
      setProccess(false);
    }
  };

  const updateSupportDetails = async (
    supportId: number,
    howItConclude: string,
    answer: string
  ) => {
    setProccess(true);
    try {
      const myProfessional: any = localStorage.getItem("access");
      if (!myProfessional) throw new Error("No access token found");
      const decodedToken: any = jwtDecode(myProfessional);
      userId = decodedToken.user_id;
      // Encuentra el soporte por ID en el array
      const updatedSupport = arraySupport.find(
        (support) => support.id === supportId
      );

      if (!updatedSupport) {
        console.error("Soporte no encontrado");
        return;
      }

      if (how_it_conclude === "") {
        MessageToastify("error", "Debe seleccionar una opción.", 2000);
        return;
      }

      const updatedObject = {
        how_it_conclude: howItConclude,
        answer: answer,
        is_active: false,
        id_CustomerUser2_id: userId,
        time_support: object.time_support,
      };

      const urlUpdate = ApiBack.SUPPORT_UPDATE_DETAILS + "/" + supportId;
      const response = await ServicePrivate.requestPUT(
        urlUpdate,
        updatedObject
      );

      if (response.OK) {
        MessageToastify(
          "error",
          "Error al momento de finalizar el soporte, datos incorrectos o verifique el soporte.",
          7000
        );
        getSupport();
      } else {
        if (howItConclude === "EXISTOSO") {
          MessageToastify(
            "success",
            "El soporte ha finalizado exitosamente, ya puede continuar con el siguiente.",
            7000
          );
        }
        if (howItConclude === "EN_PROGRESO") {
          MessageToastify("success", "El soporte tiene pendientes.", 7000);
        }
        setShowFinal(false);
        getSupport();
      }
    } catch (error) {
      console.error(
        "Error actualizando los datos del soporte por parte del servidor, por favor comuniquese con TI:",
        error
      );
      MessageToastify(
        "error",
        "Error actualizando los datos del soporte por parte del servidor, por favor comuniquese con TI.",
        7000
      );
      setShow(false);
    } finally {
      setProccess(false);
      setShow(false);
    }
  };

  const getProfessionals = async () => {
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;

      const url = ApiBack.USER_LIST_ONE_ROLE + "/" + userId;
      const results = await ServicePrivate.requestGET(url);

      if (results.role === "ADMINISTRADOR") {
        setIsAdmin(true); // Solo establece isAdmin si el usuario es ADMINISTRADOR
      }
    } catch (error) {
      console.error("Error fetching professionals:", error);
      setLoading(false);
    }
  };

  const sendForm = async (fh: formaHtml) => {
    try {
      fh.preventDefault();
      setProcess(true);
      const form = fh.currentTarget;

      form.classList.add("was-validated");

      if (form.checkValidity() === false) {
        fh.preventDefault();
        fh.stopPropagation();
        setProcess(false);
        MessageToastify(
          "error",
          "Por favor, completa todos los campos requeridos.",
          7000
        );
        return;
      }
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      console.log(userId);

      // Crear el objeto para enviar con los campos proporcionados
      const formData = {
        id_ips_id: object.id_ips_id,
        id_CustomerUser_id: userId,
        name_solicited: object.name_solicited,
        number: object.number,
        email: object.email.toLowerCase(), // Convertir email a minúsculas
        requirement: object.requirement,
        answer: object.answer,
        // is_active: isActive, // Variable booleana is_active
        // is_close: isClose, // Variable booleana is_close
        id_CustomerUser2_id: object.id_CustomerUser2_id,
        how_it_conclude: object.how_it_conclude,
        id_type_request_id: object.id_type_request_id,
        id_Subtype_request_id: object.id_Subtype_request_id,
        support_date: object.support_date,
        create_date: object.create_date,
        update_date: object.update_date,
      };

      // Realizar la solicitud POST
      const response = await ServicePrivate.requestPOST(
        ApiBack.SUPPORT_CREATE,
        formData
      );

      if (response.OK) {
        setProcess(false);
        MessageToastify("error", "error al momento de crear el soporte.", 7000);
      } else {
        setProcess(false);
        MessageToastify("success", "Soporte creado", 7000);
        navigate("/home/support-view");
      }
    } catch (error) {
      setProcess(false);
      MessageToastify("error", "Error en el momento  crear el soporte.", 7000);
    }
  };

  const getSupport = async () => {
    setLoading(true);
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      // Construye la URL con el filtro de tipo de requerimiento
      const url = `${
        ApiBack.SUPPORT_VIEW
      }?user=${userId}&page=${page}&limit=${limit}${
        selectedIps ? `&ips=${selectedIps}` : ""
      }${selectedType ? `&typerequest_id=${selectedType}` : ""}`;

      // Realiza la solicitud GET a la API
      const response = await ServicePrivate.requestGET(url);

      if (response.results) {
        let filteredResults = response.results;

        // Filtro por tipo de requerimiento (si existe un filtro seleccionado)
        if (
          objSupport.id_type_request_id &&
          objSupport.id_type_request_id !== 0
        ) {
          filteredResults = filteredResults.filter(
            (support: Support) =>
              support.id_type_request_id === objSupport.id_type_request_id
          );
        }

        // Actualiza el estado con los resultados filtrados
        setArraySupport(filteredResults);
        setTotalItems(response.total_count);
        setTotalPages(response.total_pages);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError(); // Maneja el error de token si es necesario
    } finally {
      setLoading(false);
    }
  };

  const handleShowView = (support: any) => {
    setSelectedSupport(support);
    setShowView(true);
  };

  const truncateText = (text: string, maxLength: any) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  useEffect(() => {
    getSupport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleIpsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedIps(event.target.value);
    setPage(1);
  };
  const handleTyoeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setselectedType(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    getSupport();
    getAllUsers();
    getAllTypesrequest();
    getProfessionals();
    getAllIps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
            <h3 style={{ fontWeight: "bold" }}>Mis soportes pendientes</h3>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-6 d-flex align-items-center">
            <Form.Group className=" flex-grow-1" controlId="formIps">
              <Form.Label>Seleccionar IPS</Form.Label>
              <Form.Select value={selectedIps} onChange={handleIpsChange}>
                <option value="">Todas las IPS</option>
                {arrayIps.map((ips) => (
                  <option key={ips.id} value={ips.id}>
                    {ips?.municipio_id?.nombre_municipio} - {ips.nombre_ips} (
                    {ips.cod_ips})
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <div className="col-lg-5 col-md-6">
              <Form.Group className="" controlId="formIps">
                <Form.Label>Seleccionar tipo de solicitud</Form.Label>
                <Form.Select value={selectedType} onChange={handleTyoeChange}>
                  <option value="">Todas las solicitudes</option>
                  {arrayTypeRequest.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.typerequest}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <Button
              type="submit"
              className="badge fs-6 p-2 pe-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
              variant="primary"
              onClick={getSupport}
              style={{
                marginRight: "10px",
                marginTop: "18px",
                marginLeft: "10px",
              }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>&nbsp;Buscar
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="page-header">
            <div className="col-lg-6 col-sm-12"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div
                  className="pagination-info"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="boldText">
                    Total de mis soportes en esta página:&nbsp;
                    <b className="fw-bold fs-5">{arraySupport.length}</b>
                  </p>
                  <p className="boldText">
                    Total de mis soportes:&nbsp;
                    <b className="fw-bold fs-5">{totalItems}</b>
                  </p>
                  <p className="boldText">
                    Total de páginas:&nbsp;
                    <b className="fw-bold fs-5">{totalPages}</b>
                  </p>
                </div>
                <div style={{ overflowX: "hidden" }}>
                  <table className="table table-striped table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col" style={{ width: "5%" }}>
                          Id
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          IPS
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          Solicitante del soporte
                        </th>
                        <th scope="col" style={{ width: "35%" }}>
                          Descripción
                        </th>
                        <th scope="col" style={{ width: "35%" }}>
                          Respuesta
                        </th>
                        <th scope="col" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody className="table-group-divider">
                      {loading ? (
                        <>
                          {" "}
                          <tr>
                            <td colSpan={12} className="text-center">
                              <div className="d-flex flex-column align-items-center">
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    color: "#000",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Cargando...
                                  </span>
                                </div>
                                <p
                                  className="mt-3 fs-5"
                                  style={{ color: "#000" }}
                                >
                                  Cargando datos, por favor espere...
                                </p>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {arraySupport.length === 0 && (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <div className="d-flex flex-column align-items-center">
                                  <p
                                    className="mt-3 fs-5"
                                    style={{ color: "#000" }}
                                  >
                                    No tiene soportes en el momento de buscar.
                                    Por favor, ingrese o verifique el filtro
                                    para la búsqueda.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                          {arraySupport.length > 0 &&
                            arraySupport.map((support, count) => (
                              <tr key={support.id}>
                                <td>{support.id}</td>
                                <td>
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={support.id_ips.nombre_ips}
                                  >
                                    {
                                      support.id_ips.municipio_id
                                        .nombre_municipio
                                    }
                                  </span>
                                </td>
                                <td>{support.name_solicited}</td>

                                <td>
                                  {expandedRow === count
                                    ? support.requirement
                                    : truncateText(support.requirement, 50)}
                                </td>
                                <td>
                                  {expandedRow === count
                                    ? support.answer
                                    : truncateText(support.answer, 50)}
                                </td>
                                <td>
                                  <button
                                    className="badge fs-6 py-1 pe-2 text-info-emphasis bg-info-subtle border border-info-subtle rounded-pill"
                                    type="button"
                                    onClick={() => handleShowView(support)}
                                  >
                                    <i className="fas fa-eye"></i>
                                    &nbsp;Ver
                                  </button>

                                  <button
                                    className={
                                      support.id_CustomerUser2
                                        ? "badge fs-6 py-1 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                                        : "badge fs-6 py-1 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                                    }
                                    style={{ marginLeft: "10px" }}
                                    type="button"
                                    onClick={() => getOneSupport(support.id)}
                                  >
                                    <i className="fa-solid fa-share-nodes"></i>
                                    &nbsp;
                                    {support.id_CustomerUser2
                                      ? "Enviar"
                                      : "Activar"}
                                  </button>
                                  <button
                                    className={
                                      support.answer || support.how_it_conclude
                                        ? "badge fs-6 py-1 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                                        : "badge fs-6 py-1 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                                    }
                                    type="button"
                                    onClick={() =>
                                      getOneSupportFinal(support.id)
                                    }
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i className="fa-solid fa-flag-checkered"></i>
                                    &nbsp;Finalizar
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex align-items-center justify-content-center m-3">
                  <nav className="d-flex align-items-center justify-content-center">
                    <button
                      className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                      onClick={handlePreviousPage}
                      disabled={page === 1}
                    >
                      <i className="fa-solid fa-arrow-left"></i>&nbsp;Anterior
                    </button>
                    <span className="fs-6">
                      &nbsp;Página {page} de {totalPages}&nbsp;
                    </span>
                    <button
                      className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                      onClick={handleNextPage}
                      disabled={page === limit}
                    >
                      Siguiente&nbsp;
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
            {/* Modal to send  */}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center fw-bold">
                  <i className="fa-regular fa-share-from-square"></i>
                  &nbsp;Cambiar encargado
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Encargado actual:&nbsp;
                <strong>
                  {objSupport.id_CustomerUser2.name}&nbsp;
                  {objSupport.id_CustomerUser2.last_name}&nbsp;-&nbsp;
                  {objSupport.id_CustomerUser2.role}
                </strong>
                <br />
                <div className="col-lg-12">
                  <Form
                    className="forms-sample"
                    validated={process}
                    onSubmit={sendForm}
                    noValidate
                    ref={formRef}
                  >
                    <Form.Group controlId="id_CustomerUser2_id">
                      <Form.Label
                        className="fw-bold mb-2 mt-2"
                        htmlFor="id_CustomerUser2_id"
                      >
                        Nuevo encargado:
                      </Form.Label>
                      <Form.Select
                        required
                        id="id_CustomerUser2_id"
                        name="id_CustomerUser2_id"
                        value={id_CustomerUser2_id}
                        onChange={doubleLink}
                        className="form-select"
                      >
                        <option value="">Seleccione al nuevo encargado</option>
                        {arrayCustomerUser.map((user) => (
                          <option value={user.id} key={user.id}>
                            {`${user.role} - ${user.name}`}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Seleccione al nuevo encargado.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Label className="fw-bold mb-2 mt-2">
                      Historial
                      <br />
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      name="requirement"
                      className="form-control"
                      id="requirement"
                      placeholder="Escriba o seleccione la descripción del soporte."
                      value={objSupport.answer}
                      aria-describedby="requirement"
                      autoComplete="off"
                      rows={5}
                    />
                    Tiempo empleado:&nbsp;
                    <strong>
                      {objSupport.time_support || 0}&nbsp;minutos.
                    </strong>
                  </Form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="danger"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <i className="fa-solid fa-backward"></i>
                  &nbsp;Cancelar
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    sendUpdatedId(objSupport.id, id_CustomerUser2_id);
                  }}
                >
                  {objSupport.id_CustomerUser2_id ? (
                    <>
                      <i className="fas fa-exchange-alt"></i>
                      &nbsp;Cambiar
                    </>
                  ) : (
                    <>Confirmar</>
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Modal to fanally  */}
            <Modal
              show={showFinal}
              onHide={handleCloseFinal}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center fw-bold">
                  <i className="fa-solid fa-hourglass-end"></i>&nbsp;Finalizar
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="col-lg-12">
                  <Form
                    className="forms-sample"
                    validated={process}
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateSupportDetails(
                        objSupport.id,
                        how_it_conclude,
                        answer
                      );
                    }}
                    noValidate
                    ref={formRef}
                  >
                    <Form.Group controlId="how_it_conclude">
                      <Form.Label className="fw-bold mb-2 mt-2">
                        Como concluye:
                      </Form.Label>
                      <Form.Select
                        required
                        name="how_it_conclude"
                        className="form-select border-1 border-yellow-500"
                        id="how_it_conclude"
                        value={how_it_conclude}
                        onChange={doubleLink}
                      >
                        <option value="">Selecciona una opción</option>
                        <option value="EXISTOSO">EXITOSO</option>
                        <option value="EN_PROGRESO">EN PROGRESO</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Como concluye es obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Label className="fw-bold">
                      Historial:
                      <br />
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      name="requirement"
                      className="form-control"
                      id="requirement"
                      placeholder="Escriba o seleccione la descripción del soporte."
                      value={objSupport.answer}
                      aria-describedby="requirement"
                      autoComplete="off"
                      rows={5}
                    />
                    Tiempo empleado:&nbsp;
                    <strong>
                      {objSupport.time_support || 0}&nbsp;minutos.
                    </strong>
                    <Form.Group controlId="time_support">
                      <Form.Label className="fw-bold mb-2 mt-2">
                        Tiempo:
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="time_support"
                        className="form-control"
                        id="time_support"
                        value={time_support}
                        placeholder="Tiempo en minutos."
                        onChange={doubleLink}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        El tiempo es obligatorio para finalizar el soporte es
                        obligatorio.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="answer">
                      <Form.Label className="fw-bold mb-2 mt-2">
                        Respuesta:
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="answer"
                        className="form-control border-1 border-yellow-500"
                        id="answer"
                        value={answer}
                        onChange={doubleLink}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        Respuesta:
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="danger"
                  onClick={() => {
                    setShowFinal(false);
                  }}
                >
                  <i className="fa-solid fa-backward"></i>
                  &nbsp;Cancelar
                </Button>
                <Button
                  variant="success"
                  onClick={(e) => {
                    e.preventDefault();
                    updateSupportDetails(
                      objSupport.id,
                      how_it_conclude,
                      answer
                    );
                  }}
                >
                  <i className="fa-regular fa-circle-check"></i>&nbsp;Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Modal to description */}
            <Modal show={showView} onHide={handleCloseView} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>Detalles del soporte</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedSupport && (
                  <div>
                    <p>
                      <strong>Solicitante:</strong>&nbsp;
                      {selectedSupport.name_solicited}
                    </p>
                    <p>
                      <strong>Teléfono:</strong> {selectedSupport.number}
                    </p>
                    <p>
                      <strong>Requerimiento:</strong>&nbsp;
                      {selectedSupport.requirement}
                    </p>
                    <p>
                      <strong>Tiempo de soporte:</strong>&nbsp;
                      {selectedSupport.time_support || 0} minutos.
                    </p>
                    <p>
                      <strong>Conclusión:</strong>&nbsp;
                      {selectedSupport.how_it_conclude || "Sin información"}
                    </p>
                    <p>
                      <strong>Respuesta:</strong>
                      <Form.Control
                        required
                        as="textarea"
                        name="requirement"
                        className="form-control"
                        id="requirement"
                        placeholder="Escriba o seleccione la descripción del soporte."
                        value={selectedSupport.answer}
                        aria-describedby="requirement"
                        autoComplete="off"
                        rows={5}
                      />
                    </p>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleCloseView}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
