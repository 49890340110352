class SupportViewFinally {
  public id: number;
  public id_ips__nombre_ips: string;
  public id_ips_id: number;
  public id_CustomerUser_id: number;
  public role2: string;
  public medio: string;
  public name_solicited: string;
  public number: string;
  public email: string;
  public requirement: string;
  public answer: string;
  public is_active: boolean;
  public is_close: boolean;
  public id_CustomerUser2_id: number;
  public how_it_conclude: string;
  public typerequest_id__typerequest: string;
  public id_Subtype_request_id: number;
  public support_date: Date;
  public create_date: Date;
  public update_date: Date;
  public observation: string;
  public time_support: number;
  public id_ips__municipio_id__nombre_municipio: string;
  public id_CustomerUser2__name: string;
  public id_CustomerUser2__last_name: string;
  public id_CustomerUser2__document_type: string;
  public id_CustomerUser2__num_document: string;
  public subtype_request__subtype_request: string;
  public total_supports: string;
  public total_time_minutes: string;

   constructor(
    id: number,
    id_ips_id: number,
    id_CustomerUser_id: number,
    role2: string,
    medio: string,
    name_solicited: string,
    number: string,
    email: string, 
    requirement: string, 
    answer: string, 
    is_active: boolean, 
    is_close: boolean,
    id_CustomerUser2_id: number,
    how_it_conclude: string,
    typerequest_id__typerequest: string,
    id_Subtype_request_id: number,
    support_date: Date,
    create_date: Date,
    update_date: Date,
    observation: string,
    time_support: number,
    id_ips__nombre_ips: string,
    id_ips__municipio_id__nombre_municipio: string,
    id_CustomerUser2__name: string,
    id_CustomerUser2__last_name: string,
    id_CustomerUser2__document_type: string,
    id_CustomerUser2__num_document: string,
    subtype_request__Subtype_request: string,
    total_supports: string,
    total_time_minutes: string,
  ) {
    this.id = id;
    this.id_ips_id = id_ips_id;
    this.id_CustomerUser_id = id_CustomerUser_id;
    this.role2 = role2;
    this.medio = medio;
    this.name_solicited = name_solicited;
    this.number = number;
    this.email = email;
    this.requirement = requirement;
    this.answer = answer;
    this.is_active = is_active;
    this.is_close = is_close;
    this.id_CustomerUser2_id = id_CustomerUser2_id;
    this.how_it_conclude = how_it_conclude;
    this.typerequest_id__typerequest = typerequest_id__typerequest;
    this.id_Subtype_request_id = id_Subtype_request_id;
    this.support_date = support_date;
    this.update_date = update_date;
    this.create_date = create_date;
    this.observation = observation;
    this.time_support = time_support;
    this.id_ips__nombre_ips = id_ips__nombre_ips;
    this.id_ips__municipio_id__nombre_municipio = id_ips__municipio_id__nombre_municipio; 
    this.id_CustomerUser2__name = id_CustomerUser2__name;
    this.id_CustomerUser2__last_name = id_CustomerUser2__last_name;
    this.id_CustomerUser2__document_type = id_CustomerUser2__document_type;
    this.id_CustomerUser2__num_document = id_CustomerUser2__num_document;
    this.subtype_request__subtype_request = subtype_request__Subtype_request;
    this.total_supports = total_supports;
    this.total_time_minutes = total_time_minutes;

  }
}
export default SupportViewFinally;
