import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import imgDash from "../assets/images/Rocky/LOGOROCKYPeque.png";
import ApiBack from "../utils/domains/ApiBack";
import { jwtDecode } from "jwt-decode";
import User from "../models/user";
import ServicePrivate from "../services/ServicePrivate";

export const Sidebar = ({
  isOpen,
  toggleSidebar,
}: {
  isOpen: boolean;
  toggleSidebar: () => void;
}) => {
  const location = useLocation();
  //Variables to all tsx
  const myNavigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isSupportMenuOpen, setIsSupportMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isIpsMenuOpen, setIsIpsMenuOpen] = useState(false);
  // const [isMunicipiosMenuOpen, setIsMunicipiosMenuOpen] = useState(false);
  const [isTemplateMenuOpen, setIsTemplateMenuOpen] = useState(false);
  const [arrayUsers, setArrayUsers] = useState<User>();

  const toggleSupportMenu = () => {
    setIsSupportMenuOpen(!isSupportMenuOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const toggleIpsMenu = () => {
    setIsIpsMenuOpen(!isIpsMenuOpen);
  };

  const toggleTempalteMenu = () => {
    setIsTemplateMenuOpen(!isTemplateMenuOpen);
  };
  const onRouteChanged = () => {
    const sidebar = document.querySelector("#sidebar");
    if (sidebar) {
      sidebar.classList.remove("active");
    }

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      // if (isPathActive(obj.path)) {
      //   setMenuState((prevState) => ({ ...prevState, [obj.state]: true }));
      // }
    });
  };

  // const [isOpen, setIsOpen] = useState(true); // Estado para controlar si se muestra el sidebar

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen); // Cambia el estado al hacer clic
  // };

  // const isPathActive = (path: string): boolean => {
  //   return location.pathname.startsWith(path);
  // };
  //function to error the Token on the time, using when token is invalid or expired
  const handleTokenError = () => {
    myNavigate("/"); // Redirigir a la página de inicio de sesión
  };

  const getProfessionals = async () => {
    try {
      const myAccess: any = localStorage.getItem("access");
      const decodedToken: any = jwtDecode(myAccess);
      const userId = decodedToken.user_id;
      const url = ApiBack.USER_LIST_ONE + "/" + userId;
      const results = await ServicePrivate.requestGET(url);

      localStorage.setItem("user", results.num_document);
      if (results) {
        setArrayUsers(results);
        setLoading(false);
      }
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    } catch (error) {
      console.error("Error fetching professionals:", error);
      setLoading(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    }
  };

  const isActive = (path: any) => location.pathname === path;

  useEffect(() => {
    onRouteChanged();
    getProfessionals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <button
        onClick={toggleSidebar}
        style={{
          position: "fixed",
          top: "20px",
          left: isOpen ? "140px" : "10px", // Se mueve dependiendo del estado
          zIndex: 1000,
          backgroundColor:  isOpen ? "#dcc05d" : "#0f0f6f",
          color:  isOpen ?  "#0f0f6f":"#ffffff" ,
          border: "none",
          padding: "5px",
          cursor: "pointer",
          borderRadius: "50%",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        {isOpen ? <i className="fa-regular fa-eye-slash"></i>:<i className="fa-regular fa-eye"></i>}
      </button>
      <nav
        className={`sidebar sidebar-offcanvas position-fixed ${
          isOpen ? "d-block" : "d-none"
        }`}
        id="sidebar"
        style={{
          background: "linear-gradient(to right, #0F0F6F 86%, #EBEFF3 101%)",
          paddingLeft: "0!important",
          marginLeft: "0!important",
          borderRight: "1px solid #ccc",
          height: "100vh",
        }}
        >
        <a href="/home/main">
          <img
            src={imgDash}
            alt="Logo Rocky"
            style={{
              width: "30px",
              height: "auto",
              marginLeft: "90px",
              marginTop: "20px",
              paddingBottom: "20px",
            }}
          />
        </a>
        <ul
          className="nav"
          style={{
            overflowY: "auto",
            margin: "0",
            background: "transparent",
          }}
        >
          <li className="nav-item navbar-brand-mini-wrapper">
            <Link className="nav-link navbar-brand brand-logo-mini" to="/home">
              {/* <img src={imgDash} alt="logo" /> */}
            </Link>
          </li>

          <li style={{ margin: "0 !important" }} className="nav-item">
            <span
              className="nav-link fs-5 fw-bold text-white"
              onClick={toggleSupportMenu}
              style={{
                cursor: "pointer",
                paddingLeft: "15px",
                marginLeft: "0 !important",
                color: "white",
                backgroundColor: "transparent !important",
              }}
            >
              Soporte
            </span>

            {/* Menú desplegable */}
            {isSupportMenuOpen && (
              <ul
                className="nav flex-column ms-3"
                style={{
                  paddingLeft: "0",
                  background: "transparent",
                }}
              >
                <li className="nav-item">
                  <Link
                    className="nav-link fw-bold"
                    to="/home/create-support"
                    style={{
                      padding: isActive("/home/create-support")
                        ? "10px 15px"
                        : "",
                      color: isActive("/home/create-support") ? "#dcc05d" : "",
                      backgroundColor: isActive("/home/create-support")
                        ? "#dcc05d !important"
                        : "",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    Nuevo
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link fw-bold"
                    to="/home/support-view"
                    style={{
                      padding: isActive("/home/support-view")
                        ? "10px 15px"
                        : "",
                      color: isActive("/home/support-view") ? "#dcc05d" : "",
                      backgroundColor: isActive("/home/support-view")
                        ? "#dcc05d !important"
                        : "",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    Mis pendientes
                  </Link>
                </li>
                <li className="nav-item" style={{ color: "white" }}>
                  <Link
                    className="nav-link fw-bold"
                    to="/home/view-mysupport"
                    style={{
                      padding: isActive("/home/view-mysupport")
                        ? "10px 15px"
                        : "",
                      color: isActive("/home/view-mysupport") ? "#dcc05d" : "",
                      backgroundColor: isActive("/home/view-mysupport")
                        ? "#dcc05d !important"
                        : "",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    Mis soportes
                  </Link>
                </li>
                <li className="nav-item" style={{ color: "white" }}>
                  <Link
                    className="nav-link fw-bold"
                    to="/home/view-send-support"
                    style={{
                      padding: isActive("/home/view-send-support")
                        ? "10px 15px"
                        : "",
                      color: isActive("/home/view-send-support")
                        ? "#dcc05d"
                        : "",
                      backgroundColor: isActive("/home/view-send-support")
                        ? "#dcc05d !important"
                        : "",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    Escalados
                  </Link>
                </li>
                {arrayUsers?.role === "ADMINISTRADOR" ? (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/support-view-final"
                        style={{
                          padding: isActive("/home/support-view-final")
                            ? "10px 15px"
                            : "",
                          color: isActive("/home/support-view-final")
                            ? "#dcc05d"
                            : "",
                          backgroundColor: isActive("/home/support-view-final")
                            ? "#dcc05d !important"
                            : "",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        Estadísticas
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/view-login-private"
                        style={{
                          padding: isActive("/home/view-login-private")
                            ? "10px 15px"
                            : "",
                          color: isActive("/home/view-login-private")
                            ? "#dcc05d"
                            : "",
                          backgroundColor: isActive("/home/view-login-private")
                            ? "#dcc05d !important"
                            : "",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        Eliminación
                      </Link>
                    </li> */}
                  </>
                ) : (
                  ""
                )}
              </ul>
            )}
          </li>

          <li style={{ color: "black" }} className="nav-item">
            <span
              className="nav-link fs-5 fw-bold text-white"
              onClick={toggleTempalteMenu}
              style={{
                cursor: "pointer",
                padding: "10px 15px",
                color: "white !important",
                backgroundColor: "transparent !important",
              }}
            >
              Plantillas
            </span>

            {/* Menú desplegable de Usuarios */}
            {isTemplateMenuOpen && (
              <ul
                className="nav flex-column ms-3"
                style={{ background: "transparent" }}
              >
                <li className="nav-item">
                  <Link
                    className="nav-link fw-bold"
                    to="/home/create-template"
                    style={{
                      padding: isActive("/home/create-template")
                        ? "10px 15px"
                        : "",
                      color: isActive("/home/create-template") ? "#dcc05d" : "",
                      backgroundColor: isActive("/home/create-template")
                        ? "#dcc05d !important"
                        : "",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    Nueva
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link fw-bold"
                    to="/home/view-my-template"
                    style={{
                      padding: isActive("/home/view-my-template")
                        ? "10px 15px"
                        : "",
                      color: isActive("/home/view-my-template") ? "#dcc05d" : "",
                      backgroundColor: isActive("/home/view-my-template")
                        ? "#dcc05d !important"
                        : "",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    Mis plantillas
                  </Link>
                </li>
                {arrayUsers?.role === "ADMINISTRADOR" ? (
                  <>
                    <li className="nav-item ">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/view-template"
                        style={{
                          padding: isActive("/home/view-template")
                            ? "10px 15px"
                            : "",
                          color: isActive("/home/view-template")
                            ? "#dcc05d"
                            : "",
                          backgroundColor: isActive("/home/view-template")
                            ? "#dcc05d !important"
                            : "",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        Todas las plantillas
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            )}
          </li>

          {arrayUsers?.role === "ADMINISTRADOR" ? (
            <>
              <li style={{ color: "black" }} className="nav-item">
                <span
                  className="nav-link fs-5 fw-bold text-white"
                  onClick={toggleUserMenu}
                  style={{
                    cursor: "pointer",
                    padding: "10px 15px",
                    color: "white !important",
                    backgroundColor: "transparent !important",
                  }}
                >
                  Usuarios
                </span>

                {/* Menú desplegable de Usuarios */}
                {isUserMenuOpen && (
                  <ul
                    className="nav flex-column ms-3"
                    style={{ background: "transparent" }}
                  >
                    <li className="nav-item">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/create-user"
                        style={{
                          padding: isActive("/home/create-user")
                            ? "10px 15px"
                            : "",
                          color: isActive("/home/create-user") ? "#dcc05d" : "",
                          backgroundColor: isActive("/home/create-user")
                            ? "#dcc05d !important"
                            : "",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        Creación
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/view-user"
                        style={{
                          padding: isActive("/home/view-user")
                            ? "10px 15px"
                            : "",
                          color: isActive("/home/view-user") ? "#dcc05d" : "",
                          backgroundColor: isActive("/home/view-user")
                            ? "#dcc05d !important"
                            : "",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        Usuarios
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              {/* 
              <li className="nav-item">
                <span
                  className="nav-link fs-5 fw-bold text-white"
                  onClick={toggleMunicipiosMenu}
                  style={{
                    cursor: "pointer",
                    padding: "10px 15px",
                    color: "white !important",
                    backgroundColor: "transparent !important",
                  }}
                >
                  Municipio
                </span>

                {/* Menú desplegable de IPS 
                {isMunicipiosMenuOpen && (
                  <ul
                    className="nav flex-column ms-3"
                    style={{ background: "transparent" }}
                  >
                    <li className="nav-item">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/view-create-municipality"
                        style={{
                          padding: isActive("/home/view-create-municipality")?"10px 15px":"",
                          color: isActive("/home/view-create-municipality") ? "#dcc05d" : "",
                          backgroundColor: isActive("/home/view-create-municipality") ? "#dcc05d !important" : "",
                          display: "flex",
                          justifyContent:"left",
                          alignItems: "center",
                        }}
                      >
                        Municipios
                      </Link>
                    </li>
                  </ul>
                )}
              </li> */}
              <li className="nav-item">
                <span
                  className="nav-link fs-5 fw-bold text-white"
                  onClick={toggleIpsMenu}
                  style={{
                    cursor: "pointer",
                    padding: "10px 15px",
                    color: "white !important",
                    backgroundColor: "transparent !important",
                  }}
                >
                  IPS
                </span>

                {/* Menú desplegable de IPS */}

                {isIpsMenuOpen && (
                  <ul
                    className="nav flex-column ms-3"
                    style={{ background: "transparent" }}
                  >
                    <li className="nav-item">
                      <Link
                        className="nav-link fw-bold"
                        to="/home/ips-view"
                        style={{
                          padding: isActive("/home/ips-view")
                            ? "10px 15px"
                            : "",
                          color: isActive("/home/ips-view") ? "#dcc05d" : "",
                          backgroundColor: isActive("/home/ips-view")
                            ? "#dcc05d !important"
                            : "",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        IPS
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
      </nav>
    </>
  );
};
