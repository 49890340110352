import ApiBack from "../../../utils/domains/ApiBack";
import IPS from "../../../models/Ips";
import IPSView from "../../../models/ipsView";
import Municipio from "../../../models/municipio";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ServicePrivate from "../../../services/ServicePrivate";
import { Button, Form, Modal } from "react-bootstrap";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { useForm } from "../../../utils/hooks/useForm";
import { useRef } from "react";
import Deparment from "../../../models/Deparment";

export const ViewIps = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [process, setProcess] = useState<boolean>(false);
  const [dateContract, setDateContract] = useState("");
  const [arrayjIps, setArrayIps] = useState<IPSView[]>([]);
  const [proccess, setProccess] = useState<boolean>();
  const [showFinal, setShowFinal] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  type formaHtml = React.FormEvent<HTMLFormElement>;
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [arrayMunicipio, setArrayMunicipio] = useState<Municipio[]>([]);
  //function to error the Token on the time, using when token is invalid or expired

  let { id, nombre_ips, cod_ips, id_municipio_id, doubleLink, object } =
    useForm<IPS>(
      new IPS(
        0,
        "",
        "",
        true,
        new Municipio(0, "", "", new Deparment(0, "")),
        0,
        new Date(),
        false
      ) // Incluye `id_municipio` con un valor inicial
    );

  const [objips, setObjips] = useState<IPSView>(
    new IPSView(
      0,
      "",
      "",
      false,
      new Municipio(0, "", "", new Deparment(0, "")),
      new Date(),
      false,
      ""
    )
  );

  const handleTokenError = () => {
    navigate("/");
  };

  const updateSupportDetails = async (myipsid: number, isActive: boolean) => {
    setProccess(true);

    try {
      // Encuentra el soporte por ID en el array
      const updatedSupport = arrayjIps.find((myIps) => myIps.id === myipsid);

      if (!updatedSupport) {
        console.error("Soporte no encontrado");
        return;
      }

      // Crea un objeto con el valor de is_active basado en el parámetro
      const updatedObject = {
        is_active: isActive,
      };

      const urlUpdate = ApiBack.IPS_UPDATED + "/" + myipsid;
      const response = await ServicePrivate.requestPUT(
        urlUpdate,
        updatedObject
      );

      if (response.OK) {
        MessageToastify(
          "error",
          "Error en el momento de Actualizar el ID del usuario.",
          7000
        );
        getAllIPS();
      } else {
        MessageToastify("success", "ID actualizado del usuario.", 7000);
        setShowFinal(false);
        getAllIPS();
      }
    } catch (error) {
      console.error("Error actualizando el ID del usuario:", error);
      MessageToastify(
        "error",
        "Error en el momento de Actualizar el ID del usuario en el servidor.",
        7000
      );
      setShow(false);
    } finally {
      setProccess(false);
      setShow(false);
    }
  };

  const getOneIps = async (id: number) => {
    try {
      const urlGetUser = ApiBack.IPS_LIST_ONE + "/" + id;
      const result = await ServicePrivate.requestGET(urlGetUser);
      setObjips(result);
      setShow(true);
    } catch (error) {
      console.error("Error fetching profesional:", error);
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const getMunicipality = async () => {
    try {
      const urlGetMunc = ApiBack.MUNICIPIOS_LIST;
      const result = await ServicePrivate.requestGET(urlGetMunc);
      setArrayMunicipio(result);
    } catch (error) {
      console.error("Error fetching profesional:", error);
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const handleShowCreate = async () => {
    setShow2(true);
    getMunicipality();

  }
  const getOneIpsUp = async (id: number) => {
    try {
      const urlGetUser = ApiBack.IPS_LIST_ONE + "/" + id;
      const result = await ServicePrivate.requestGET(urlGetUser);
      setObjips(result);
      setShowUpdate(true);
    } catch (error) {
      console.error("Error fetching profesional:", error);
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const getAllIPS = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ALL);
      if (results) {
        setArrayIps(results);
        console.log(results);
        
        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching consents:", error);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError();
      }
    }
  };

  const sendUpdated = async (Id: number, dateContract: string) => {
    setProccess(true);
    try {
      // Encuentra el soporte en el array
      const findIps = arrayjIps.find((prof) => prof.id === Id);

      if (!findIps) {
        MessageToastify(
          "error",
          "No se encuentra la IPS en la base de datos.",
          7000
        );
        return;
      }
      const today = new Date();
      const eightDaysAgo = new Date(today);
      eightDaysAgo.setDate(today.getDate() - 365);


      const selectedDate = new Date(dateContract);
      if (selectedDate < eightDaysAgo) {
        MessageToastify(
          "error",
          "La fecha no puede ser menor a 1 año menor a hoy.",
          2000
        );
        return;
      } else if (selectedDate > today) {
        MessageToastify(
          "error",
          "La fecha no puede superar la fecha actual.",
          2000
        );
        return;
      }
      // Crear el objeto con el id que deseas actualizar
      const updatedObject = {
        date_contract: dateContract,
      };

      const urlUpdate = ApiBack.IPS_UPDATE_CONTRACT + "/" + Id;

      const response = await ServicePrivate.requestPUT(
        urlUpdate,
        updatedObject
      );

      if (response?.status === 200) {
        console.error("Error al actualizar el ID:", response?.data);
        MessageToastify(
          "success",
          "Error al momento de cambiar la fecha, verifique los datos enviados.",
          7000
        );
      } else {
        MessageToastify(
          "success",
          "Se ha actualizado la fecha del contrato.",
          3000
        );
        setShowUpdate(false);
        getAllIPS();
      }
    } catch (error) {
      console.error("Error actualizando el ID del usuario:", error);
      MessageToastify(
        "error",
        "Error al enviar la nueva fecha del contrato por parte del servidor comuniquese con TI.",
        7000
      );
    } finally {
      setProccess(false);
    }
  };

  const sendForm = async (fh: formaHtml) => {
    try {
      fh.preventDefault();
      setProcess(true);
      const form = fh.currentTarget;

      form.classList.add("was-validated");

      if (form.checkValidity() === false) {
        fh.preventDefault();
        fh.stopPropagation();
        setProcess(false);
        MessageToastify(
          "error",
          "Por favor, completa todos los campos requeridos.",
          7000
        );
        return;
      }

      const formData = {
        nombre_ips: object.nombre_ips,
        cod_ips: object.cod_ips,
        is_active: true,
        id_municipio_id: object.id_municipio_id,
      };
      console.log(formData);

      const response = await ServicePrivate.requestPOST(
        ApiBack.IPS_CREATE,
        formData
      );

      if (response.OK) {
        setProcess(false);
        MessageToastify(
          "error",
          "error al momento de crear el nuevo cliente.",
          5000
        );
      } else {
        setProcess(false);
        handleClose2();
        getAllIPS();
        MessageToastify("success", "EL nuevo cliente ha sido creado.", 5000);
      }
    } catch (error) {
      setProcess(false);

      MessageToastify(
        "error",
        "Error en el momento  crear el nuevo cliente.",
        7000
      );
    }
  };

  useEffect(() => {
    getAllIPS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div>
        <div className="row">
          <div className="page-header">
            <div className="col-lg-6 col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-chevron"></ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <h4 className="card-title fs-4">
                      <i className="fa-solid fa-list"></i>&nbsp;Listado de
                      clientes
                    </h4>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className={
                          "badge fs-6 py-1 pe-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                        }
                        type="button"
                        onClick={() => handleShowCreate()}
                        style={{ marginLeft: "10px" }}
                      >
                        <i className="fa-solid fa-plus"></i>&nbsp;Crear
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row d-flex"></div>
                <div className="table-responsive">
                  <table className="table table-striped table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th style={{ width: "10%" }}>Municipio</th>
                        <th style={{ width: "30%" }}>Nombre IPS</th>
                        <th style={{ width: "15%" }}>Cod. IPS</th>
                        <th style={{ width: "15%" }}>Último contrato</th>
                        <th style={{ width: "20%" }}>Estado</th>
                      </tr>
                    </thead>
                    <tbody className="table-group-divider">
                      {arrayjIps.map((myIps, count) => (
                        <tr key={count + 1}>
                          <td>{myIps.municipio_id?.nombre_municipio}</td>
                          <td>{myIps.nombre_ips}</td>
                          <td>{myIps.cod_ips}</td>
                          <td>
                            {myIps.date_contract
                              ? new Date(myIps.date_contract).toLocaleString(
                                  "es-CO",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )
                              : "No Tiene contrato"}
                          </td>

                          <td>
                            <button
                              className={
                                "badge fs-6 align-items-center p-1 pe-1 text-dark-emphasis bg-dark-subtle border border-dark-subtle rounded-pill"
                              }
                              onClick={() => getOneIpsUp(myIps.id)}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>{" "}
                              Actualizar
                            </button>
                            <button
                              className={
                                myIps.is_active
                                  ? "badge fs-6 py-1 pe-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-pill"
                                  : "badge fs-6 py-1 pe-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                              }
                              type="button"
                              onClick={() => getOneIps(myIps.id)}
                              style={{ marginLeft: "10px" }}
                            >
                              {myIps.is_active ? (
                                <>
                                  <i className="fa-regular fa-circle-xmark"></i>
                                  &nbsp;Inactivar
                                </>
                              ) : (
                                <>
                                  <i className="fa-regular fa-circle-check"></i>
                                  &nbsp;Activar
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center justify-content-center m-3"></div>
                </div>

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-center fw-bold">
                      {objips.is_active ? "Inactivar la IPS" : "Activar la IPS"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    ¿Realmente desea cambiar el estado de la IPS?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <i className="fa-solid fa-backward"></i>
                      &nbsp;Cancelar
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        console.log("ID de la ips:", id);
                        updateSupportDetails(objips.id, !objips.is_active);
                      }}
                    >
                      <i className="fa-solid fa-circle-check"></i>
                      &nbsp;Confirmar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={show2}
                  onHide={handleClose2}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-center fw-bold">
                      <i className="fa-solid fa-folder-plus"></i>
                      &nbsp;Formulario de creación
                    </Modal.Title>
                  </Modal.Header>
                  <Form
                    className="forms-sample"
                    validated={process}
                    onSubmit={sendForm}
                    noValidate
                    ref={formRef}
                  >
                    <Modal.Body>
                      {/* Campo para nombre_ips */}
                      <Form.Group controlId="nombre_ips" className="mb-3 w-100">
                        <Form.Label
                          className="fw-bold mb-2 mt-2"
                          htmlFor="nombre_ips"
                        >
                          Nombre IPS:
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="nombre_ips"
                          className="form-control"
                          id="nombre_ips"
                          placeholder="Escriba el nombre de la IPS"
                          value={nombre_ips}
                          onChange={(e) => {
                            let value = e.target.value.toUpperCase(); // Convierte a mayúsculas
                            // Validar que solo contenga letras y limitar a 60 caracteres
                            if (
                             /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]*$/.test(value) &&
                              value.length <= 60
                            ) {
                              e.target.value = value; // Establece el valor en mayúsculas
                              doubleLink(e); // Llama a tu función existente
                            }
                          }}
                          autoComplete="off"
                          maxLength={60} // Limita a 60 caracteres
                        />
                        <Form.Control.Feedback type="invalid">
                          Nombre de la IPS es obligatorio.
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* Campo para cod_ips */}
                      <Form.Group controlId="cod_ips" className="mb-3 w-100">
                        <Form.Label
                          className="fw-bold mb-2 mt-2"
                          htmlFor="cod_ips"
                        >
                          Código IPS:
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="cod_ips"
                          className="form-control"
                          id="cod_ips"
                          placeholder="Escriba el código de la IPS"
                          value={cod_ips}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Permitir solo números y limitar a 20 caracteres
                            if (/^\d*$/.test(value) && value.length <= 20) {
                              doubleLink(e); // Llama a tu función existente
                            }
                          }}
                          autoComplete="off"
                          maxLength={20} // Limita a 20 caracteres
                        />
                        <Form.Control.Feedback type="invalid">
                          Código de la IPS es obligatorio.
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* Campo para seleccionar municipio */}
                      <Form.Group
                        controlId="id_municipio_id"
                        className="mb-3 w-100"
                      >
                        <Form.Label
                          className="fw-bold mb-2 mt-2"
                          htmlFor="id_municipio_id"
                        >
                          Municipio:
                        </Form.Label>
                        <Form.Select
                          required
                          id="id_municipio_id"
                          name="id_municipio_id"
                          value={id_municipio_id}
                          onChange={doubleLink}
                        >
                          <option value="">Selecciona un municipio</option>
                          {arrayMunicipio.map((municipio) => (
                            <option key={municipio.id} value={municipio.id}>
                              {municipio.nombre_municipio} - {" "}
                              {municipio?.id_deparment?.name_deparment}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          EL municipio es obligatorio.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose2}>
                        <i className="fa-solid fa-backward"></i>&nbsp;Cancelar
                      </Button>
                      <Button
                        variant="success"
                        type="submit"
                        onClick={() => {}}
                      >
                        <i className="fa-solid fa-plus"></i>&nbsp;Guardar
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal>

                <Modal
                  show={showUpdate}
                  onHide={handleCloseUpdate}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-center fw-bold">
                      <i className="fa-solid fa-pen"></i>
                      &nbsp;Actualizar la fecha de contrato
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    IPS:
                    <strong>&nbsp;{objips.nombre_ips}</strong>
                    <br />
                    <div className="col-lg-12">
                      <Form
                        className="forms-sample"
                        validated={process}
                        noValidate
                        ref={formRef}
                      >
                        <div className="col-lg-12">
                          <Form.Group controlId="date_contract">
                            <Form.Label
                              className="fw-bold mb-2 mt-2"
                              htmlFor="date_contract"
                            >
                              Fecha de contrato:
                            </Form.Label>
                            <Form.Control
                              type="date"
                              id="date_contract"
                              name="date_contract"
                              className="form-control"
                              value={dateContract}
                              onChange={(e) => setDateContract(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setShowUpdate(false);
                      }}
                    >
                      <i className="fa-solid fa-backward"></i>
                      &nbsp;Cancelar
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        sendUpdated(objips.id, dateContract);
                      }}
                    >
                      <i className="fa-solid fa-circle-check"></i>&nbsp;Confirmar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      <ToastContainer />
      </div>
    </>
  );
};
