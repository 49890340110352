import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Template from "../../../models/Template";
import Subtype_request from "../../../models/sub_type_of_request";
import Typerequest from "../../../models/type_of_request";
import User from "../../../models/user";
import { MessageToastify } from "../../../utils/funtions/MessageToastify";

export const ViewMyTemplate = () => {
  const [arrayMyTemplate, setArrayMyTemplate] = useState<Template[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const [limit, setlimit] = useState(0);
  const [Total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [objTemplate, setObjTemplate] = useState<Template>(
    new Template(
      0,
      new Subtype_request(0, "", new Typerequest(0, "")),
      0,
      new User(0, "", "", "", "", false, "", "", ""),
      0,
      "",
      "",
      new Date(),
      new Date(),
      false,
      false
    )
  );

  const handleTokenError = () => {
    navigate("/"); // Go to login session
  };

  const getOneTemplate = async (id: number) => {
    try {
      const urlOneTemplate = ApiBack.TEMPLATE_VIEW_ONE + "/" + id;
      const result = await ServicePrivate.requestGET(urlOneTemplate);
      setObjTemplate(result);
      setShow(true);
    } catch (error) {
      console.error("Error fetching profesional:", error);
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        // handleTokenError(); // Manejar el error de token
      }
    }
  };

  const getPatientId = (): number | null => {
    const token = localStorage.getItem("access");
    if (!token) return null;
    const decodedToken: any = jwtDecode(token);
    return decodedToken.user_id;
  };

  const getMyTemplate = async () => {
    const id_user = getPatientId();
    if (!id_user) {
      // navigate("/");
      return;
    }

    try {
      const results = await ServicePrivate.requestGET(
        `${ApiBack.TEMPLATE_VIEW_BY_USER}/${id_user}?page=${page}`
      );

      // Asegúrate de que results sea un arreglo
      if (Array.isArray(results.results)) {
        setArrayMyTemplate(results.results);
        const totalItems = results.total_pages;
        setlimit(totalItems);
        const totalData = results.total_count;
        setTotal(totalData);
      } else {
        MessageToastify(
          "info",
          "No tiene plantillas en el momento, por favor creelas para verlas.",
          2000
        );
        setArrayMyTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      MessageToastify(
        "error",
        "Error en el momento de ver las plantillas",
        2000
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1)); // Asegura que la página no sea menor que 1
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, limit)); // Asegura que la página no exceda el límite
    window.scrollTo(0, 0);
  };

  const sendIsActivated = async (id: number) => {
    const token = localStorage.getItem("access");
    if (token === null) handleTokenError();

    try {
      // Encuentra el profesional por ID en el array
      const updatedTemplate = arrayMyTemplate.find((prof) => prof.id === id);

      if (!updatedTemplate) {
        console.error("Profesional no encontrado");
        return;
      }

      // Invierte el estado is_active
      const updatedObject = {
        ...updatedTemplate,
        is_active: !updatedTemplate.is_active,
      };

      const urlUpdate = ApiBack.TEMPLATE_UPDATE_STATUS + "/" + id;
      const response = await ServicePrivate.requestPUT(
        urlUpdate,
        updatedObject
      );

      if (response.OK) {
        MessageToastify(
          "error",
          "Error en el momento de actualizar la plantilla por favor verifique.",
          7000
        );
      } else {
        MessageToastify("success", "Estado actualizado de la plantilla.", 7000);
        getMyTemplate();
      }
    } catch (error) {
      console.error("Error updating usuario:", error);
      MessageToastify(
        "error",
        "Error en el momento de Actualizar la plantilla en el servidor, por favor comuníquese con .",
        7000
      );
    } finally {
      // setProccess(false);
    }
  };

  useEffect(() => {
    getMyTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div>
        <div className="row">
          <div className="page-header">
            <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
              <h3 style={{ fontWeight: "bold" }}>Mis plantillas</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3">
                    <p>
                      Mis plantillas:&nbsp;
                      <b className="fw-bold">{arrayMyTemplate.length}</b>
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      Mis plantillas totales:&nbsp;
                      <b className="fw-bold">{Total}</b>
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      Páginas totales:&nbsp;
                      <b className="fw-bold">{limit}</b>
                    </p>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th style={{ width: "5%" }}>Id</th>
                        <th style={{ width: "10%" }}>Tipo de solicitud</th>
                        <th style={{ width: "10%" }}>Tipo de pantilla</th>
                        <th style={{ width: "80%" }}>Contenido</th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (

                          <tr>
                            <td colSpan={12} className="text-center">
                              <div className="d-flex flex-column align-items-center">
                                <div
                                  className="spinner-border"
                                  role="status"
                                  style={{
                                    width: "3rem",
                                    height: "3rem",
                                    color: "#000",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Cargando...
                                  </span>
                                </div>
                                <p
                                  className="mt-3 fs-5"
                                  style={{ color: "#000" }}
                                >
                                  Cargando datos, por favor espere...
                                </p>
                              </div>
                            </td>
                          </tr>

                      ) : (
                        <>
                          {arrayMyTemplate.length === 0 ? (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <div className="d-flex flex-column align-items-center">
                                  <p
                                    className="mt-3 fs-5"
                                    style={{ color: "#000" }}
                                  >
                                    No tiene plantillas en el momento.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            arrayMyTemplate.map((template) => (
                              <tr key={template.id}>
                                <td>{template.id}</td>
                                <td>
                                  {template.id_type_support.subtype_request}
                                </td>
                                <td>
                                  {template.is_description
                                    ? "Solicitud"
                                    : "Respuesta"}
                                </td>
                                <td>{template.template_content}</td>
                                <td>
                                  <button
                                    className={
                                      template.is_active
                                        ? "badge fs-6 py-1 pe-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-pill"
                                        : "badge fs-6 py-1 pe-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                                    }
                                    style={{ marginRight: "10px" }}
                                    type="button"
                                    onClick={() => getOneTemplate(template.id)}
                                  >
                                    {template.is_active ? (
                                      <>
                                        <i className="fas fa-times"></i>
                                        &nbsp;Inactivar
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-check"></i>
                                        &nbsp;Activar
                                      </>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center justify-content-center">
                    <nav className="inline-flex space-x-2.5 justify-content-center">
                      <button
                        className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                        &nbsp;Anterior
                      </button>
                      &nbsp;
                      <span className="fs-6">
                        Página {page} de {limit}
                      </span>
                      &nbsp;
                      <button
                        className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                        onClick={handleNextPage}
                        disabled={page === limit}
                      >
                        Siguiente&nbsp;
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal para ver detalles del soporte */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center fw-bold">
              {objTemplate.is_active
                ? "Inactivar plantilla"
                : "Activar plantilla"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Realmente desea {objTemplate.is_active ? "inactivar" : "activar"}{" "}
            la plantilla?
            <br />
            Contenido:&nbsp;
            <strong>{objTemplate.template_content}</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={(e) => {
                setShow(false);
              }}
            >
              <i className="fa-solid fa-backward"></i>
              &nbsp; Cancelar
            </Button>
            <Button
              variant="success"
              onClick={(e) => {
                sendIsActivated(objTemplate.id);
                setShow(false);
              }}
            >
              <i className="fa-solid fa-circle-check"></i>&nbsp;Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};
