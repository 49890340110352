const ApiBack = {
  //URL: "http://localhost:8000/api/v1/",
  URL: "https://b-rocky-intranet.onrender.com/api/v1/",

  //Login endpoint to connect to the API
  LOGIN: "login",
  USER_VIEW: "view-user",
  USERS_VIEW: "view-users",
  USER_CREATE: "create-user",
  USER_UPDATE_STATUS: "user-status-update",
  USER_LIST_ONE: "user-list-one",
  USER_LIST_ONE_ROLE: "user-list-one-rol",
  USER_UPDATE: "user-update",
  USER_LIST_ONE_TOKE: "user-list-one-token",

  //ips for connect to the API
  IPS_VIEW: "view-ips",
  IPS_VIEW_ALL: "view-ips-all",
  IPS_VIEW_ACTIVE: "view-ips-active",
  IPS_UPDATED: "update-ips",
  IPS_ONE_VIEW: "view-one-ips",
  IPS_CREATE: "create-ips",
  IPS_LIST_ONE: "ips-list-one",
  IPS_UPDATE_CONTRACT: "update-contract-ips",
  MUNICIPIOS_LIST: "municipio-list",

  COMMENTS_VIEW: "view-comment",
  COMMENTS_UPDATED: "create-comment",
  COMMENTS_CREATE: "update-comment",

  SUPPORT_VIEW: "view-support",
  SUPPORT_MY_VIEW: "view-my-support",
  SUPPORT_SEND_VIEW: "view-my-support-send",
  SUPPORT_VIEW_FINAL: "view-support-final",
  SUPPORT_VIEW_FINAL_EXPO_IPS: "view-suppport-con-ips-fun-export",
  SUPPORT_VIEW_FINAL_EXPO_ONLY_PDF: "view-suppport-con-ips-export",
  SUPPORT_LIST_ONE: "support-list-one",
  SUPPORT_UPDATED: "update-support",
  SUPPORT_UPDATE_USER: "update-supportUser2",
  SUPPORT_UPDATE_DETAILS: "update-supporDetails",
  SUPPORT_CREATE: "create-support",
  SUPPORT_MY: "view-mysupport",
  SUPPORT_VIEW_ONLY_PDF: "view-support-final-only-pdf",
  SUPPORT_VIEW_FINAL_HISTORY: "view-support-final-history",
  SUPPORT_VIEW_CON_IPS_FUN: "view-suppport-con-ips-fun",
  SUPPORT_VIEW_CON_IPS: "view-suppport-con-ips",
  SUPPORT_VIEW_BY_FUNCIONARY: "view-suppport-funcionary",

  TYPE_OF_REQUEST_lIST: "type-of-request-list",
  TYPE_OF_REQUEST_LIST_ONE: "type-of-request-list-one",

  SUBTYPE_OF_REQUEST_LIST: "Subtype-list",
  SUBTYPE_OF_REQUEST_LIST_TYPE: "Subtype-list-type",
  SUBTYPE_OF_REQUEST_LIST_ONE: "Subtype-list-one",
  SUBTYPE_OF_REQUEST_CREATE: "Subtype-create",

  MUNICIPALITY_LIST: "municipios-list",
  MUNICIPALITY_CREATE: "municipios-create",

  TEMPLATE_LIST: "template-view",
  TEMPALTE_CREATE: "template-create",
  TEMPALTE_LIST_BY_USER: "template-view-by-user-descrip",
  TEMPALTE_LIST_BY_DESCRIP_ALL: "template-view-by-user-descrip-all",
  TEMPALTE_LIST_BY_RES_ALL: "template-view-by-user-resp-all",
  TEMPALTE_LIST_BY_USER_RES: "template-view-by-user-resp",
  TEMPLATE_CREATE: "template-create",
  TEMPLATE_VIEW_BY_USER: "template-view-by-user-all",
  TEMPLATE_VIEW_ONE:"template-view-one",
  TEMPLATE_UPDATE_STATUS: "template-update-status",
};
export default ApiBack;
