import Deparment from "./Deparment";
class Municipio {
  public id: number;
  public nombre_municipio: string;
  public codigo_municipio: string;
  public id_deparment: Deparment

  constructor(id: number, nombre_municipio: string, codigo_municipio: string, id_deparment_id: Deparment) {
    this.id = id;
    this.nombre_municipio = nombre_municipio;
    this.codigo_municipio = codigo_municipio;
    this.id_deparment = id_deparment_id;
  }
}

export default Municipio;
