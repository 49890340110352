import Municipio from "./municipio";
class IPSView {
  public id: number;
  public nombre_ips: string;
  public cod_ips: string;
  public is_active: boolean;
  public municipio_id: Municipio;
  public date_contract: Date;
  public have_contract: boolean;
  public municipio_id__nombre_municipio:string

  constructor(
    id: number,
    nombre_ips: string,
    cod_ips: string,
    is_active: boolean,
    municipio_id: Municipio,
    date_contract: Date,
    have_contract: boolean,
    municipio_id__nombre_municipio: string
  ) {
    this.id = id;
    this.is_active = is_active;
    this.nombre_ips = nombre_ips;
    this.cod_ips = cod_ips;
    this.municipio_id = municipio_id;
    this.date_contract = date_contract;
    this.have_contract = have_contract;
    this.municipio_id__nombre_municipio = municipio_id__nombre_municipio;
  }
}
export default IPSView;
