import Subtype_of_request from "./sub_type_of_request";
import User from "./user";

class Template {
  public id: number;
  public id_type_support: Subtype_of_request;
  public id_type_support_id: number;
  public id_user: User;
  public id_user_id: number;
  public template_name: string;
  public template_content: string;
  public created_date: Date;
  public updated_date: Date;
  public is_description: boolean;
  public is_active: boolean;

  constructor(
    id: number,
    id_type_support: Subtype_of_request,
    id_type_support_id: number,
    id_user: User,
    id_user_id: number,
    template_name: string,
    template_content: string,
    create_date: Date,
    update_date: Date,
    is_description: boolean,
    is_active: boolean
  ) {
    this.id = id;
    this.id_type_support = id_type_support;
    this.id_type_support_id = id_type_support_id;
    this.id_user = id_user;
    this.id_user_id = id_user_id;
    this.template_name = template_name;
    this.template_content = template_content;
    this.created_date = create_date;
    this.updated_date = update_date;
    this.is_description = is_description;
    this.is_active = is_active;
  }
}
export default Template