/* eslint-disable react/jsx-no-target-blank */
import { Link } from "react-router-dom";
import imgDash from "../assets/images/Rocky/LOGOROCKYPeque.png";

export const Footer = () => {
  return (
    <>
      <footer className="footer ps-5 ">
        <Link to="/home/main" className="logo-down">
          <img src={imgDash} alt="Logo Rocky" />
        </Link>
      </footer>
    </>
  );
};
