import { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import ServicePrivate from "../../../services/ServicePrivate";
import ApiBack from "../../../utils/domains/ApiBack";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MessageToastify } from "../../../utils/funtions/MessageToastify";
import { ToastContainer } from "react-toastify";
import IPS from "../../../models/Ips";
import User from "../../../models/user";
import Template from "../../../models/Template";
import Typerequest from "../../../models/type_of_request";
import Subtype_of_request from "../../../models/sub_type_of_request";
import Municipio from "../../../models/municipio";
import Subtype_request from "../../../models/sub_type_of_request";
import Deparment from "../../../models/Deparment";

export const ViewTemplateAll = () => {
  const [arrayTemplate, setArrayTemplate] = useState<Template[]>([]);
  const [arrayCustomerUser, setArrayCustomerUser] = useState<User[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arrayTypeRequest, setArrayTypeRequest] = useState<Typerequest[]>([]);
  const [arrayIPS, setArrayIPS] = useState<IPS[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(0);
  const [Total, setTotal] = useState(0);
  const [show, setShow] = useState<boolean>(false);
  
  const [objTemplate, setObjTemplate] = useState<Template>(
    new Template(
      0,
      new Subtype_request(0, "", new Typerequest(0, "")),
      0,
      new User(0, "", "", "", "", false, "", "", ""),
      0,
      "",
      "",
      new Date(),
      new Date(),
      false,
      false
    )
  );
  const handleClose = () => setShow(false);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1)); // Asegura que la página no sea menor que 1
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, limit)); // Asegura que la página no exceda el límite
    window.scrollTo(0, 0);
  };
  const getMyTemplate = async (page: number) => {
    setLoading(true);
    try {
      const url = `${ApiBack.TEMPLATE_LIST}`;
      try {
        const response = await ServicePrivate.requestGET(url);

        if (response.results && Array.isArray(response.results)) {
          const filteredResults = response.results;
          setArrayTemplate(filteredResults);
          const totalItems = response.total_pages;
          setlimit(totalItems);
          const totalData = response.total_count;
          setTotal(totalData);
        } else {
          console.error("La respuesta no es un arreglo:", response);
          MessageToastify(
            "info",
            "No se pueden mostrar los datos de la base de datos, por favor comuníquese con TI.",
            4000
          );
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        MessageToastify(
          "info",
          "No se encuentra el datos en la base de datos.",
          4000
        );
        setArrayTemplate([]);
      }
    } catch (error) {
      console.error("Error fetching supports:", error);
      const token = localStorage.getItem("access");
      if (!token) handleTokenError();
    } finally {
      setLoading(false);
    }
  };

  const getAllUsers = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.USER_VIEW);
      setArrayCustomerUser(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };
  const getAllIPS = async () => {
    try {
      const results = await ServicePrivate.requestGET(ApiBack.IPS_VIEW_ACTIVE);
      setArrayIPS(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info IPS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const getAllTypesrequest = async () => {
    try {
      const results = await ServicePrivate.requestGET(
        ApiBack.TYPE_OF_REQUEST_lIST
      );
      setArrayTypeRequest(results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching info USERS:", error);
      setLoading(false);
      // handleTokenError(error); // Manejar el error de token
    }
  };

  const handleTokenError = () => {
    navigate("/"); // Go to login session
  };

  const getOneTemplate = async (id: number) => {
    try {
      const urlOneTemplate = ApiBack.TEMPLATE_VIEW_ONE + "/" + id;
      const result = await ServicePrivate.requestGET(urlOneTemplate);
      setObjTemplate(result);
      setShow(true);
    } catch (error) {
      console.error("Error fetching profesional:", error);
      setShow(false);
      const token = localStorage.getItem("access");
      if (token === null) {
        handleTokenError(); // Manejar el error de token
      }
    }
  };

  const sendIsActivated = async (id: number) => {
    const token = localStorage.getItem("access");
    if (token === null) handleTokenError();

    try {
      // Encuentra el profesional por ID en el array
      const updatedTemplate = arrayTemplate.find((prof) => prof.id === id);

      if (!updatedTemplate) {
        console.error("Profesional no encontrado");
        return;
      }

      // Invierte el estado is_active
      const updatedObject = {
        ...updatedTemplate,
        is_active: !updatedTemplate.is_active,
      };

      const urlUpdate = ApiBack.TEMPLATE_UPDATE_STATUS + "/" + id;
      const response = await ServicePrivate.requestPUT(
        urlUpdate,
        updatedObject
      );

      if (response.OK) {
        MessageToastify(
          "error",
          "Error en el momento de actualizar la plantilla por favor verifique.",
          7000
        );
      } else {
        MessageToastify("success", "Estado actualizado de la plantilla.", 7000);
        getMyTemplate(page);
      }
    } catch (error) {
      console.error("Error updating usuario:", error);
      MessageToastify(
        "error",
        "Error en el momento de Actualizar la plantilla en el servidor, por favor comuníquese con .",
        7000
      );
    } finally {
      // setProccess(false);
    }
  };

  useEffect(() => {
    getMyTemplate(page);
    getAllUsers();
    getAllTypesrequest();
    getAllIPS();
    // filterByCreationDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-lg-12 col-sm-12 d-flex justify-content-center">
            <h3 style={{ fontWeight: "bold" }}>Listado todas las plantillas creadas en el sistema</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="fs-6 fw-light">
                  <div className="row">
                    <div className="col-lg-3">
                      <p>
                        Plantillas por página:&nbsp;
                        <b className="fw-bold">{arrayTemplate.length}</b>
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p>
                        Plantillas totales:&nbsp;
                        <b className="fw-bold">{Total}</b>
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <p>
                        Páginas totales:&nbsp;
                        <b className="fw-bold">{limit}</b>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped table-sm rounded-table">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col" style={{ width: "5%" }}>
                          Id
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Usuario
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Tipo de solicitud
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Tipo de plantilla
                        </th>
                        <th scope="col" style={{ width: "70%" }}>
                          Contenido de plantilla
                        </th>
                        <th scope="col" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {arrayTemplate.length === 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <p>No hay resultados</p>
                          </td>
                        </tr>
                      )}

                      {arrayTemplate.map((oneTemplate, count) => (
                        <tr key={count + 1}>
                          <td>{oneTemplate.id}</td>
                          <td>
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={oneTemplate.id_user.role} // Este es el texto que se mostrará en el tooltip
                            >
                              {oneTemplate.id_user.name}&nbsp;
                              {oneTemplate.id_user.last_name}
                            </span>
                          </td>
                          <td>{oneTemplate.id_type_support.subtype_request}</td>
                          <td>
                            {oneTemplate.is_description
                              ? "Solicitud"
                              : "Respuesta"}
                          </td>
                          <td>{oneTemplate.template_content}</td>
                          <td>
                            <button
                              className={
                                oneTemplate.is_active
                                  ? "badge fs-6 py-1 pe-1 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-pill"
                                  : "badge fs-6 py-1 pe-3 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill"
                              }
                              style={{ marginRight: "10px" }}
                              type="button"
                              onClick={() => getOneTemplate(oneTemplate.id)}
                            >
                              {oneTemplate.is_active ? (
                                <>
                                  <i className="fa-regular fa-circle-xmark"></i>
                                  &nbsp;Inactivar
                                </>
                              ) : (
                                <>
                                  <i className="fa-regular fa-circle-check"></i>
                                  &nbsp;Activar
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="d-flex align-items-center justify-content-end">
                      <nav className="inline-flex space-x-2.5 justify-content-end">
                        <button
                          className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill"
                          onClick={handlePreviousPage}
                          disabled={page === 1}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                          &nbsp;Anterior
                        </button>
                        &nbsp;
                        <span className="fs-6">
                          Página {page} de {limit}
                        </span>
                        &nbsp;
                        <button
                          className="badge fs-6 p-2 pe-2 px-2 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-pill "
                          onClick={handleNextPage}
                          disabled={page === limit}
                        >
                          Siguiente&nbsp;
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal para ver detalles del soporte */}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-center fw-bold">
                  {objTemplate.is_active
                    ? "Inactivar plantilla"
                    : "Activar plantilla"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Realmente desea{" "}
                {objTemplate.is_active ? "inactivar" : "activar"} la plantilla?
                <br />
                Contenido:&nbsp;
                <strong>{objTemplate.template_content}</strong>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    setShow(false);
                  }}
                >
                  <i className="fa-solid fa-backward"></i>
                  &nbsp; Cancelar
                </Button>
                <Button
                  variant="success"
                  onClick={(e) => {
                    sendIsActivated(objTemplate.id);
                    setShow(false);
                  }}
                >
                 <i className="fa-solid fa-circle-check"></i>&nbsp;Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
